import React from 'react'
import BurnHero from './BurnHero'
import Brunstatustics from './Brunstatustics'
import Header from '../comman/Header'
import Footer from '../comman/Footer'

export default function Burn() {
  return (
    <>
    <Header/>
    <BurnHero/>
    <Brunstatustics/>
    <Footer/>
    </>
  )
}

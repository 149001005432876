import React from 'react'

export default function ValidatorsTable() {
    return (
        <>
            <div className='section wf-section bg-light'>
                <div className='row'>
                    <div className='col-12 text-center my-2 d-flex '>
                        <div data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb6d" className="form-block-banner w-form align-center float-start">
                            <input type="email" className="text-field w-input w-100" maxLength={256} name="Email" data-name="Email" placeholder="submit your hash" id="Email" required />
                        </div>

                        <h6 className='mt-5 text-black float-end'>hello</h6>
                    </div>
                </div>
            </div>
        </>
    )
}

import React from "react";

export default function BrandkitHero() {
  return (
    <>
      <div className="section wf-section bg-11">
        <div className="content hero">
          <div className="block-hero">
            <div
              data-w-id="78bf7935-39ac-c464-dac4-4f1b84bd7529"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="subtitle"
            >
              About
            </div>
            <h1
              data-w-id="fec0fb7b-303f-09d0-3ce6-40f91d7252a5"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="heading-hero"
            >
              CTEX Media Assets
            </h1>
            <p
              data-w-id="dcd739db-6d1f-6a52-859b-4a3fd0e20c84"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="paragraph-large text-white"
            >
              Design style and infographics for you to use
            </p>
            <div
              data-w-id="77b60b92-872c-7997-8a32-0c57412cdee7"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="w-layout-grid grid-button"
            >
              <a href="#" className="button w-button">
                Get Started
              </a>
            </div>
          </div>
          {/* <div data-w-id="297673ad-47a4-b4b1-8938-0ca16b3d6b41" className="figure-a-hero">
                        <div className="figure-block-a-hero" style={{
                            willChange: 'transform',
                            transform: 'translate3d(6.99888%, -219.965px, 0px) scale3d(1, 1, 1) rotateX(60.9976deg) rotateY(28.9954deg) rotateZ(-45.9926deg) skew(0deg, 0deg)'
                            , transformStyle: 'preserve-3d'
                        }}>
                            <div style={{
                                width: '100%', willChange: 'transform',
                                transform: 'translate3d(0px, 0px, 0.00064vh) scale3d(1, 1, 1) rotateX(-11.889deg) rotateY(0deg) rotateZ(13.4233deg) skew(0deg, 0deg)'
                                , transformStyle: 'preserve-3d', height: '500px'
                            }} className="figure-a1" />
                            <div style={{
                                width: '90%', willChange: 'transform',
                                transform: 'translate3d(0px, 0px, -18.9987vh) scale3d(1, 1, 1) rotateX(-5.9334deg) rotateY(0deg) rotateZ(8.94888deg) skew(0deg, 0deg)'
                                , transformStyle: 'preserve-3d', height: '450px'
                            }} className="figure-a2" />
                            <div style={{
                                width: '60%', willChange: 'transform',
                                transform: 'translate3d(0px, 0px, -43.9974vh) scale3d(1, 1, 1) rotateX(0.0222deg) rotateY(0deg) rotateZ(4.47444deg) skew(0deg, 0deg)'
                                , transformStyle: 'preserve-3d', height: '350px'
                            }} className="figure-a3" />
                        </div>
                    </div> */}
        </div>
        <div
          data-w-id="58e11b86-9d96-c9a8-f52f-b1795c37fa57"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="glow-top"
        />
      </div>
      {/* <div className="section-hero wf-section brand-bg">
                <div className="content single-page">
                    <div className="block-hero">
                        <div data-w-id="78bf7935-39ac-c464-dac4-4f1b84bd7529" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="subtitle">About</div>
                        <h1 data-w-id="294bce85-806e-c17f-c9e9-048ba9d50788" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="heading-single-page">CTEX Media Assets</h1>
                        <p data-w-id="dcd739db-6d1f-6a52-859b-4a3fd0e20c84" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="paragraph-large">Design style and infographics for you to use</p>
                    </div>
                </div>
                <div data-w-id="58e11b86-9d96-c9a8-f52f-b1795c37fa57" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="glow-top" />
            </div> */}
    </>
  );
}

import React, { useState } from "react";

export default function Team() {
  const [toggleState, setToggleState] = useState(true);
  return (
    <>
      <div className="section wf-section ptblr-50">
        <div className="content">
          <div className="w-layout-grid grid-left">
            <div className="block-left">
              <div className="w-layout-grid grid-stats"></div>
              <img src="./assets/img/meet_our_team_image.png" alt="" />
            </div>
            <div
              id="w-node-_5713fedf-471c-a9c3-90d4-7d6642ea17a8-6ad29b8f"
              className="block"
            >
              <div
                data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17a9"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="subtitle"
              >
                Team
              </div>
              <h2
                data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ab"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="heading"
              >
                Meet our Team
              </h2>
              <p
                data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ad"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="paragraph-large text-white"
              >
                The people behind the CTEX are working efficaciously to reach
                the vision the project has set. Our team includes developers,
                growth specialists, relationship managers and other dozens of
                professionals with the mutual goal of building the thriving
                ecosystem for CTEX project and growing further into the market.
              </p>
              <div
                data-w-id="2d6db815-ef82-a123-9a77-26cdadb39017"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="w-layout-grid grid-button"
              ></div>
            </div>
          </div>
        </div>
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
          style={{ paddingTop: "50px" }}
        >
          <div className="w-layout-grid  grid-team">
            <div
              data-w-id="bb94df64-cb79-9bf0-537f-3833576410c6"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="team-member"
            >
              <img
                src="assets/img/s_c_munjapara.jpg"
                loading="lazy"
                alt=""
                className="image-member"
              />
              <div className="block-member text-black">
                <h6 className="name-member text-black">S C Munjpara</h6>
                <div className="info-member">CEO & Founder</div>
              </div>
            </div>
            <div
              data-w-id="2fb9d41c-1668-1d44-4857-66053502bdb9"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="team-member"
            >
              <img
                src="assets/img/saurabh_mungi.jpg"
                loading="lazy"
                alt=""
                className="image-member"
              />
              <div className="block-member">
                <h6 className="name-member text-black">Saurabh Mungi</h6>
                <div className="info-member text-black">CMD & Co-founder</div>
              </div>
            </div>
            {/* <div
              data-w-id="2fb9d41c-1668-1d44-4857-66053502bdb9"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="team-member"
            >
              <img
                src="assets/img/dhruvie_prajapati.jpg"
                loading="lazy"
                alt=""
                className="image-member"
              />
              <div className="block-member">
                <h6 className="name-member text-black">Dhruvie Prajapati</h6>
                <div className="info-member text-black">COO</div>
              </div>
            </div> */}
            <div
              data-w-id="542b0ad5-f079-ec93-2320-72cc8e823a8c"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="team-member"
            >
              <img
                src="assets/img/bhalchandra_jadav.jpg"
                loading="lazy"
                alt=""
                className="image-member"
              />
              <div className="block-member">
                <h6 className="name-member text-black">Bhalchnadra jadav</h6>
                <div className="info-member text-black">CFO</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import WhitepaperHero from './WhitepaperHero'
import WhitepaperMain from './WhitepaperMain'

export default function Whitepaper() {
    document.title = `Whitepaper - ${process.env.REACT_APP_TITLE}`;
  return (
    <>
    <Header/>
    <WhitepaperHero/>
    <WhitepaperMain/>
    <Footer/>
    </>
  )
}

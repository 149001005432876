import React from "react";

export default function CareerHero() {
  return (
    <>
      <div className="section wf-section bg-8">
        <div className="content hero">
          <div className="block-hero">
            <h1
              data-w-id="fec0fb7b-303f-09d0-3ce6-40f91d7252a5"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="heading-hero"
            >
              Join Our Community
            </h1>
            <p
              data-w-id="dcd739db-6d1f-6a52-859b-4a3fd0e20c84"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="paragraph-large text-white"
            >
              Interested in working with us? <br /> Find out the if there is any
              opportunity below for you to join us.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

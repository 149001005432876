import React, { useState } from "react";

export default function HeroDashbord() {
  const [isActive, setIsActive] = useState(false);

  const handleClick = (event) => {
    // 👇️ toggle isActive state variable
    setIsActive((current) => !current);
  };
  return (
    <>
      <div className="section wf-section">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <div className="block-hero">
            <h4
              data-w-id="fec0fb7b-303f-09d0-3ce6-40f91d7252a5"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className=" pb-30"
            >
              Dashboard
            </h4>
          </div>
          <div className="w-layout-grid grid-page-b">
            <div
              id="w-node-_03dd9d41-6f8f-6dae-ad77-4ec7b94fceda-e76b7e62"
              data-w-id="03dd9d41-6f8f-6dae-ad77-4ec7b94fceda"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
                alignItems: "center",
              }}
              className="tasks bg-gray"
            >
              <div className="tasks-block text-center">
                <h5 className="heading-tasks">QR Code</h5>
              </div>
              <img src="./assets/img/qr.jpg" alt="" width="200" />
              <p
                data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ad"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="paragraph-large mb-15 text-golden text-center"
              >
                <div className="d-flex text-start">
                  <div className="text-white">
                    {" "}
                    Min. Stake <span className="text-golden">50 CTEX</span>{" "}
                  </div>
                  <div className="text-white">
                    CTEX Rate <span className="text-golden">1$</span>{" "}
                  </div>
                </div>
                <div className="text-white">
                  USDT <span className="text-golden">50$</span>
                </div>
              </p>
              <div
                data-w-id="77b60b92-872c-7997-8a32-0c57412cdee7"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="w-layout-grid grid-button"
              >
                <a href="/submit-hash" className="button w-button">
                  Verify
                </a>
              </div>
            </div>
            <div
              data-w-id="9040b537-44e1-e314-8717-bd0bd8cbe0a4"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="stats lighter"
            >
              <h6 className="heading-stats text-black">Email</h6>
              <div className="text-stats text-black">User@email.com</div>
              <h6 className="heading-stats mt-10 text-black">Login key</h6>
              <div className="text-stats">
                <span className="p-10 text-black">
                  {isActive ? "123456789" : "*********"}
                </span>{" "}
                <span
                  toggle="#password-field"
                  className={
                    isActive
                      ? "fa fa-fw fa-eye-slash field-icon toggle-password text-black"
                      : "fa fa-fw fa-eye field-icon toggle-password text-black"
                  }
                  onClick={handleClick}
                ></span>
              </div>
            </div>
            <div
              data-w-id="f0aa4076-555c-586f-088d-17e0523b26a1"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="stats lighter"
            >
              <h4 className="heading-stats text-black">58.3M</h4>
              <div className="text-stats text-black">Node</div>
            </div>
            <div
              data-w-id="27764734-a540-afeb-0e84-c4274378e76a"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="stats lighter"
            >
              <h4 className="heading-stats text-black">2.4B</h4>
              <div className="text-stats text-black">Verified Node</div>
            </div>
            <div
              data-w-id="918d5c54-f1a0-3064-11f2-3a26b74b3ba9"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="stats lighter"
            >
              <h4 className="heading-stats text-black">50</h4>
              <div className="text-stats text-black">USTD</div>
            </div>
            {/* <div data-w-id="b243638b-925b-7205-18ef-8dd1bbb756f7" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="tasks">
                            <div className="tasks-block">
                                <h5 className="heading-tasks">Submit Your Hash</h5>
                            </div>
                            <div data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb6d" className="form-block-banner w-form text-left">
                                <div method="get" className="form-banner text-center">
                                    <input type="email" className="text-field w-input" maxLength={256} name="Email" data-name="Email" placeholder="Hash" id="Email" required />
                                    <br />
                                    <a type="submit" className="button w-button mt-10" href='/dashboard'>Submit</a>
                                </div>
                            </div>

                        </div> */}
            {/* <div id="w-node-_03dd9d41-6f8f-6dae-ad77-4ec7b94fceda-e76b7e62" data-w-id="03dd9d41-6f8f-6dae-ad77-4ec7b94fceda" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="tasks">
                            <div className="tasks-block"><img src="https://assets.website-files.com/613e7a6e19fd8f65b8d29b8e/6143d8094ffeae993a69429f_icon 9.svg" loading="lazy" alt="" className="icon-tasks" />
                                <h5 className="heading-tasks">Our Values</h5>
                            </div>
                            <div data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb6d" className="form-block-banner w-form text-left">
                                <div method="get" className="form-banner text-center">
                                    <input type="email" className="text-field w-input" maxLength={256} name="Email" data-name="Email" placeholder="Hash" id="Email" required />
                                    <br />
                                    <a type="submit" className="button w-button mt-10" href='/dashboard'>Submit</a>
                                </div>
                            </div>
                        </div> */}

            {/* <div className="content single-page">
                            <div data-w-id="3121dfda-7efb-fce9-0178-45a4d3ad8400" style={{
                                transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'
                                , opacity: 1, transformStyle: 'preserve-3d'
                            }} className="form-block-contact w-form">
                                <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form-contact"><input
                                    type="text" className="text-field w-input" maxLength={256} name="First-Name" data-name="First Name"
                                    placeholder="First Name" id="First-Name" />
                                    <input type="submit" defaultValue="Submit" data-wait="Please wait..." className="button w-button" /></form>

                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

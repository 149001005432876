import React from 'react'

export default function EventBlog() {
    return (
        <>
            <div className="section wf-section bg-light">
                <div className="block-heading">
                    {/* <div data-w-id="6d9ad869-a5c2-fd2f-9bfc-e6fc73288d47" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="subtitle"> Partner</div> */}
                    <h2 data-w-id="6d9ad869-a5c2-fd2f-9bfc-e6fc73288d49" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="heading text-black">Finished events</h2>
                    <p data-w-id="7fc5df83-6010-b5dc-f288-b6f30f9f4eef" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="paragraph-large"></p>
                </div>
                <div data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc" className="content">
                    <div className="collection-list-wrapper-blog w-dyn-list">
                        <div role="list" className="collection-list-blog w-dyn-items">
                            <div role="listitem" className="collection-item-blog w-dyn-item"><a
                                data-w-id="4ef7fad7-e469-9c21-35a8-ce9fc0ff3b63"
                                style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }}
                                href="/post/introducing-enhanced-search-api-functionality-with-core-2-0"
                                className="link-image-blog w-inline-block"><img
                                    src="https://assets.website-files.com/614519168cffbd131c32d792/61451eb41100bf61e2edaeb4_blog%201.svg"
                                    loading="lazy" alt="Introducing enhanced search API functionality with Core 2.0"
                                    className="image-blog" />
                                <div className="bg-blog" style={{ opacity: '0.5' }} /></a>
                                <div className="block-blog">
                                    <div data-w-id="e603bc27-b601-3b89-4492-55877cc273de"
                                        style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }}
                                        className="date-blog large">September 18, 2021</div><a
                                            data-w-id="6b005aeb-eee3-bc41-f276-abe52461547a"
                                            style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }}
                                            href="/post/introducing-enhanced-search-api-functionality-with-core-2-0"
                                            className="link-heading-blog w-inline-block">
                                        <h4 className="heading-blog">Introducing enhanced search API functionality with
                                            Core 2.0</h4>
                                    </a>
                                </div>
                            </div>
                            <div role="listitem" className="collection-item-blog w-dyn-item"><a
                                data-w-id="4ef7fad7-e469-9c21-35a8-ce9fc0ff3b63"
                                style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }}
                                href="/post/introducing-standardised-smart-contract-apis"
                                className="link-image-blog w-inline-block"><img
                                    src="https://assets.website-files.com/614519168cffbd131c32d792/61451ebf03b6b874b09745aa_blog%202.svg"
                                    loading="lazy" alt="Introducing standardised smart contract APIs"
                                    className="image-blog" />
                                <div className="bg-blog" style={{ opacity: '0.5' }} /></a>
                                <div className="block-blog">
                                    <div data-w-id="e603bc27-b601-3b89-4492-55877cc273de"
                                        style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }}
                                        className="date-blog large">September 18, 2021</div><a
                                            data-w-id="6b005aeb-eee3-bc41-f276-abe52461547a"
                                            style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }}
                                            href="/post/introducing-standardised-smart-contract-apis"
                                            className="link-heading-blog w-inline-block">
                                        <h4 className="heading-blog">Introducing standardised smart contract APIs</h4>
                                    </a>
                                </div>
                            </div>
                            <div role="listitem" className="collection-item-blog w-dyn-item"><a
                                data-w-id="4ef7fad7-e469-9c21-35a8-ce9fc0ff3b63"
                                style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }}
                                href="/post/new-partnership-marks-multi-chain-evolution-for-smart-contracts"
                                className="link-image-blog w-inline-block"><img
                                    src="https://assets.website-files.com/614519168cffbd131c32d792/61451ec9f48304bdea104c6a_blog%203.svg"
                                    loading="lazy"
                                    alt="New Partnership Marks Multi-chain Evolution for Smart Contracts"
                                    className="image-blog" />
                                <div className="bg-blog" style={{ opacity: '0.5' }} /></a>
                                <div className="block-blog">
                                    <div data-w-id="e603bc27-b601-3b89-4492-55877cc273de"
                                        style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }}
                                        className="date-blog large">September 18, 2021</div><a
                                            data-w-id="6b005aeb-eee3-bc41-f276-abe52461547a"
                                            style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }}
                                            href="/post/new-partnership-marks-multi-chain-evolution-for-smart-contracts"
                                            className="link-heading-blog w-inline-block">
                                        <h4 className="heading-blog">New Partnership Marks Multi-chain Evolution for
                                            Smart Contracts</h4>
                                    </a>
                                </div>
                            </div>
                            <div role="listitem" className="collection-item-blog w-dyn-item"><a
                                data-w-id="4ef7fad7-e469-9c21-35a8-ce9fc0ff3b63"
                                style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }}
                                href="/post/launching-the-demo-app-for-core-2-0"
                                className="link-image-blog w-inline-block"><img
                                    src="https://assets.website-files.com/614519168cffbd131c32d792/61451ed0939d3c19cc367822_blog%204.svg"
                                    loading="lazy" alt="Launching the demo app for Core 2.0"
                                    className="image-blog" />
                                <div className="bg-blog" style={{ opacity: '0.5' }} /></a>
                                <div className="block-blog">
                                    <div data-w-id="e603bc27-b601-3b89-4492-55877cc273de"
                                        style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }}
                                        className="date-blog large">September 18, 2021</div><a
                                            data-w-id="6b005aeb-eee3-bc41-f276-abe52461547a"
                                            style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }}
                                            href="/post/launching-the-demo-app-for-core-2-0"
                                            className="link-heading-blog w-inline-block">
                                        <h4 className="heading-blog">Launching the demo app for Core 2.0</h4>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

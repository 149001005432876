import React from 'react'

export default function ExchangeHero() {
    return (
        <>
            <div className="section wf-section pt-140 pb-0 bg-20">
                <div className="content">
                    <div className="w-layout-grid grid-right">
                        <div id="w-node-_5713fedf-471c-a9c3-90d4-7d6642ea17a8-6ad29b8f" className="block">
                            <div data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17a9" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="subtitle">exchange</div>
                            <h2 data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ab" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="heading">India’s leading cryptocurrency exchange.</h2>
                            <div data-w-id="2d6db815-ef82-a123-9a77-26cdadb39017" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="w-layout-grid grid-button">
                                <a href="https://justbit.in/" className="button w-button text-white">Log In</a>
                            </div>
                        </div>
                        <video muted autoPlay loop id="myVideo" className='video-4'><source src="./assets/exchange.webm" type="video/webm" /></video>
                    </div>
                    <div className="w-layout-grid grid-page-b">

                        <div data-w-id="f0aa4076-555c-586f-088d-17e0523b26a1"
                            style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }}
                            className="stats lighter">
                            <h4 className="heading-stats text-black">Popular Assets</h4>
                            <div className="text-stats text-black">Explore hundreds of crypto trading pairs</div>
                        </div>
                        <div data-w-id="27764734-a540-afeb-0e84-c4274378e76a"
                            style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }}
                            className="stats lighter">
                            <h4 className="heading-stats text-black">Fiat Support</h4>
                            <div className="text-stats text-black">Trade crypto with fiat currencies</div>
                        </div>
                        <div data-w-id="918d5c54-f1a0-3064-11f2-3a26b74b3ba9"
                            style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }}
                            className="stats lighter">
                            <h4 className="heading-stats text-black">F&O Trading</h4>
                            <div className="text-stats text-black">Derivatives trading for cryptos</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

import React from "react";
import Header from "../comman/Header";
import Footer from "./Footer";

export default function ComingSoon() {
  return (
    <>
     <Header />

      <div>
         <div className="section-hero wf-section .video-1">
        <div className="content hero">
          <video muted autoPlay loop id="myVideo" className="video">
            <source src="./assets/rupto_hero.mp4" type="video/webm" />
          </video>
          <div className="block-hero h1-comming">
            {/* <div data-w-id="3930e259-75dd-d066-e614-6fbbe9bf2ee5" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="subtitle blink_me"><a href="/validators-presale" className='text-golden icon-a'>Validators Presale - join now</a></div> */}
            <h1
              data-w-id="fec0fb7b-303f-09d0-3ce6-40f91d7252a5"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="heading-hero"
            >
                 Coming soon
            </h1>
        
            <p
              data-w-id="dcd739db-6d1f-6a52-859b-4a3fd0e20c84"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="paragraph-large text-white"
            >
             Stay tuned & join socialmedia. We will make sure to notify you with
            our latest updates.
            </p>
            <div className="main">
            {/* Facebook Icon */}
            <a
              className="icon-a"
              href="https://www.facebook.com/people/Crypto-Tex-Token/100083171064229/"
              target="_blank"
            >
              <div className="icon s-icon">
                <i className="fa fa-facebook-f" />
                <span>Facebook</span>
              </div>
            </a>
            {/* Twitter Icon */}
            <a
              className="icon-a"
              href="https://twitter.com/CryptoTexCoin"
              target="_blank"
            >
              <div className="icon s-icon">
                <i className="fa fa-twitter" />
                <span>Twitter</span>
              </div>
            </a>
            {/* Linkedin Icon */}
            <a
              className="icon-a"
              href="https://www.instagram.com/ctexcoin/"
              target="_blank"
            >
              <div className="icon s-icon">
                <i className="fa fa-instagram" />
                <span>Instagram</span>
              </div>
            </a>
            {/* GitHub Icon */}
            <a
              className="icon-a"
              href="https://t.me/cryptotexofficial"
              target="_blank"
            >
              <div className="icon s-icon">
                <i className="fa fa-telegram" />
                <span>Telegram</span>
              </div>
            </a>
            {/* <a
              className="icon-a"
              href="https://t.me/ruptoofficial"
              target="_blank"
            >
              <div className="icon s-icon">
                <img
                  src="./assets/img/icon/dicorrd.png"
                  width="40"
                  className="fa fa-telegram"
                  alt=""
                />
                <span>Discord</span>
              </div>
            </a> */}
            <a
              className="icon-a text-black"
              href="https://github.com/ctexcoin"
              target="_blank"
            >
              <div className="icon s-icon">
                <i className="fa fa-github" />
                <span>GitHub</span>
              </div>
            </a>
            {/* <a
              className="icon-a"
              href="https://t.me/ruptoofficial"
              target="_blank"
            >
              <div className="icon s-icon">
                <img
                  src="./assets/img/icon/gitlook.png"
                  width="40"
                  className="fa fa-telegram"
                  alt=""
                />
                <span>GitBook</span>
              </div>
            </a> */}
            {/* <a
              className="icon-a"
              href="https://t.me/ruptoofficial"
              target="_blank"
            >
              <div className="icon s-icon">
                <img
                  src="./assets/img/icon/medium.png"
                  width="40"
                  className="fa fa-telegram"
                  alt=""
                />
                <span>Medium</span>
              </div>
            </a> */}
            <a
              className="icon-a text-black"
              href="https://www.reddit.com/r/ctexcoin/?rdt=54742"
              target="_blank"
            >
              <div className="icon s-icon">
                <i className="fa fa-reddit" />
                <span>Reddit</span>
              </div>
            </a>
            {/* YouTube Icon */}
            <a
              className="icon-a text-black"
              href="https://www.linkedin.com/company/crypto-tex"
            >
              <div className="icon s-icon">
                <i className="fa fa-linkedin" />
                <span>LimkedIn</span>
              </div>
            </a>
          </div>
          </div>
        </div>
        <div
          className="w-layout-grid grid-button btn-comimg mb-5"
          style={{
            paddingBottom:"30px",
            transform:
              "translate3d(0px, 15px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            // opacity: 1,
            position:"relative"
          }}
        >
          <a className="button w-button text-white " target="blank" href="https://ctex-network.gitbook.io/developer-documentation/" style={{ width: "200px" }}>
            Documentation
          </a>
          <a className="button w-button text-white " target="blank" href="/developer-resource" style={{ width: "200px" }}>
            Build
          </a>
        </div>

        {/* <div data-w-id="58e11b86-9d96-c9a8-f52f-b1795c37fa57" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="glow-top" /> */}
      </div>
        {/* <img src="./assets/coming-soon.png" className="bg-2-1" alt="" /> */}
   
      </div>
      <Footer/>
    </>
  );
}

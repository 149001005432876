import React from "react";

export default function Trusted() {
  return (
    <>
      <div className="section wf-section">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <div className="block-heading">
            <div
              data-w-id="823c362f-ea60-5ffd-ca7f-cde6a03d15bb"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="subtitle"
            >
              Validator Reward
            </div>
            <h2
              data-w-id="edc71457-7d5b-7bea-ff46-ddb5540db920"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="heading"
            >
              Validator Rewards
            </h2>
            <p>
              Join CTEX as validator and secure the network. Participate & stake
              your CTEX tokens to get access to the CTEX node.
            </p>
          </div>

          <div className="w-layout-grid grid-page-a"></div>
          <div className="w-layout-grid grid-page-b">
            <div
              data-w-id="f0aa4076-555c-586f-088d-17e0523b26a1"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="stats lighter"
            >
              <h4 className="heading-stats text-black">25 CTEX</h4>
              <div className="text-stats text-black">
                Stake to Become Validator
              </div>
            </div>
            <div
              data-w-id="27764734-a540-afeb-0e84-c4274378e76a"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="stats lighter"
            >
              <h4 className="heading-stats text-black">2 Year</h4>
              <div className="text-stats text-black">Staking Compulsory</div>
            </div>
            <div
              data-w-id="918d5c54-f1a0-3064-11f2-3a26b74b3ba9"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="stats lighter"
            >
              <h4 className="heading-stats text-black">12% APY</h4>
              <div className="text-stats text-black">on Validator Staking</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

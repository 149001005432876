import React from 'react'
import Footer from '../comman/Footer'
import Header2 from '../comman/Header2'
import SubmitForm from './SubmitForm'

export default function SubmitHash() {
    document.title = 'Submit Hash - Rupto';
    return (
        <>
            <Header2 />
            <SubmitForm />
            <Footer />
        </>
    )
}

import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import ValidatingRpt from './ValidatingRpt'
import ValidatorsBox from './ValidatorsBox'
import ValidatorsHero from './ValidatorsHero'
import ValidatorsReward from './ValidatorsReward'
import ValidatosDetails from './ValidatosDetails'

export default function BecomeValidators() {
    return (
        <>
        <Header/>
        <ValidatorsHero/>
        <ValidatosDetails/>
        <ValidatorsReward/>
        <ValidatingRpt/>
        <ValidatorsBox/>
        <Footer/>
        </>
    )
}

import React from 'react'

export default function ComeWork() {
    return (
        <>
            <div className="section wf-section">
                <div className="content">
                    <div data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc498b" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="subtitle text-center">COME WORK WITH US</div>
                    <div className="w-layout-grid grid-3">
                        <div className='link-underlined'>
                            <h4>Open Positions</h4>
                        </div>
                        <div id="w-node-a50c89a1-4e8e-1cda-83cc-d3e74bcc498a-e3eb6dcf" className="block link-underlined">
                            <h6 data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc498d" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="heading">Business Intelligence</h6>
                            <ul className='d-flex-1 list-style-none mb-0'>
                                <li className='float-start'>
                                    <a href="/job-desc" className='icon-a'><p data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc4991" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="paragraph-large-2 text-white">Assistant Manager (1) &nbsp;&nbsp;&nbsp;&nbsp; </p></a>
                                    <a href="/job-desc" className='icon-a'><p data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc4991" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="paragraph-large-2 text-white">Growth Hacker (1)</p></a>
                                </li>
                                <li className='float-end'>
                                    <a href="/job-desc" className='icon-a'><p data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc4991" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="paragraph-large-2 text-white">Remote</p></a>
                                    <a href="/job-desc" className='icon-a'><p data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc4991" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="paragraph-large-2 text-white">Remote</p></a>
                                </li>
                            </ul>
                        </div>
                        <div id="w-node-a50c89a1-4e8e-1cda-83cc-d3e74bcc498a-e3eb6dcf" className="block link-underlined">
                            <h6 data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc498d" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="heading">Developer</h6>
                            <ul className='d-flex-1 list-style-none mb-0'>
                                <li className='float-start'>
                                    <a href="/job-desc" className='icon-a'><p data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc4991" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="paragraph-large-2 text-white">Blockchain Developer (3)</p></a>
                                </li>
                                <li className='float-end'>
                                    <a href="/job-desc" className='icon-a'><p data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc4991" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="paragraph-large-2 text-white">Remote</p></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

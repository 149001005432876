import React from "react";

export default function EventSEction() {
  return (
    <>
      <div
        className="section banner wf-section bg-light"
        style={{ justifyItems: "center", paddingTop: "150px" }}
      >
        <div className="content">
          <div
            data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb69"
            className="group-banner ptblr--50 bg-img"
          >
            <div className="block-banner " style={{ alignItems: "start" }}>
              <h3
                data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb6b"
                className="heading-banner text-black text-left"
              >
                Are you interested in hosting an Event for the CTEX Ecosystem?
              </h3>
              <p>Let us know! we will gladly provide support 👋</p>
              <a type="submit" className="button w-button btn-black">
                Apply
              </a>
              {/* <img src="./assets/hand.png" alt="" /> */}
            </div>
            {/* <div className="glow-banner" /> */}
          </div>
        </div>
      </div>
    </>
  );
}

import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import BrandkitHero from './BrandkitHero'
import Brandkitlogo from './Brandkitlogo'

export default function Brandkit() {
    document.title = `Brand Kit - ${process.env.REACT_APP_TITLE}`;
    return (
        <>
        <Header/>
        <BrandkitHero/>
        <Brandkitlogo/>
        <Footer/>
        </>
    )
}

import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import Forum from './Forum'
import GovernanceHero from './GovernanceHero'
import GovernanceResoures from './GovernanceResoures'
import MakeVoice from './MakeVoice'
import Process from './Process'
import Proposal from './Proposal'
import Voting from './Voting'

export default function Governance() {
  return (
    <>
    <Header/>
    <GovernanceHero/>
    <MakeVoice/>
    <Process/>
    <GovernanceResoures/>
    {/* <Forum/>
    <Proposal/>
    <Voting/> */}
    <Footer/>
    </>
  )
}

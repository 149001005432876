import React from 'react'
import ComingSoon from '../comman/ComingSoon'

export default function Bridge() {
    return (
        <>
            <ComingSoon />
        </>
    )
}

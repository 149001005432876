import React from "react";

export default function ProtocolHero() {
  return (
    <>
      <div className="section wf-section bg-7">
        <div className="content hero">
          {/* <img src="../assets/img/protocol-background.png" className='bg' alt="" /> */}
          <div className="block-hero">
            <h1
              data-w-id="fec0fb7b-303f-09d0-3ce6-40f91d7252a5"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="heading-hero"
            >
              CTEX Protocol
            </h1>
            <p
              data-w-id="dcd739db-6d1f-6a52-859b-4a3fd0e20c84"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="paragraph-large text-white"
            >
              CTEX introduces the Proof-Of-Asset Security model for asset surety
              and transparency. The total assets of the CTEX project are pegged
              to real world land.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import Footer from "../comman/Footer";
import Header from "../comman/Header";
import About from "./About";
import Build from "./Build";
import Community from "./Community";
import Ecosystem from "./Ecosystem";
import Feature from "./Feature";
import Governance from "./Governance";
import Hero from "./Hero";
import Overview from "./Overview";
import Participate from "./Participate";
import Pataner from "./Pataner";
import Products from "./Products";
import SetupNode from "./SetupNode";
import Status from "./Status";
import Subscribe from "./Subscribe";
import Transaction from "./Transaction";
import Validators from "./Validators";
import Whitepaper from "./Whitepaper";

export default function Index() {
  document.title =
    " - Building The Decentralized Tokenized Economy With Real World Assets ";
  return (
    <>
      <Header />
      <Hero />
      <Transaction />
      <Status />
      <Feature />
      {/* <Validators/> */}
      {/* <About/> */}
      {/* <Products/> */}
      {/* <Overview/> */}
      <Build />
      <SetupNode />
      <Governance />
      {/* <Ecosystem/> */}
      {/* <Whitepaper/> */}
      {/* <Subscribe/> */}
      <Pataner />
      <Participate />
      <Community />
      <Footer />
    </>
  );
}

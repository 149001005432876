import React from "react";

export default function ValidatingRpt() {
  return (
    <>
      <div className="block-heading bg-light">
        <h2
          data-w-id="68275e70-ddcc-9d46-397e-61d7b3551ba6"
          style={{
            WebkitTransform:
              "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            MozTransform:
              "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            msTransform:
              "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            transform:
              "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            opacity: 1,
          }}
          className="heading text-black"
        >
          Validating on CTEX Blockchain
        </h2>
      </div>
      <div className="accordion bg-light">
        <details>
          <p className="text-black">
            CTEX blockchain is a layer1 blockchain network powered by
            proof-of-asset security (PoAS) mechanism.
          </p>
          <summary>
            <h6 className="text-black">What is CTEX Blockchain?</h6>
          </summary>
        </details>
        <details>
          <p className="text-black">
            CTEX validators are the backbone of CTEX protocol. They validate and
            verify transactions on the CTEX blockchain network and are provided
            with incentives by doing so.
          </p>
          <summary>
            <h6 className="text-black">Why become a validator on CTEX?</h6>
          </summary>
        </details>
        <details>
          <p className="text-black">
            Validators are provided with two rewards; (i) transaction rewards
            for validating network transactions and (ii) block rewards for
            adding transaction blocks into the blockchain network.
          </p>
          <summary>
            <h6 className="text-black">
              What rewards are validators provided?
            </h6>
          </summary>
        </details>
        <details>
          <p className="text-black">
            Currently it requires 25 CTEX to run a validator node.
          </p>
          <summary>
            <h6 className="text-black">
              How much CTEX is required to stake to become a validator?
            </h6>
          </summary>
        </details>
        <details>
          <p className="text-black">
            RPC is a remote procedure call protocol which forms a bridge between
            the web programming languages and the CTEX for blockchain data
            showcasing.
          </p>
          <summary>
            <h6 className="text-black">What is CTEX RPC?</h6>
          </summary>
        </details>
        <details>
          <p className="text-black">
            It completely depends on the uptime of the validator node and number
            of blocks it broadcasts to the network.
          </p>
          <summary>
            <h6 className="text-black">
              How much approximate reward a validator gets by validating
              transactions?
            </h6>
          </summary>
        </details>
        <details>
          <p className="text-black">
            Staking reward for validator varies to total staked CTEX on the
            network. Current APR is ∼15%.
          </p>
          <summary>
            <h6 className="text-black">
              How much staking reward validators receive?
            </h6>
          </summary>
        </details>
      </div>
    </>
  );
}

import React from "react";
import Footer from "../comman/Footer";
import Header from "../comman/Header";

export default function Architecture() {
  return (
    <>
      <Header />
      <div className="section-hero wf-section">
        <div className="content hero">
          <img src="../assets/img/about-bg.png" className="bg" alt="" />
          <div className="block-hero">
            <h1
              data-w-id="fec0fb7b-303f-09d0-3ce6-40f91d7252a5"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="heading text-left"
            >
              The Architecture of RBC
            </h1>
          </div>
        </div>
      </div>

      <div className="section-hero wf-section pt-0 bg-light">
        <div className="content single-page pt-0 mt-0">
          <div
            data-w-id="b18b81cf-a2db-0c97-0718-4b6d84339d65"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              opacity: 1,
              transformStyle: "preserve-3d",
            }}
            className="rich-text-block w-richtext text-black"
          >
            <p>
            The architecture of CTEX Chain is designed to provide a robust and scalable infrastructure for decentralized applications (dApps), smart contracts, and token transactions. Here's an overview of its architecture:

            </p>

            {/* <h4 className="text-black">Layers in CTEX Blockchain</h4> */}

            <h5 className="text-black">Layer 7 Blockchain Platform</h5>
            <p>
            CTEX Chain operates as a Layer 7 blockchain platform, built on top of existing blockchain technologies like the Ethereum Virtual Machine (EVM). Layer 7 refers to the application layer of the OSI model, which includes protocols and services that directly support user applications. This architecture enables CTEX Chain to offer high-level services such as decentralized finance (DeFi), GameFi, NFTs, and Metaverse applications.
            </p>

            <h5 className="text-black">Ethereum Virtual Machine (EVM) Compatibility</h5>
            <p>
            CTEX Chain is fully compatible with the Ethereum Virtual Machine (EVM), allowing developers to leverage existing tools, languages, and smart contracts from the Ethereum ecosystem. This compatibility ensures seamless migration of dApps and smart contracts from Ethereum to CTEX Chain, making it easier for developers to build and deploy decentralized applications.
            </p>

            <h5 className="text-black">Consensus Mechanism</h5>
            <p>
            CTEX Chain utilizes a Delegated Proof of Stake (DPoS) consensus mechanism to validate transactions and secure the network. In DPoS, token holders vote for a select group of delegates who are responsible for producing blocks and verifying transactions. This approach provides a balance between decentralization, scalability, and energy efficiency.
            </p>

            <h5 className="text-black">Validator Nodes</h5>
            <p>
            Validator nodes play a crucial role in the consensus process by producing blocks, validating transactions, and maintaining the integrity of the blockchain. These nodes are elected by token holders based on their stake in the network. Validator nodes are incentivized to perform their duties honestly and efficiently to earn rewards in the form of transaction fees and block rewards.
            </p>
            <h5 className="text-black">Smart Contracts and Decentralized Applications (dApps)</h5>
            <p>
            CTEX Chain supports the execution of smart contracts and decentralized applications (dApps) on its platform. Smart contracts are self-executing contracts with the terms of the agreement directly written into code. Developers can build a wide range of dApps, including DeFi protocols, gaming platforms, NFT marketplaces, and more, using languages like Solidity.
            </p>
            <h5 className="text-black">Interoperability</h5>
            <p>
            CTEX Chain aims to enhance interoperability with other blockchain networks, allowing for seamless communication and exchange of assets across different platforms. This interoperability is achieved through standards like the Inter-Blockchain Communication (IBC) protocol, which enables communication between disparate blockchains.
            </p>
            <h5 className="text-black">Scalability and Performance</h5>
            <p>
            CTEX Chain is designed to be highly scalable, capable of handling a large number of transactions per second (TPS) while maintaining low latency and high throughput. The architecture allows for horizontal scaling by adding more validator nodes to the network, ensuring optimal performance as the platform grows.
            </p>
            <p>Overall, the architecture of CTEX Chain is engineered to provide a secure, scalable, and developer-friendly platform for building decentralized applications and facilitating token transactions in the blockchain ecosystem.


</p>
          </div>
        </div>
        <div
          data-w-id="c8bf05fb-9eee-0307-c6c4-6adf4ca0a1dc"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="glow-top"
        />
      </div>
      <Footer />
    </>
  );
}

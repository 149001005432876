import React from "react";
import Footer from "../comman/Footer";
import Header from "../comman/Header";

export default function UnderstandingRupto() {
  return (
    <>
      <Header />
      <div className="section-hero wf-section">
        <div className="content hero">
          <img src="../assets/img/about-bg.png" className="bg" alt="" />
          <div className="block-hero">
            <h1
              data-w-id="fec0fb7b-303f-09d0-3ce6-40f91d7252a5"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="heading text-left"
            >
              Understanding the CTEX Blockchain
            </h1>
          </div>
        </div>
      </div>

      <div className="section-hero wf-section pt-0 bg-light">
        <div className="content single-page pt-0 mt-0">
          <div
            data-w-id="b18b81cf-a2db-0c97-0718-4b6d84339d65"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              opacity: 1,
              transformStyle: "preserve-3d",
            }}
            className="rich-text-block w-richtext text-black"
          >
            <h4 className="text-black">What is CTEX Blockchain</h4>
            <p>
              CTEX Blockchain (RBC) is a layer 1 blockchain network using the
              proof-of-asset security model. The asset on the CTEX blockchain
              network derives the value from real world assets through
              tokenization.
            </p>
            <p>
              The PoAS is the heart of CTEX protocol and RBC. Every blockchain
              requires a validation process to achieve consensus. It helps in
              keeping the blockchain network safe & secure. The proof-of-asset
              security consensus follows the architecture of the tokenized
              security model. The concept of this model is tokenization of
              actual property on the blockchain network. Each CTEX taken (CTEX)
              represents a piece of actual property in the real world. This
              property is fractionated using tokenization on the blockchain.
            </p>

            <p>1 CTEX = 10 SqrFt land</p>
            <p>350,000 CTEX = 3,500,000 SqrFt land</p>
            <h4 className="text-black">Features</h4>

            <h5 className="text-black">EVM Compatible</h5>
            <p>
              As being an EVM compatible blockchain, CTEX blockchain allows one
              click deployment and easy integrations of Ethereum based dApps.
            </p>

            <h5 className="text-black">Quick Transactions</h5>
            <p>
              The transactions on CTEX blockchain are faster than other L1
              blockchain networks.
            </p>

            <h5 className="text-black">Linear Scalable</h5>
            <p>
              CTEX architecture increases the transaction counts along with
              network capacity to achieve linear scaling.
            </p>

            <h5 className="text-black">Energy Efficient</h5>
            <p>
              It requires less energy to run a CTEX blockchain node compared to
              other blockchain networks.
              <b>
                In the majority of PoS blockchain networks, the incentive to
                participate in the block validating process is a payout in the
                form of transaction fees and block rewards. In the case of CTEX
                blockchain, participants are incentivized with CTEX tokens.
              </b>
            </p>
            <p>
              CTEX Blockchain follows the lowest fee possible model as its
              business model is not solely dependent on transaction fees. It
              requires almost less than a cent to transact on the CTEX
              blockchain. From the transaction fee, 25% goes to the developer
              fund which is used to empower and incentivize the developer
              community of the CTEX ecosystem.
            </p>
          </div>
        </div>
        <div
          data-w-id="c8bf05fb-9eee-0307-c6c4-6adf4ca0a1dc"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="glow-top"
        />
      </div>
      <Footer />
    </>
  );
}

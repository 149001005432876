import React from "react";

export default function PlatformHero() {
  return (
    <>
      <div className="section wf-section pt-0 pb-0  bg-light bg-12">
        <div className="content hero">
          <div className="w-layout-grid grid-right">
            <div
              id="w-node-_5713fedf-471c-a9c3-90d4-7d6642ea17a8-6ad29b8f"
              className="block"
            >
              <h1
                data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ab"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                  textAlign: "left",
                }}
                className="heading text-black"
              >
                The CTEX Platform
              </h1>
            </div>
            <div className="block-right">
            <img src="./assets/img/ctex_platform_hero_banner.png" alt="" />
              {/* <video muted autoPlay loop id="myVideo" className="video-4">
                <source
                  src="./assets/img/rupto_platform.webm"
                  type="video/webm"
                />
              </video> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useContext, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Mycontext } from "../../MyContext";
import copy from "copy-to-clipboard";
import logo from "../../load.gif";

export default function Header() {
  const [buttonText, setButtonText] = useState("");
  const [button, setButton] = useState(false);

  const handleClick = () => {
    setButtonText("Copied!");
    console.log("hello");
    setTimeout(() => {
      setButtonText("");
    }, 3000);
  };
  const copyaddress = (address) => {
    copy(address);
  };
  // let [loading, setLoading] = useState(true);
  // let [color, setColor] = useState("#ffffff");

  const { account, connectMetamask, formatAddress, disconnect } =
    useContext(Mycontext);
  const history = useNavigate();

  // const [isHide, setIsHide] = useState(true);
  const [isClick, setIsClick] = useState(false);
  // setTimeout(() => setIsHide(false), 5000);

  const [toggleState, setToggleState] = useState(true);
  return (
    <>
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration={400}
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="navbar w-nav"
      >
        <div className="w-layout-gri grid-navbar menu">
          <div data-w-id="e8cea9b6-4627-f1cd-fec0-16ebfaf55914" className="nav">
            <a
              href="/"
              aria-current="page"
              className=" w-nav-brand w--current"
            >
              <img src="logo512.png" loading="lazy" alt="" className="logo" />
            </a>
          </div>

          <div data-w-id="e8cea9b6-4627-f1cd-fec0-16ebfaf55917" className="nav">
            <nav
              role="navigation"
              className={
                toggleState
                  ? "nav-menu w-nav-menu respons-nav-top"
                  : "nav-menu w-nav-menu"
              }
            >
              <a href="/about" className="nav-link w-nav-link">
                About
              </a>
              <a href="products" className="nav-link w-nav-link">
                Ecosystem
              </a>
              <a
                href="/whitepaper"
                aria-current="page"
                className="nav-link w-nav-link "
                activeClassName="active w--current"
              >
                Research
              </a>
              <a href="/developer-resource" className="nav-link w-nav-link">
                Developer
              </a>
              <a href="/network" className="nav-link w-nav-link">
                Network
              </a>
              <a href="/governance" className="nav-link w-nav-link">
                Governance
              </a>
            </nav>
          </div>
          <div className="nav-dflex"></div>
          <div
            className="nav right"
            onClick={() => {
              setToggleState(!toggleState);
            }}
          >
            <div
              data-w-id="e8cea9b6-4627-f1cd-fec0-16ebfaf55924"
              className=" grid-button-nav"
            >
              <div
                className={
                  toggleState
                    ? "menu-button w-nav-button"
                    : "menu-button w-nav-button w--open"
                }
                style={{ WebkitUserSelect: " text" }}
                aria-label="menu"
                role="button"
                tabindex="0"
                aria-controls="w-nav-overlay-0"
                aria-haspopup="menu"
                aria-expanded={toggleState ? "true" : "false"}
              >
                <div className="menu-icon w-icon-nav-menu" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="shadow-navbar"
          style={{ willChange: "opacity", opacity: "0" }}
        />
        <div
          className="w-nav-overlay"
          data-wf-ignore=""
          id="w-nav-overlay-0"
          style={
            toggleState
              ? { display: "none" }
              : { height: "5412.05px", display: "block" }
          }
        >
          {toggleState ? (
            ""
          ) : (
            <nav
              role="navigation"
              className="nav-menu"
              style={{
                transform: "translateY(0px) translateX(0px)",
                transition: "transform 400ms ease 0s",
              }}
            >
              <a
                href="/"
                aria-current="page"
                className="nav-link w-nav-link w--nav-link-open w--current"
                activeClassName=""
              >
                Home
              </a>
              <a
                href="/validators-presale"
                className="nav-link w-nav-link w--nav-link-open"
              >
                Validators
              </a>
              <a href="#" className="nav-link w-nav-link w--nav-link-open">
                Technology
              </a>
              <a href="#" className="nav-link w-nav-link w--nav-link-open">
                Blog
              </a>
              <a href="#" className="nav-link w-nav-link w--nav-link-open">
                Contact
              </a>
            </nav>
          )}
        </div>
      </div>
    </>
  );
}

import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import AllAssets from './AllAssets'
import BuyCrypto from './BuyCrypto'
import Earnstaking from './Earnstaking'
import ExchangeCrypto from './ExchangeCrypto'
import PayCrypto from './PayCrypto'
import WalletHero from './WalletHero'

export default function Wallet() {
  return (
    <>
    <Header/>
    <WalletHero/>
    <PayCrypto/>
    <BuyCrypto/>
    <Earnstaking/>
    <ExchangeCrypto/>
    <AllAssets/>
    <Footer/>
    </>
  )
}

import React from "react";

export default function NodeList() {
  return (
    <>
      <div className="section wf-section section-padding">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <h4
            data-w-id="fec0fb7b-303f-09d0-3ce6-40f91d7252a5"
            style={{
              WebkitTransform:
                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              MozTransform:
                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              msTransform:
                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              transform:
                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              opacity: 1,
            }}
            className=" pb-30"
          >
            Node List
          </h4>
          <div className="w-layout-grid grid-page-b">
            <div
              data-w-id="f0aa4076-555c-586f-088d-17e0523b26a1"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="stats lighter"
            >
              <h6 className="heading-stats mb-30 text-black">
                #0x3e8...c3e3d &nbsp;<i className="fa fa-copy"></i>
              </h6>
              <h6 className="heading-stats fs-20 text-black">CTEX</h6>
              <div className="text-stats text-black">50</div>
              <h6 className="heading-stats fs-20 text-black">Rate</h6>
              <div className="text-stats text-black">1$</div>
              <h6 className="heading-stats fs-20 text-black">USDT</h6>
              <div className="text-stats text-black">50$</div>
              <h6 className="heading-stats fs-20 text-black">Date </h6>
              <div className="text-stats text-black">11/09/22</div>
              <h6 className="heading-stats fs-20 text-black">Status</h6>
              <div className="text-stats text-black">Active</div>
            </div>
            <div
              data-w-id="27764734-a540-afeb-0e84-c4274378e76a"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="stats lighter"
            >
              <h6 className="heading-stats mb-30 text-black">
                #0x3e8...c3e3d &nbsp;<i className="fa fa-copy"></i>
              </h6>
              <h6 className="heading-stats fs-20 text-black">CTEX</h6>
              <div className="text-stats text-black">50</div>
              <h6 className="heading-stats fs-20 text-black">Rate</h6>
              <div className="text-stats text-black">1$</div>
              <h6 className="heading-stats fs-20 text-black">USDT</h6>
              <div className="text-stats text-black">50$</div>
              <h6 className="heading-stats fs-20 text-black">Date </h6>
              <div className="text-stats text-black">11/09/22</div>
              <h6 className="heading-stats fs-20 text-black">Status</h6>
              <div className="text-stats text-black">Active</div>
            </div>
            <div
              data-w-id="918d5c54-f1a0-3064-11f2-3a26b74b3ba9"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="stats lighter"
            >
              <h6 className="heading-stats mb-30 text-black">
                #0x3e8...c3e3d &nbsp;<i className="fa fa-copy"></i>
              </h6>
              <h6 className="heading-stats fs-20 text-black">CTEX</h6>
              <div className="text-stats text-black">50</div>
              <h6 className="heading-stats fs-20 text-black">Rate</h6>
              <div className="text-stats text-black">1$</div>
              <h6 className="heading-stats fs-20 text-black">USDT</h6>
              <div className="text-stats text-black">50$</div>
              <h6 className="heading-stats fs-20 text-black">Date </h6>
              <div className="text-stats text-black">11/09/22</div>
              <h6 className="heading-stats fs-20 text-black">Status</h6>
              <div className="text-stats text-black">Active</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

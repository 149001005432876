import React from "react";

export default function Brandkitlogo() {
  return (
    <>
      <div className="section wf-section light-bg">
        <div className="content">
          <div className="w-layout-grid grid-team-2">
            <div
              data-w-id="59875d70-392d-8277-03db-77a776d77ce9"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="team-member"
            >
              <a
                href="#"
                target="_blank"
              >
                <img
                  src="./assets/img/brand-kit/rupto.png"
                  loading="lazy"
                  alt=""
                  className="image-member-2"
                />
              </a>
              {/* <div className="block-member">
                                <h6 className="name-member">Jessica Sanders</h6>
                                <div className="info-member">Developer</div>
                            </div> */}
            </div>
            <div
              data-w-id="bb94df64-cb79-9bf0-537f-3833576410c6"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="team-member"
            >
              <a
                href="#"
                target="_blank"
              >
                <img
                  src="./assets/img/brand-kit/fact-dekho.png"
                  loading="lazy"
                  alt=""
                  className="image-member-2 w-50"
                />
              </a>
            </div>
            <div
              data-w-id="2fb9d41c-1668-1d44-4857-66053502bdb9"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="team-member"
            >
              <a
                href="#"
                target="_blank"
              >
                <img
                  src="./assets/img/brand-kit/goomojoomo-01.png"
                  loading="lazy"
                  alt=""
                  className="image-member-2"
                />
              </a>
            </div>
            <div
              data-w-id="5ae747ee-7677-ab47-8ba9-6eef4b9b269f"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="team-member"
            >
              <a
                href="#"
                target="_blank"
              >
                <img
                  src="./assets/img/brand-kit/tap-info.png"
                  loading="lazy"
                  alt=""
                  className="image-member-2"
                />
              </a>
            </div>
            <div
              data-w-id="542b0ad5-f079-ec93-2320-72cc8e823a8c"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="team-member"
            >
              <a
                href="#"
                target="_blank"
              >
                <img
                  src="./assets/img/brand-kit/just-bit-black.png"
                  loading="lazy"
                  alt=""
                  className="image-member-2"
                />
              </a>
            </div>
            <div
              data-w-id="722dc918-0e1e-8388-7f12-3f22e025ad84"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="team-member"
            >
              <a
                href="#"
                target="_blank"
              >
                <img
                  src="./assets/img/brand-kit/just-real.png"
                  loading="lazy"
                  alt=""
                  className="image-member-2"
                />
              </a>
            </div>
            <div
              data-w-id="13f02683-3e9e-6692-e7ca-800c0551da85"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="team-member"
            >
              <a
                href="#"
                target="_blank"
              >
                <img
                  src="./assets/img/brand-kit/mobistar-black.png"
                  loading="lazy"
                  alt=""
                  className="image-member-2"
                />
              </a>
            </div>
            <div
              data-w-id="21e7ed7f-4604-10b1-748b-37a1e6e22ce1"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="team-member"
            >
              <a
                href="#"
                target="_blank"
              >
                <img
                  src="./assets/img/brand-kit/self-mart.png"
                  loading="lazy"
                  alt=""
                  className="image-member-2"
                />
              </a>
            </div>
            <div
              data-w-id="59875d70-392d-8277-03db-77a776d77ce9"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="team-member"
            >
              <a
                href="#"
                target="_blank"
              >
                <img
                  src="./assets/img/brand-kit/sprtfan-12.png"
                  loading="lazy"
                  alt=""
                  className="image-member-2"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

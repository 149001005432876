import React from "react";

export default function PresaleReward() {
  return (
    <>
      <div className="section wf-section">
        <div className="content">
          <div className="w-layout-grid grid-right">
            <div className="block">
              <div
                data-w-id="10717017-9153-2952-45a2-9777c8214290"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="subtitle"
              >
                Presale Reward
              </div>
              <h2
                data-w-id="9ba3e33b-0e41-bbb4-d783-b036597e5f42"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="heading"
              >
                Presale Reward
              </h2>
              <ul role="list">
                <li>60% Reward From Transaction Gas.</li>
                <li>
                  Each node will receive 60% of gas of the transactions it
                  validate.
                </li>
                <li>25 CTEX Bonus While booking node in Presale.</li>
                <li>
                  The bonus will be rewarded 5% monthly after mainnet release.
                </li>
              </ul>
            </div>
            <div className="block-right">
              <div
                data-w-id="aa0c30b7-4b0e-5634-dacf-abd61c960570"
                className="figure-b"
              >
                <img src="./assets/img/presale.png" alt="" />
                {/* <div className="figure-block-b" style={{ willChange: 'transform', transform: 'translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(-6.86332deg) rotateY(47.3534deg) rotateZ(6.31728deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>
                                    <div style={{ width: '80%', willChange: 'transform', transform: 'translate3d(0px, 0px, 13.3654vh) scale3d(1, 1, 1) rotateX(-11.949deg) rotateY(0deg) rotateZ(9.61812deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', height: '300px' }} className="figure-b1" />
                                    <div style={{ width: '80%', willChange: 'transform', transform: 'translate3d(0px, 3.16076%, -7.69476vh) scale3d(1, 1, 1) rotateX(-5.9694deg) rotateY(0deg) rotateZ(6.41208deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', height: '325px' }} className="figure-b2" />
                                    <div style={{ width: '90%', willChange: 'transform', transform: 'translate3d(0px, 17.546%, -27.755vh) scale3d(1, 1, 1) rotateX(0.0102deg) rotateY(0deg) rotateZ(3.20604deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', height: '325px' }} className="figure-b3" />
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

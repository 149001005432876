import React from "react";

export default function Conversation() {
  return (
    <>
      <div className="section wf-section bg-light">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <div className="w-layout-grid grid-page-a">
            <div
              data-w-id="b243638b-925b-7205-18ef-8dd1bbb756f7"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="tasks"
            >
              <div className="tasks-block">
                <img
                  src="./assets/img/mission.svg"
                  loading="lazy"
                  alt=""
                  className="icon-tasks"
                />
                <h5 className="heading-tasks text-black">CTEX Developers</h5>
              </div>
              <p className="text-black">
                Join & BUIDL on CTEX with thousands of DeFi developers building
                dApps and other decentralized solutions. The developer community
                aims to grow the CTEX ecosystem by building prominent
                decentralized applications.
              </p>
            </div>
            <div
              data-w-id="f6d37ef0-7429-a241-58f8-4b5be9e2265d"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="tasks"
            >
              <div className="tasks-block">
                <img
                  src="./assets/img/role.svg"
                  loading="lazy"
                  alt=""
                  className="icon-tasks"
                />
                <h5 className="heading-tasks text-black">CTEX Enthusiasts</h5>
              </div>
              <p className="text-black">
                Community members of the CTEX ecosystem are there to help &
                support the growth of CTEX protocol to achieve its mission and
                beyond.
              </p>
            </div>
            {/* <div data-w-id="f6d37ef0-7429-a241-58f8-4b5be9e2265d" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="tasks">
                            <div className="tasks-block"><img src="./assets/img/role.svg" loading="lazy" alt="" className="icon-tasks" />
                                <h5 className="heading-tasks text-black">Rupto Enthusiasts</h5>
                            </div>
                            <p className='text-black'>Community members of the Rupto ecosystem are there to help & support the growth of Rupto protocol to achieve its mission and beyond.</p>
                        </div> */}
          </div>
          <div
            data-w-id="9040b537-44e1-e314-8717-bd0bd8cbe0a4"
            style={{
              WebkitTransform:
                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              MozTransform:
                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              msTransform:
                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              transform:
                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              opacity: 1,
            }}
            className="stats lighter"
          >
            <a href="/imself" target="_blank" className="icon-a">
              <h5 className="heading-stats text-black">Trading Community</h5>
              <br />
              <p className="text-black">
                The CTEX Exchange hosts a vibrant cryptocurrency trading
                community where traders can engage in real-time discussions,
                share insights, and collaborate on trading strategies. Join us
                to stay updated on market trends and explore new trading
                opportunities.{" "}
              </p>

              {/* <div className="text-stats text-black">Rupto whitepaper represents overall structure for the Rupto project from the idea to implementation into a working product & functioning application. All the content included into this whitepaper is a blueprint for the design & development of Rupto.</div> */}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";

export default function CtexNetwork() {
  return (
    <>
      <div className="section-network wf-section bg-light">
        <div className="content hero">
          {/* <img src="../assets/img/protocol-background.png" className='bg' alt="" /> */}
          <div className="block-hero">
            <p
              data-w-id="dcd739db-6d1f-6a52-859b-4a3fd0e20c84"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="paragraph-large text-black"
            >
              CTEX Network
            </p>
            <h1
              data-w-id="fec0fb7b-303f-09d0-3ce6-40f91d7252a5"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="heading-hero text-black"
            >
              Blockchain Ecosystem for Web3 Payments
            </h1>
            <p
              data-w-id="dcd739db-6d1f-6a52-859b-4a3fd0e20c84"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
                maxWidth: "900px",
              }}
              className="paragraph-large text-black"
            >
              A high barrier of entry for businesses accessing digital payments
              is restricting global trade and innovation. However, companies can
              now provide payment services for their customers and enable the
              exchange of goods and services using the blockchain.
            </p>
            <p
              data-w-id="dcd739db-6d1f-6a52-859b-4a3fd0e20c84"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
                maxWidth: "900px",
              }}
              className="paragraph-large text-black"
            >
              There are hundreds of apps and services in the CTEX ecosystem
              providing community and business focussed Web3 payment solutions
              with more than 100 million transactions completed. Nonetheless,
              most consumers will not use the blockchain for payments directly,
              as crypto-natives do now.
            </p>
            <p
              data-w-id="dcd739db-6d1f-6a52-859b-4a3fd0e20c84"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
                maxWidth: "900px",
              }}
              className="paragraph-large text-black"
            >
              Instead, businesses will build applications to make the experience
              of Web3 payments and other financial transactions close to what
              users are used to. These can run on the blockchain but will not
              force users to handle private keys or pay transaction fees.
            </p>
          </div>
        </div>
      </div>
    </>
    //   <i className="fa fa-check"></i>
  );
}

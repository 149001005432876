import React from "react";

export default function ForUsers() {
  return (
    <>
      <div
        className="section banner wf-section bg-light pb-120"
        style={{ justifyItems: "center" }}
      >
        <div className="content ">
          <div
            data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb69"
            className=" ptblr-30 "
          >
            <div className="content bg-dark" style={{borderRadius:"30px", padding:"20px"}}>
              <div
                className="w-layout-grid grid-right "
                style={{ paddingLeft: "10px", textAlign: "start" }}
              >
                <div
                  id="w-node-_5713fedf-471c-a9c3-90d4-7d6642ea17a8-6ad29b8f"
                  className="block"
                >
                  {/* <div data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17a9" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="subtitle">Why PoAS</div> */}
            <div data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17a9" className="subtitle" style={{transform: 'translate3d(0px, 15px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1}}> For Users</div>

                  <h5
                    data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ab"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: 1,
                    }}
                    className="heading text-black text-white"
                  >
                    Financial products and services
                  </h5>
                  <p
                    data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ad"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: 1,
                    }}
                    className="paragraph-large text-justify text-white"
                  >
                    The CTEX Wallet lets users store and use digital assets in a
                    non-custodial wallet. Get up and running using white label
                    templates or integrate our SDK to your existing mobile
                    product.
                  </p>
                  <div className="d-flex">
                    <ul className="">
                      <li>Mobile wallets</li>
                      <li>Apps</li>
                      <li>Marketplaces</li>
                      <li>Swap/trading</li>
                    </ul>
                    <ul className="">
                      <li>P2P payments</li>
                      <li>B2B payments</li>
                      <li>Loyalty</li>
                    </ul>
                  </div>
                  {/* <p
                    data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ad"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: 1,
                    }}
                    className="paragraph-large text-justify"
                  >
                    Each CTEX token is assigned with a value of 10 SqFt physical
                    land for a total of 3,500,000 SqFt of plot area. The token
                    supply for CTEX is fixed at 350,000 with each token
                    equivalent to 10 SqFt land area.
                  </p> */}
                  {/* <h6 className="text-black">1 CTEX = 10 SqrFt Land</h6> */}
                  {/* <h6 className="text-black">
                    350,000 CTEX = 3,500,000 SqrFt Land
                  </h6> */}
                </div>
                <div className="block-left">
                  <img src="./assets/img/financial_products.png" height="300" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

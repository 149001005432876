import React from 'react'

export default function NewFace() {
    return (
        <>
            <div className="section wf-section ptblr-50 bg-light">
                <div className="content">
                    <div className="w-layout-grid grid-left">
                        <div className="block-left">
                            <div className="w-layout-grid grid-stats">
                            </div>
                            <img src="./assets/img/im-self-purposes.png" alt="" />
                        </div>
                        <div id="w-node-_5713fedf-471c-a9c3-90d4-7d6642ea17a8-6ad29b8f" className="block">
                            <h2 data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ab" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="heading text-black">Community for Purposes</h2>
                            <p data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ad" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="paragraph-large">Imself is a community of like minded individuals that seek to help each other in growing further as an organization. The community provides resources for personal as well organizational needs. </p>
                            {/* <div data-w-id="2d6db815-ef82-a123-9a77-26cdadb39017" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="w-layout-grid grid-button"><a href='/governance' className="button w-button text-white">See More</a>
                            </div> */}
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

import React from "react";

export default function ValidatosDetails() {
  return (
    <>
      <div className="section wf-section bg-light pb-0">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <div className="w-layout-grid grid-page-a">
            <div
              data-w-id="9040b537-44e1-e314-8717-bd0bd8cbe0a4"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="stats lighter"
            >
              <h4 className="heading-stats text-black">100</h4>
              <div className="text-stats text-black">Current Validators</div>
            </div>
            <div
              data-w-id="f0aa4076-555c-586f-088d-17e0523b26a1"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="stats lighter"
            >
              <h4 className="heading-stats text-black">∼23%</h4>
              <div className="text-stats text-black">Total CTEX Staked</div>
            </div>
          </div>
          <div className="page-content pb-0">
            <h3
              data-w-id="88efa683-a3ad-b788-d8e0-a44585c25590"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="text-black"
            >
              Validator empowers the decentralization
            </h3>
            <p
              data-w-id="0dafb890-b226-74d6-9466-46f5bec96839"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
            >
              Every blockchain network needs validators to verify transactions
              and secure the network. Validators are incentivised with CTEX
              tokens from transaction gas and benefited with staking rewards.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";

export default function Faq2() {
  const [showtab, setShowTab] = useState([false,  false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]);
const togglefaq =  (index,value) => {
    let showtabcpy = [...showtab];
    showtabcpy[index] = value;
    setShowTab(showtabcpy)

}
  return (
      <div className="block-heading bg-light text-black">
          <div className="container">
        <h1 className="text-black">F.A.Q</h1>
        <div className="process__accordions">
          <ul className="accordions__list">
            <li className={showtab[0] ? "accordions__item open" : "accordions__item"}>
              <button className="accordions__control" aria-expanded="false">
                {/* <span className="accordions__number">01</span> */}
                <span className="accordions__title">1. What is the CTEX Chain?
</span>
                <span className="accordions__icon" onClick={()=>togglefaq(0,!showtab[0])}></span>
              </button>
              <div
                className="accordions__content text"
                aria-hidden="true"
                style={showtab[0] ? { maxHeight: "46px" } : {  }}
              >
                <p>
                CTEX Chain is a Layer 7 Blockchain Platform built on the Ethereum Virtual Machine (EVM), aiming to revolutionize the cryptocurrency landscape by offering solutions for DeFi, GameFi, NFTs, and more.


                </p>
              </div>
            </li>
            <li className={showtab[1] ? "accordions__item open" : "accordions__item"}>
              <button className="accordions__control" aria-expanded="false">
                <span className="accordions__title">2. How does CTEX Chain ensure security?
</span>
                <span className="accordions__icon" onClick={()=>togglefaq(1,!showtab[1])}></span>
              </button>
              <div
                className="accordions__content text"
                aria-hidden="true"
                style={showtab[1] ? { maxHeight: "46px" } : {  }}
              >
                <p>
                CTEX Chain utilizes a Delegated Proof of Stake (DPoS) consensus mechanism, where validators are elected to validate transactions and secure the network. Additionally, robust security measures and auditing protocols are implemented to safeguard the platform against potential threats.


                </p>
              </div>
            </li>
            <li className={showtab[2] ? "accordions__item open" : "accordions__item"}>
              <button className="accordions__control" aria-expanded="false">
                <span className="accordions__title">3. What are the benefits of staking CTEX tokens?
</span>
                <span className="accordions__icon" onClick={()=>togglefaq(2,!showtab[2])}></span>
              </button>
              <div
                className="accordions__content text"
                aria-hidden="true"
                style={showtab[2] ? { maxHeight: "46px" } : {  }}
              >
                <p>
                By staking CTEX tokens, users can participate in the consensus mechanism, earn rewards for validating transactions, and contribute to the security and decentralization of the network. Staking also allows users to actively engage in the governance of the CTEX Chain ecosystem.


                </p>
              </div>
            </li>
            <li className={showtab[3] ? "accordions__item open" : "accordions__item"}>
              <button className="accordions__control" aria-expanded="false">
                <span className="accordions__title">4. How can I become a validator on CTEX Chain?
</span>
                <span className="accordions__icon" onClick={()=>togglefaq(3,!showtab[3])}></span>
              </button>
              <div
                className="accordions__content text"
                aria-hidden="true"
                style={showtab[3] ? { maxHeight: "46px" } : {  }}
              >
                <p>
                To become a validator, you need to stake a minimum of 100,000 CTEX tokens and meet the necessary technical requirements. Validators play a crucial role in proposing and validating blocks, and they are rewarded for their contributions to the network.


                </p>
              </div>
            </li>
            <li className={showtab[4] ? "accordions__item open" : "accordions__item"}>
              <button className="accordions__control" aria-expanded="false">
                <span className="accordions__title">5. Can I delegate my CTEX tokens to a validator?
</span>
                <span className="accordions__icon" onClick={()=>togglefaq(4,!showtab[4])}></span>
              </button>
              <div
                className="accordions__content text"
                aria-hidden="true"
                style={showtab[4] ? { maxHeight: "46px" } : {  }}
              >
                <p>
                Yes, users have the option to delegate their CTEX tokens to validators, allowing them to participate in the staking program without running a validator node themselves. Delegators receive a portion of the rewards based on their delegated stake.


                </p>
              </div>
            </li>
            <li className={showtab[5] ? "accordions__item open" : "accordions__item"}>
              <button className="accordions__control" aria-expanded="false">
                <span className="accordions__title">6. What is the lock-up period for staked tokens?
</span>
                <span className="accordions__icon" onClick={()=>togglefaq(5,!showtab[5])}></span>
              </button>
              <div
                className="accordions__content text"
                aria-hidden="true"
                style={showtab[5] ? { maxHeight: "46px" } : {  }}
              >
                <p>
                Staked tokens are subject to a lock-up period, during which they cannot be withdrawn. The duration of the lock-up period may vary, and users may incur a penalty for unstaking tokens prematurely.


                </p>
              </div>
            </li>
            <li className={showtab[6] ? "accordions__item open" : "accordions__item"}>
              <button className="accordions__control" aria-expanded="false">
                <span className="accordions__title">7. How are rewards distributed to validators and delegators?
</span>
                <span className="accordions__icon" onClick={()=>togglefaq(6,!showtab[6])}></span>
              </button>
              <div
                className="accordions__content text"
                aria-hidden="true"
                style={showtab[6] ? { maxHeight: "46px" } : {  }}
              >
                <p>
                Rewards are distributed to validators and delegators based on their contribution to the network. Validators receive a share of transaction fees and block rewards, while delegators receive a portion of the rewards based on their delegated stake.


                </p>
              </div>
            </li>
            <li className={showtab[7] ? "accordions__item open" : "accordions__item"}>
              <button className="accordions__control" aria-expanded="false">
                <span className="accordions__title">8. What is the total token supply of CTEX Chain?</span>
                <span className="accordions__icon" onClick={()=>togglefaq(7,!showtab[7])}></span>
              </button>
              <div
                className="accordions__content text"
                aria-hidden="true"
                style={showtab[7] ? { maxHeight: "46px" } : {  }}
              >
                <p>
                The total token supply of CTEX Chain is 1 billion tokens, with 500 million tokens released through the mining program. These tokens are distributed to participants who contribute to the network through staking and validation.


                </p>
              </div>
            </li>
            <li className={showtab[8] ? "accordions__item open" : "accordions__item"}>
              <button className="accordions__control" aria-expanded="false">
                <span className="accordions__title">9. How can I learn more about CTEX Chain and stay updated with the latest developments?
</span>
                <span className="accordions__icon" onClick={()=>togglefaq(8,!showtab[8])}></span>
              </button>
              <div
                className="accordions__content text"
                aria-hidden="true"
                style={showtab[8] ? { maxHeight: "46px" } : {  }}
              >
                <p>
                To learn more about CTEX Chain, you can visit our official website, join our community on social media platforms, or subscribe to our newsletter. Stay informed about the latest news, updates, and announcements to be part of the CTEX Chain community!


                </p>
              </div>
            </li>
            <li className={showtab[9] ? "accordions__item open" : "accordions__item"}>
              <button className="accordions__control" aria-expanded="false">
                <span className="accordions__title">10. What distinguishes CTEX Chain from other blockchain platforms?</span>
                <span className="accordions__icon" onClick={()=>togglefaq(9,!showtab[9])}></span>
              </button>
              <div
                className="accordions__content text"
                aria-hidden="true"
                style={showtab[9] ? { maxHeight: "46px" } : {  }}
              >
                <p>
                CTEX Chain stands out for its Layer 7 architecture, which prioritizes speed, security, and scalability, making it ideal for DeFi, GameFi, and NFT applications.


                </p>
              </div>
            </li>
            <li className={showtab[10] ? "accordions__item open" : "accordions__item"}>
              <button className="accordions__control" aria-expanded="false">
                <span className="accordions__title">11. How does CTEX Chain address the issue of scalability in blockchain networks?</span>
                <span className="accordions__icon" onClick={()=>togglefaq(10,!showtab[10])}></span>
              </button>
              <div
                className="accordions__content text"
                aria-hidden="true"
                style={showtab[10] ? { maxHeight: "46px" } : {  }}
              >
                <p>
                CTEX Chain leverages advanced technologies, such as sharding and optimized consensus algorithms, to enhance scalability and accommodate a higher throughput of transactions.

</p>
              </div>
            </li>
            <li className={showtab[11] ? "accordions__item open" : "accordions__item"}>
              <button className="accordions__control" aria-expanded="false">
                <span className="accordions__title">12. Can I run a node on the CTEX Chain network?
</span>
                <span className="accordions__icon" onClick={()=>togglefaq(11,!showtab[11])}></span>
              </button>
              <div
                className="accordions__content text"
                aria-hidden="true"
                style={showtab[11] ? { maxHeight: "46px" } : {  }}
              >
                <p>
                Yes, CTEX Chain offers opportunities for individuals and organizations to run nodes and participate in the consensus mechanism, contributing to the security and decentralization of the network.


                </p>
              </div>
            </li>
            <li className={showtab[12] ? "accordions__item open" : "accordions__item"}>
              <button className="accordions__control" aria-expanded="false">
                <span className="accordions__title">13. What role do smart contracts play in the CTEX Chain ecosystem?
</span>
                <span className="accordions__icon" onClick={()=>togglefaq(12,!showtab[12])}></span>
              </button>
              <div
                className="accordions__content text"
                aria-hidden="true"
                style={showtab[12] ? { maxHeight: "46px" } : {  }}
              >
                <p>
                Smart contracts are integral to the functionality of CTEX Chain, enabling automated and trustless execution of transactions, agreements, and other functions within the ecosystem.


                </p>
              </div>
            </li>
            <li className={showtab[13] ? "accordions__item open" : "accordions__item"}>
              <button className="accordions__control" aria-expanded="false">
                <span className="accordions__title">14. How does CTEX Chain ensure interoperability with other blockchain networks?</span>
                <span className="accordions__icon" onClick={()=>togglefaq(13,!showtab[13])}></span>
              </button>
              <div
                className="accordions__content text"
                aria-hidden="true"
                style={showtab[13] ? { maxHeight: "46px" } : {  }}
              >
                <p>
                CTEX Chain integrates cross-chain compatibility protocols, allowing seamless interaction and data transfer between different blockchain networks, enhancing interoperability and connectivity.


                </p>
              </div>
            </li>
            <li className={showtab[14] ? "accordions__item open" : "accordions__item"}>
              <button className="accordions__control" aria-expanded="false">
                <span className="accordions__title">15. How does CTEX Chain promote decentralization and community governance?</span>
                <span className="accordions__icon" onClick={()=>togglefaq(14,!showtab[14])}></span>
              </button>
              <div
                className="accordions__content text"
                aria-hidden="true"
                style={showtab[14] ? { maxHeight: "46px" } : {  }}
              >
                <p>
                CTEX Chain operates on a decentralized governance model, where stakeholders can participate in decision-making processes, propose changes, and vote on key network upgrades and initiatives.


                </p>
              </div>
            </li>
            <li className={showtab[15] ? "accordions__item open" : "accordions__item"}>
              <button className="accordions__control" aria-expanded="false">
                <span className="accordions__title">16. Can I use CTEX Chain for building centralized applications (dApps)?</span>
                <span className="accordions__icon" onClick={()=>togglefaq(15,!showtab[15])}></span>
              </button>
              <div
                className="accordions__content text"
                aria-hidden="true"
                style={showtab[15] ? { maxHeight: "46px" } : {  }}
              >
                <p>
                Yes, developers can leverage the Ethereum Virtual Machine (EVM) compatibility of CTEX Chain to build and deploy decentralized applications, benefiting from existing tools and resources in the Ethereum ecosystem.


                </p>
              </div>
            </li>
            <li className={showtab[16] ? "accordions__item open" : "accordions__item"}>
              <button className="accordions__control" aria-expanded="false">
                <span className="accordions__title">17. What is the process for withdrawing staked tokens from the CTEX Chain network?</span>
                <span className="accordions__icon" onClick={()=>togglefaq(16,!showtab[16])}></span>
              </button>
              <div
                className="accordions__content text"
                aria-hidden="true"
                style={showtab[16] ? { maxHeight: "46px" } : {  }}
              >
                <p>
                Users can withdraw their staked tokens from the CTEX Chain network by initiating an unstaking transaction and waiting for the lock-up period to expire. Upon completion of the lock-up period, users can claim their unstaked tokens and transfer them to their wallet.


                </p>
              </div>
            </li>
            <li className={showtab[17] ? "accordions__item open" : "accordions__item"}>
              <button className="accordions__control" aria-expanded="false">
                <span className="accordions__title">18. What is the process for listing new tokens on the CTEX Chain centralized exchange (CEX)?</span>
                <span className="accordions__icon" onClick={()=>togglefaq(17,!showtab[17])}></span>
              </button>
              <div
                className="accordions__content text"
                aria-hidden="true"
                style={showtab[17] ? { maxHeight: "46px" } : {  }}
              >
                <p>
                Projects interested in listing their tokens on the CTEX Chain centralized exchange (CEX) can submit listing applications through the official platform. The listing process typically involves rigorous due diligence, compliance checks, and community voting to ensure transparency, quality, and security standards are met.


                </p>
              </div>
            </li>
           
          </ul>
        </div>
      </div>
    </div>
  );
}

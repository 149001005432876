import React from "react";
import Footer from "../comman/Footer";
import Header from "../comman/Header";

export default function PoAS() {
  return (
    <>
      <Header />
      <div className="section-hero wf-section">
        <div className="content hero">
          <img src="../assets/img/about-bg.png" className="bg" alt="" />
          <div className="block-hero">
            <h1
              data-w-id="fec0fb7b-303f-09d0-3ce6-40f91d7252a5"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="heading text-left"
            >
              What is DPoS consensus Model?
            </h1>
          </div>
        </div>
      </div>

      <div className="section-hero wf-section pt-0 bg-light">
        <div className="content single-page pt-0 mt-0">
          <div
            data-w-id="b18b81cf-a2db-0c97-0718-4b6d84339d65"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              opacity: 1,
              transformStyle: "preserve-3d",
            }}
            className="rich-text-block w-richtext text-black"
          >
            <p>
              DPoS, or Delegated Proof of Stake, is a consensus mechanism used
              in blockchain networks to achieve agreement on the state of the
              ledger. It is a variation of the Proof of Stake (PoS) consensus
              algorithm, where token holders stake their cryptocurrency to
              validate transactions and secure the network. However, in DPoS,
              token holders elect a small number of delegates or validators to
              perform these tasks on their behalf.
            </p>

            <h4 className="text-black">Here's how DPoS works:</h4>
            <p>
              Token holders vote for delegates: In a DPoS system, token holders
              have the power to vote for a limited number of delegates who will
              be responsible for validating transactions and adding new blocks
              to the blockchain. Typically, token holders can cast their votes
              in proportion to the number of tokens they hold.
            </p>

            <h5 className="text-black">Delegates produce blocks:</h5>
            <p>
              The delegates who receive the most votes become active block
              producers. These delegates are responsible for creating new blocks
              and validating transactions. They are incentivized to perform
              their duties honestly and efficiently, as they receive rewards in
              the form of transaction fees and block rewards.
            </p>

            <h5 className="text-black">Block production and validation:</h5>
            <p>
              Delegates take turns producing blocks in a predetermined order.
              Each block contains a set of transactions that have been validated
              by the delegate. Once produced, the block is broadcasted to the
              network for verification.
            </p>

            <h5 className="text-black">Network consensus:</h5>
            <p>
              The other nodes on the network verify the validity of the block
              and the transactions it contains. If a supermajority of nodes
              agree that the block is valid, it is added to the blockchain. If a
              block is deemed invalid, it is rejected, and the consensus process
              continues with the next block producer.
            </p>

            <h5 className="text-black">Rotation of delegates:</h5>
            <p>
              In DPoS, the list of active delegates is subject to periodic
              rotation based on the results of token holder voting. Delegates
              may lose their position if they fail to fulfill their
              responsibilities or if token holders choose to vote for different
              delegates.
            </p>

            <p>
              DPoS is favored for its scalability and energy efficiency compared
              to other consensus mechanisms like Proof of Work (PoW). However,
              it also introduces some centralization risks, as a small number of
              delegates have significant influence over the network. Therefore,
              proper governance mechanisms and transparency are essential to
              ensure the integrity and decentralization of DPoS-based
              blockchains.
            </p>
          </div>
        </div>
        <div
          data-w-id="c8bf05fb-9eee-0307-c6c4-6adf4ca0a1dc"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="glow-top"
        />
      </div>
      <Footer />
    </>
  );
}

import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'

export default function Register() {
    return (
        <>
            <Header />
            <div className="section banner wf-section" style={{ justifyItems: "center", paddingTop: "200px" }}>
                <div className="content">
                    <div data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb69" className="group-banner">
                        <div className="block-banner">
                            <h3 data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb6b" className="heading-banner">Register
                            </h3>
                            <div data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb6d" className="form-block-banner w-form">
                                <div method="get" className="form-banner text-center">
                                    <input type="email" className="text-field w-input" maxLength={256} name="Email" data-name="Email" placeholder="Enter Email" id="Email" required />
                                    <a type="submit" className="button w-button btn-black">Register</a>
                                    <p className='mt-10 text-black'>You've <a href="/login"> Log In</a> </p>
                                </div>
                            </div>
                        </div>
                        <div className="glow-banner" />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

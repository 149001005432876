import React from "react";

export default function HeroSetupNode() {
  return (
    <>
      <div className="section-hero wf-section">
        <div className="content hero">
          <img src="./assets/img/images.png" className="bg" alt="" />
          <div className="block-hero">
            <h1
              data-w-id="fec0fb7b-303f-09d0-3ce6-40f91d7252a5"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="heading-hero"
            >
              Secure the ledger.<br></br> Earn CTEX.
            </h1>
            <p
              data-w-id="dcd739db-6d1f-6a52-859b-4a3fd0e20c84"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="paragraph-large text-white"
            >
              CTEX is a Decentralized Blockchain Built to Enable Scalable,
              user-Friendly dApps for the Global.
            </p>
          </div>
          {/* <div data-w-id="297673ad-47a4-b4b1-8938-0ca16b3d6b41" className="figure-a-hero">
                        <video autoPlay loop id="myVideo"><source src="Scene_01.mp4" type="video/mp4" /></video>
                        <div className="figure-block-a-hero">
                            <div style={{ width: '0%' }} className="figure-a1" />
                            <div style={{ width: '0%' }} className="figure-a2" />
                            <div style={{ width: '0%' }} className="figure-a3" />
                        </div>
                    </div> */}
        </div>
        <div
          data-w-id="58e11b86-9d96-c9a8-f52f-b1795c37fa57"
          style={{
            WebkitTransform:
              "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            MozTransform:
              "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            msTransform:
              "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            transform:
              "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            opacity: 1,
          }}
          className="glow-top"
        />
      </div>
    </>
  );
}

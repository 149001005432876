import React from 'react'

export default function LifeStyle() {
    return (
        <>
            <div className="section wf-section bg-light bg-3" >
                <div className="content">
                    {/* <img src="./assets/img/iamself/bg1.png" alt="" /> */}
                    <div className="w-layout-grid grid-left">
                        <div className="block-left">
                            <img src="./assets/img/values.png" alt="" />
                        </div>
                        <div id="w-node-_5713fedf-471c-a9c3-90d4-7d6642ea17a8-6ad29b8f" className="block">
                            <h2 data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ab" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="heading text-black">ImSelf Values</h2>
                            <p data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ad" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="paragraph-large">With aiming to drive major changes into the lifestyle for Indian society, Imself focuses on improving communities with the help of individual group leaders who voluntarily lead for the change. The core values that Imself provide is individual support, growth, and freedom.</p>
                            {/* <div data-w-id="2d6db815-ef82-a123-9a77-26cdadb39017" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="w-layout-grid grid-button"><a href='/governance' className="button w-button text-white">See More</a>
                            </div> */}
                        </div>
                    </div>

                </div>
            </div>

            <div className="section wf-section bg-light bg-3-1 pt-30">
                <div data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc" className="content">
                    <div className="w-layout-grid grid-page-b">
                        <div id="w-node-_03dd9d41-6f8f-6dae-ad77-4ec7b94fceda-e76b7e62" data-w-id="03dd9d41-6f8f-6dae-ad77-4ec7b94fceda" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="tasks min-height">
                            <div className="tasks-block"><img src="./assets/img/value.svg" loading="lazy" alt="" className="icon-tasks" />
                                <h5 className="heading-tasks text-black">Social</h5>
                            </div>
                            <p className='text-black text-justify'>Improving the socio-economic structure of society and enabling social work.</p>
                        </div>
                        <div id="w-node-_03dd9d41-6f8f-6dae-ad77-4ec7b94fceda-e76b7e62" data-w-id="03dd9d41-6f8f-6dae-ad77-4ec7b94fceda" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="tasks min-height">
                            <div className="tasks-block"><img src="./assets/img/value.svg" loading="lazy" alt="" className="icon-tasks" />
                                <h5 className="heading-tasks text-black">Educational</h5>
                            </div>
                            <p className='text-black text-justify'>The community seeks after supporting non-profit educational organizations.</p>
                        </div>
                        <div id="w-node-_03dd9d41-6f8f-6dae-ad77-4ec7b94fceda-e76b7e62" data-w-id="03dd9d41-6f8f-6dae-ad77-4ec7b94fceda" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="tasks min-height">
                            <div className="tasks-block"><img src="./assets/img/value.svg" loading="lazy" alt="" className="icon-tasks" />
                                <h5 className="heading-tasks text-black">Finance</h5>
                            </div>
                            <p className='text-black text-justify'>Providing with financial support for businesses, institutions or individuals.</p>
                        </div>
                        <div id="w-node-_03dd9d41-6f8f-6dae-ad77-4ec7b94fceda-e76b7e62" data-w-id="03dd9d41-6f8f-6dae-ad77-4ec7b94fceda" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="tasks min-height">
                            <div className="tasks-block"><img src="./assets/img/value.svg" loading="lazy" alt="" className="icon-tasks" />
                                <h5 className="heading-tasks text-black">Health</h5>
                            </div>
                            <p className='text-black text-justify'>Supporting health centers and medical institution in enhancing their services.</p>
                        </div>
                        <div id="w-node-_03dd9d41-6f8f-6dae-ad77-4ec7b94fceda-e76b7e62" data-w-id="03dd9d41-6f8f-6dae-ad77-4ec7b94fceda" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="tasks min-height">
                            <div className="tasks-block"><img src="./assets/img/value.svg" loading="lazy" alt="" className="icon-tasks" />
                                <h5 className="heading-tasks text-black">Nature</h5>
                            </div>
                            <p className='text-black text-justify'>Working for climate changes causes and environmental activities.</p>
                        </div>
                        <div id="w-node-_03dd9d41-6f8f-6dae-ad77-4ec7b94fceda-e76b7e62" data-w-id="03dd9d41-6f8f-6dae-ad77-4ec7b94fceda" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="tasks min-height">
                            <div className="tasks-block"><img src="./assets/img/value.svg" loading="lazy" alt="" className="icon-tasks" />
                                <h5 className="heading-tasks text-black">Political</h5>
                            </div>
                            <p className='text-black text-justify'>Providing political support to individuals and political parties.</p>
                        </div>
                    </div>
                    <div className="page-content">
                        <h3 data-w-id="88efa683-a3ad-b788-d8e0-a44585c25590" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="text-black">
                            The for-purpose organization</h3>
                        <p data-w-id="0dafb890-b226-74d6-9466-46f5bec96839" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }}>
                            ImSelf is a for-purpose organization that works with individuals who are ambitious, aspiring and passionate for working for social changes and societal development.
                            <br /><br />All the community members collaborate and work closely with the social workers, teachers, agencies and institutions. The community encourages each other in overcoming obstacles and unlock greater benefits.
                        </p>
                        <div data-w-id="77b60b92-872c-7997-8a32-0c57412cdee7" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="w-layout-grid grid-button"><a href="#" target="_blank" className="button w-button text-white">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="section wf-section ptblr-50">
                <div className="content">
                    <div id="tab-2" className="w-layout-grid grid-clients ptblr-50">
                        <div data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="client">
                            <img src="./assets/img/partners/spartfan.png" loading="lazy" alt="" className="logo-client" />
                            <div className="bg-client" style={{ opacity: '0.5' }} />
                        </div>
                        <div data-w-id="f567adfd-1fc5-47fc-fe1d-b3cb4ed22463" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="client">
                            <img src="./assets/img/partners/factdekho.png" loading="lazy" alt="" className="logo-client" />
                            <div className="bg-client" style={{ opacity: '0.5' }} />
                        </div>
                        <div data-w-id="15951e27-b4c4-fda3-2e35-207951f74bc2" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="client">
                            <img src="./assets/img/partners/goomo-joomo.png" loading="lazy" alt="" className="logo-client" />
                            <div className="bg-client" style={{ opacity: '0.5' }} />
                        </div>
                        <div data-w-id="d837c98e-8ed0-5a81-0b73-79c8cfad71ca" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="client">
                            <img src="./assets/img/partners/juntinfo.png" loading="lazy" alt="" className="logo-client" />
                            <div className="bg-client" style={{ opacity: '0.5' }} />
                        </div>
                        <div data-w-id="844d1e39-a177-95a8-d613-60f08197b22e" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="client">
                            <img src="./assets/img/partners/justbit.png" loading="lazy" alt="" className="logo-client" />
                            <div className="bg-client" style={{ opacity: '0.5' }} />
                        </div>
                        <div data-w-id="c3a5c7cd-0014-86d5-946e-cae0abff07cb" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="client">
                            <img src="./assets/img/partners/justreal.png" loading="lazy" alt="" className="logo-client" />
                            <div className="bg-client" style={{ opacity: '0.5' }} />
                        </div>
                        <div data-w-id="3897b77d-7c6d-d02d-a091-750ba0b115e0" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="client">
                            <img src="./assets/img/partners/mobistar.png" loading="lazy" alt="" className="logo-client" />
                            <div className="bg-client" style={{ opacity: '0.5' }} />
                        </div>
                        <div data-w-id="02316edd-cdb3-0150-a792-54531d1e0283" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="client">
                            <img src="./assets/img/partners/selfmart.png" loading="lazy" alt="" className="logo-client" />
                            <div className="bg-client" style={{ opacity: '0.5' }} />
                        </div>
                        <div data-w-id="303ba026-3055-cdf6-a5db-e3bed7bdaecc" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="client">
                            <img src="./assets/img/partners/trust-wallet.png" loading="lazy" alt="" className="logo-client" />
                            <div className="bg-client" style={{ opacity: '0.5' }} />
                        </div>
                        <div data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="client">
                            <img src="./assets/img/partners/coin-market.png" loading="lazy" alt="" className="logo-client" />
                            <div className="bg-client" style={{ opacity: '0.5' }} />
                        </div>
                        <div data-w-id="f567adfd-1fc5-47fc-fe1d-b3cb4ed22463" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="client">
                            <img src="./assets/img/partners/coin-gecko.png" loading="lazy" alt="" className="logo-client" />
                            <div className="bg-client" style={{ opacity: '0.5' }} />
                        </div>
                        <div data-w-id="15951e27-b4c4-fda3-2e35-207951f74bc2" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="client">
                            <img src="./assets/img/partners/coin-perpika.png" loading="lazy" alt="" className="logo-client" />
                            <div className="bg-client" style={{ opacity: '0.5' }} />
                        </div>
                        <div data-w-id="15951e27-b4c4-fda3-2e35-207951f74bc2" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="client">
                            <img src="./assets/img/partners/cu-news.png" loading="lazy" alt="" className="logo-client" />
                            <div className="bg-client" style={{ opacity: '0.5' }} />
                        </div>
                    </div>
                </div>
            </div> */}

        </>
    )
}

import React, { useContext, useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { Mycontext } from '../../MyContext';
import copy from "copy-to-clipboard";
import logo from '../../load.gif'




export default function Header() {

    const [buttonText, setButtonText] = useState('');
    const [button, setButton] = useState(false);
    


    const handleClick = () => {
        setButtonText(true);
        console.log('hello')
        setTimeout(() => {
            setButtonText(false);
        }, 3000);
    }
    const copyaddress = (address) => {
        copy(address);
    }
    // let [loading, setLoading] = useState(true);
    // let [color, setColor] = useState("#ffffff");

    const { account, connectMetamask, formatAddress, disconnect } = useContext(Mycontext);
    const history = useNavigate();


    // const [isHide, setIsHide] = useState(true);
    const [isClick, setIsClick] = useState(false);
    // setTimeout(() => setIsHide(false), 5000);

    const [toggleState, setToggleState] = useState(true);
    return (
        <>
            <div data-collapse="medium" data-animation="default" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav text-right">
                <div className="w-layout-grid grid-navbar menu">
                    <div data-w-id="e8cea9b6-4627-f1cd-fec0-16ebfaf55914" className="nav"><a href="/" aria-current="page" className="brand w-nav-brand w--current"><img src="assets/logo-dark.png" loading="lazy" alt="" className="logo" /></a></div>

                    
                    <div className='nav-dflex'>
                        <div className="nav right">
                            {account ? (
                                <>

                                    <div class="dropdown">
                                        <button className="nav-button-1 w-button  " style={{ marginBottom: "10px" }} >{formatAddress(account)}</button>
                                        <div class="dropdown-content" style={{width: 'auto'}}>
                                        <img
                                            className="pe-1 "
                                            src="assets/logo-dark.png"
                                            width="40"
                                        />

                                            <lable className="text-black" >Account 1234</lable>
                                           
                                            <div class="dropdown-item ml-0 px-2 mb-3 mt-3 my-3 mx-3  address-area  "  style={{ color: "#000", width: "200px", backgroundColor: "#96969642", borderRadius: "10px" }} onClick={(e) => { copyaddress(account); handleClick(); }}>{formatAddress(account)}
                                            {
                                                buttonText ? 
                                                <>
                                                    <i class="fa fa-check-circle" aria-hidden="true" />

                                                </>
                                                :
                                                <>
                                                         <i className="fa fa-copy text-black" />

                                                </>
                                            }
                                            </div>
                                            <hr/>
                                            <Link class="dropdown-item ml-0 py-2s" to="/myaccount" style={{ color: "#000", }}><i class="fa fa-th-large" aria-hidden="true" />&nbsp;Dashboard</Link>
                                            <br />
                                            <Link class="dropdown-item ml-0 py-2" onClick={() => disconnect()} to="/" style={{ color: "#000" }}><i class="fa fa-calculator" aria-hidden="true" />&nbsp;Reward Calculate</Link>
                                            <br />
                                            <Link class="dropdown-item ml-0 py-2" onClick={() => disconnect()} to="/" style={{ color: "#000" }}> <i class="fa fa-sign-out" aria-hidden="true" />&nbsp;Logout</Link>
                                            <br />
                                            <span className='text-golden'>{buttonText}</span>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <a className="nav-button-1 w-button" href="#open-modal2">Connect</a>

                                </>
                            )}

                            <div>
                                <div id="open-modal2" className="modal-window" >
                                    <div className='bg-19' >
                                        <a href="#" title="Close" className="modal-close"><i class="fa fa-times" aria-hidden="true"></i></a>
                                        <br />
                                        {isClick ?
                                            <>
                                                {!account ?
                                                    <>
                                                        <img src={logo} alt="" width={250} className='align-middle center position-relative align-self-center' />
                                                    </>
                                                    :
                                                    window.location.reload()
                                                }
                                            </>
                                            :
                                            <>
                                                <button className="nav-button-2 w-button text-black" onClick={() => { connectMetamask(); setIsClick(true); }}>
                                                    <img src="./assets/img/metamask.png" alt="" srcset="" width="40px" />
                                                    &nbsp;&nbsp;Metamask
                                                </button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               

            </div>
        </>
    )
}

import React from "react";

export default function WhatRupto() {
  return (
    <>
      <div
        className="section banner wf-section bg-light pb-120"
        style={{ justifyItems: "center" }}
      >
        <div className="content">
          <div
            data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb69"
            className="group-banner ptblr-30 "
          >
            <div className="content">
              <div
                className="w-layout-grid grid-right"
                style={{ textAlign: "start" }}
              >
                <div
                  id="w-node-_5713fedf-471c-a9c3-90d4-7d6642ea17a8-6ad29b8f"
                  className="block"
                >
                  <h4
                    data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ab"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: 1,
                    }}
                    className="heading text-black"
                  >
                    Get Started
                  </h4>
                  <p
                    data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ad"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: 1,
                    }}
                    className="paragraph-large"
                  >
                    Find out all the needed resources to build and deploy dApps
                    on CTEX.
                  </p>
                  <div
                    data-w-id="2d6db815-ef82-a123-9a77-26cdadb39017"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: 1,
                    }}
                    className="w-layout-grid grid-button"
                  >
                    <a
                      // href="/validators-presale"
                      href="https://ctex-network.gitbook.io/developer-documentation/ctex-consensus"
                      className="button w-button text-white"
                      target="blank"
                    >
                      Docs
                    </a>
                  </div>
                </div>
                <div className="block-left align-items-end">
                  <img
                    src="./assets/img/dev-rec.png"
                    width="500"
                    height="300"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Mycontext } from "../../MyContext";
export default function Transaction() {
  const {
    rate,
    totalaccount,
    totalTransactions,
  } = useContext(Mycontext);

  return (
    <>
      <div className="section wf-section bg-light ptblr-40">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <div className="block-heading">
            <h2
              data-w-id="edc71457-7d5b-7bea-ff46-ddb5540db920"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="heading text-black"
            >
              Mass Adoption, <br />
              Massive Distribution
            </h2>
            <p className="text-black">
              Unlocking high-speed, scalable dApps through CTEX's robust
              blockchain infrastructure
            </p>
          </div>

          <div className="w-layout-grid grid-page-b">
            <div
              data-w-id="f0aa4076-555c-586f-088d-17e0523b26a1"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="stats lighter"
            >
              <h4 className="heading-stats text-black">
                {totalTransactions}
              </h4>
              <div className="text-stats text-black">Transactions</div>
            </div>
            <div
              data-w-id="27764734-a540-afeb-0e84-c4274378e76a"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="stats lighter"
            >
              <h4 className="heading-stats text-black">{totalaccount}</h4>
              <div className="text-stats text-black">Accounts</div>
            </div>
            <div
              data-w-id="918d5c54-f1a0-3064-11f2-3a26b74b3ba9"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="stats lighter"
            >
              <h4 className="heading-stats text-black">${rate}</h4>
              <div className="text-stats text-black">Rate</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="section banner wf-section bg-light pb-120"
        style={{ justifyItems: "center" }}
      >
        <div className="content">
          <div
            data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb69"
            className="group-banner ptblr-30 "
          >
            <div className="content">
              <div
                className="w-layout-grid grid-right "
                style={{ textAlign: "start" }}
              >
                <div
                  id="w-node-_5713fedf-471c-a9c3-90d4-7d6642ea17a8-6ad29b8f"
                  className="block"
                >
                  <div
                    data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17a9"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: 1,
                    }}
                    className="subtitle"
                  >
                    CTEX Protocol
                  </div>
                  <h4
                    data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ab"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: 1,
                    }}
                    className="heading text-black"
                  >
                    The Technology Powering the CTEX Protocol
                  </h4>
                  <p
                    data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ad"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: 1,
                    }}
                    className="paragraph-large text-black"
                  >
                    Crypto Tex is an open-source blockchain scaling solution
                    that delivers a faster, cheaper and smarter experience for
                    blockchain’s next billion users.
                  </p>
                  {/* <div data-w-id="2d6db815-ef82-a123-9a77-26cdadb39017" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="w-layout-grid grid-button"><a href="/validators-presale" className="button w-button text-white">Setup a Node</a>
                                    </div> */}
                  <ul className="d-flx">
                    <li>
                      <a href="/token" className="icon-a text-golden">
                        Get CTEX <i class="fa fa-chevron-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/products" className="icon-a text-golden">
                        Use Case <i class="fa fa-chevron-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/bridge" className="icon-a text-golden">
                        Bridge <i class="fa fa-chevron-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="block-left align-items-end">
                  <img
                    src="./assets/coins.png"
                    width="500"
                    height="300"
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/* <div className="block-banner">
                            <h3 data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb6b" className="heading-banner">Stay informed and never
                                miss an
                                Core update!
                            </h3>
                            <div data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb6d" className="form-block-banner w-form">
                                <div method="get" className="form-banner text-center">
                                    <input type="email" className="text-field w-input" maxLength={256} name="Email" data-name="Email" placeholder="Enter Email" id="Email" required />
                                    <a type="submit" className="button w-button btn-black">Subscribe</a>
                                </div>
                            </div>
                        </div> */}
            {/* <div className="glow-banner" /> */}
          </div>
        </div>
      </div>
    </>
  );
}

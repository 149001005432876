import React from 'react'
import Footer from '../comman/Footer'
import HeroDashbord from './HeroDashbord'
import NodeList from './NodeList'
import Header from '../comman/Header'

export default function Dashboard() {
  document.title = 'Dashboard - Rupto';
  return (
    <>
    <Header/>
    <HeroDashbord/>
    <NodeList/>
    <Footer/>
    </>
  )
}

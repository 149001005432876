import React from "react";
import Footer from "../comman/Footer";
import Header from "../comman/Header";
import Protocoldetails from "./Protocoldetails";
import ProtocolHero from "./ProtocolHero";
import Statistics from "./Statistics";
import CtexNetwork from "./CtexNetwork";
import ForUsers from "./ForUsers";
import ForOperators from "./ForOperators";
import ForNetwork from "./ForNetwork";
import Ctextokens from "./Ctextokens";

export default function Protocol() {
  return (
    <>
      <Header />
      <ProtocolHero />
      <CtexNetwork />
      <ForUsers />
      <ForOperators />
      <ForNetwork />
      {/* <Protocoldetails/> */}
      <Ctextokens />
      {/* <Statistics /> */}
      <Footer />
    </>
  );
}

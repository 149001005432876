import React from "react";

export default function CtexForce() {
  return (
    <>
      <div className="section wf-section bg-light ">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <div className="w-layout-grid grid-page-b-1">
            <div
              data-w-id="f0aa4076-555c-586f-088d-17e0523b26a1"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
                maxWidth: "1200px",
                backgroundColor:"#f0b41a"
              }}
              className="stats-force "
            >
              <h4 className="heading-stats text-black text-center  ">
                Join the Ctex 
              </h4>
              {/* <img
                src="./assets/ambassdor.svg"
                alt=""
                className="align-self-end"
              /> */}
              <div className="text-black">
                If our vision resonates with you and you're eager to champion
                Fuse Network in its next growth phase, we'd be thrilled to have
                you on board.
              </div>
              <div
                data-w-id="77b60b92-872c-7997-8a32-0c57412cdee7"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="w-layout-grid grid-button"
              >
                <a
                  href="/validators-presale"
                  className="button w-button text-white"
                >
                  Join Ambassadors
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      s
    </>
  );
}

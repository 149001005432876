import React from 'react'

export default function WithdwarTable() {
    return (
        <>
            <div className="section wf-section bg-light">
                <div className="content">
                    <div className="container text-size">
                        <div className="main_title mb-4">Withdrawal History</div>
                        <div className="row border rounded-11 table-box overflow-auto" >
                            <div className="col-lg-6 align-self-center rounded" style={{}}>
                                <div>
                                    <i class="fa fa-search search-box" aria-hidden="true" />
                                    <input type="text" placeholder="Search" className="rounded-10 ps-2 w-80" />
                                </div>
                            </div>
                            <div className="col-lg-6  " style={{}}>
                                <div className="float-end">
                                    <label>Sort By</label>
                                    <select
                                        className=" rounded-10 ps-2"
                                        style={{}}
                                        aria-label="Default"
                                    >
                                        <option>Performance</option>
                                        <option>Commission</option>
                                        <option>Stake</option>
                                        <option>Random</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-12">
                                <table>
                                    <thead>
                                        <tr> <th>
                                            name
                                        </th>
                                            <th>
                                                Stake
                                            </th><th>
                                                Commission
                                            </th><th>
                                                Checkpoints SignOuts
                                            </th><th>
                                                Health Status
                                            </th>
                                            <th>
                                                Health Status
                                            </th></tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Stake</td>
                                            <td>6111 RWT</td>
                                            <td>5%</td>
                                            <td>100%</td>
                                            <td>Final Notice</td>
                                        </tr>
                                    </tbody>
                                </table>

                                {/* <div className="row border-top border-bottom bg-light-gray text-white py-2 px-2 overflow-auto">
                                    <div className="col-2">
                                        name
                                    </div>
                                    <div className="col-2">
                                        Stake
                                    </div>
                                    <div className="col-2">
                                        Commission
                                    </div>
                                    <div className="col-2">
                                        Checkpoints SignOuts
                                    </div>
                                    <div className="col-2">

                                        Health Status
                                    </div>
                                    <div className="col-2">

                                    </div>
                                </div> */}
                            </div>
                            {/* <div className="col-12">
                                <div className="row border-top border-bottom px-2  py-4 overflow-auto">
                                    <div className="col-2">
                                        <img
                                            className="pe-1 "
                                            src="assets/logo-dark.png"
                                            width="30"
                                        />
                                        Stake
                                    </div>
                                    <div className="col-2">
                                        6111 RWT
                                    </div>
                                    <div className="col-2">
                                        5%
                                    </div>
                                    <div className="col-2">
                                        100%
                                    </div>
                                    <div className="col-2">
                                        <i class="fa fa-circle text-gold pe-2" aria-hidden="true"></i>
                                        Final Notice
                                    </div>
                                    <div className="col-2">
                                        <buttun type="button" className="btn btn main_heading_button p-3">Delegate</buttun>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

import React from "react";

export default function Process() {
  return (
    <>
      <div className="bg-light">
        <div className="section-hero wf-section">
          <div className="content single-page">
            <div className="block-hero">
              <div
                data-w-id="78bf7935-39ac-c464-dac4-4f1b84bd7529 "
                id="it-works"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="subtitle"
              >
                Overview
              </div>
              <h1
                data-w-id="294bce85-806e-c17f-c9e9-048ba9d50788"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="heading-single-page text-black"
              >
                How it works?
              </h1>
              <p
                data-w-id="dcd739db-6d1f-6a52-859b-4a3fd0e20c84"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="paragraph-large text-black"
              >
                Governance enable true decentralization in managing the CTEX
                protocol.
              </p>
            </div>
          </div>
          <div
            data-w-id="58e11b86-9d96-c9a8-f52f-b1795c37fa57"
            style={{
              WebkitTransform:
                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              MozTransform:
                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              msTransform:
                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              transform:
                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              opacity: 1,
            }}
            className="glow-top"
          />
        </div>
        {/* <h2>A clean timeline design, with animated links</h2> */}
        <div className="timeline bg-light">
          <div className="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
            <div className="timeline__event__icon ">
              <i className="lni-cake" />
            </div>
            <div className="timeline__event__date"></div>
            <div className="timeline__event__content ">
              <div className="timeline__event__title">Proposal</div>
              <div className="timeline__event__description">
                <p className="text-black">
                  A new proposal is introduced to governance. To propose the
                  change, the proposer must stake predefined CTEX tokens as
                  deposit. The proposal must pass the community surveillance
                  audit in forum discussion before moving further for voting.
                </p>
              </div>
            </div>
          </div>
          <div className="timeline__event animated fadeInUp delay-2s timeline__event--type2">
            <div className="timeline__event__icon">
              <i className="lni-burger" />
            </div>
            <div className="timeline__event__date"></div>
            <div className="timeline__event__content">
              <div className="timeline__event__title">Voting</div>
              <div className="timeline__event__description">
                <p className="text-black">
                  CTEX community votes either Yes, No or Abstain. CTEX community
                  & CTEX token holders decide if the proposal needs to be
                  considered for upgradation using their voting power. It
                  requires more than 50% votes to ‘YES’ to terminate the change
                  successfully. The proposal is rejected if the total ‘NO’ votes
                  ratio is higher than ‘YES’ and ‘Abstain’ votes are excluded
                  from either decision.
                </p>
              </div>
            </div>
          </div>
          <div className="timeline__event animated fadeInUp delay-1s timeline__event--type3">
            <div className="timeline__event__icon">
              <i className="lni-slim" />
            </div>
            <div className="timeline__event__date"></div>
            <div className="timeline__event__content">
              <div className="timeline__event__title">Termination</div>
              <div className="timeline__event__description">
                <p className="text-black">
                  At the end of deadline, the snapshot records the decision. If
                  the proposal passes majority of ‘YES’ votes then protocol is
                  upgraded with proposed change and all network participants
                  shall follow the change. In case of rejection, the proposer
                  loses its deposit and it is added to the community pool.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="timeline__event animated fadeInUp timeline__event--type1">
                        <div className="timeline__event__icon">
                            <i className="lni-cake" />
                        </div>
                        <div className="timeline__event__date">
                            20-08-2019
                        </div>
                        <div className="timeline__event__content">
                            <div className="timeline__event__title">
                                Birthday
                            </div>
                            <div className="timeline__event__description">
                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum quidem dolorem in!</p>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </>
  );
}

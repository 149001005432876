import React from "react";

export default function GovernanceResoures() {
  return (
    <>
      <div className="section wf-section bg-light">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <div className="w-layout-grid grid-technology">
            <div
              data-w-id="893553c5-8fd4-cfbf-8679-88871d8ec8a6"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="technology-2"
            >
              <img
                src="./assets/img/icon/join-comunity.png"
                loading="lazy"
                alt=""
                className="icon-technology"
              />
              <a
                className="icon-a"
              >
                <h6 className="text-black">Join the Forum</h6>
                <p className="text-black">
                  Join forum to discuss CTEX improvement proposals.
                </p>
              </a>
            </div>
            <div
              data-w-id="919317fc-3fc6-5955-bd1f-d2b83420d18f"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="technology-2"
            >
              <img
                src="./assets/img/icon/submit-prpsl.png"
                loading="lazy"
                alt=""
                className="icon-technology"
              />
              <a
               
                className="icon-a"
              >
                <h6 className="text-black">Submit Proposal</h6>
                <p className="text-black">Propose changes to CTEX protocol.</p>
              </a>
            </div>
            <div
              data-w-id="138e3976-e8c8-15d4-ba81-7614035567d3"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="technology-2"
            >
              <img
                src="./assets/img/icon/vote-on-prpsl.png"
                loading="lazy"
                alt=""
                className="icon-technology"
              />
              <a
             
                className="icon-a"
              >
                <h6 className="text-black">Vote on Proposals</h6>
                <p className="text-black">
                  Use CTEX tokens & start voting on CTEX improvement proposals.
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";

export default function RutpoPlatform() {
  return (
    <>
      <div className="section wf-section ptblr-30 bg-light bg-13">
        <div className="content">
          <div className="w-layout-grid grid-left">
            <div className="block-left">
              <img src="./assets/img/why_ctex_image.png" alt="" />
            </div>
            <div
              id="w-node-_5713fedf-471c-a9c3-90d4-7d6642ea17a8-6ad29b8f"
              className="block"
            >
              <h2
                data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ab"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="heading text-black"
              >
                Why CTEX
              </h2>
              <p
                data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ad"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="paragraph-large text-justify"
              >
                Rupo introduces Proof-of-Asset Security (PoAS) mechanism for
                tokenizing real world assets while providing an actual value to
                the asset, CTEX protocol pushes blockchain and digital property
                adoption further.
              </p>
              {/* <div data-w-id="2d6db815-ef82-a123-9a77-26cdadb39017" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="w-layout-grid grid-button"><a href='https://rbcscan.com/' className="button w-button text-white">Explore</a>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

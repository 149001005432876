import React from "react";

export default function GetToken() {
  return (
    <>
      <div className="section wf-section">
        <div className="content "  id="get">
          <div className="block-heading">
            {/* <div
              data-w-id="6d9ad869-a5c2-fd2f-9bfc-e6fc73288d47"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="subtitle"
            >
              Backed by
            </div> */}
            <h2

              data-w-id="6d9ad869-a5c2-fd2f-9bfc-e6fc73288d49"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="heading"
             
            >
              Buy & Store CTEX
            </h2>
            <p
              data-w-id="7fc5df83-6010-b5dc-f288-b6f30f9f4eef"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
                fontSize: "17px",
              }}
              className="paragraph-large text-white"
            >
              The Ctex Coin is an Mainnet Coin that is available on all the
              major markets, both centralized and decentralized. Ctex can be
              bought directly with from our listed exchanges. Ctex Coin is
              available on most major centralized exchanges for users to further
              utilize the network.
            </p>
          </div>
          <div className="w-layout-grid grid-page-b">
          <a href="#" target="_blank" className="icon-a">
              <div
                data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="client"
              >
                <img
                  src="./assets/img/news_media/fameex (1).png"
                  loading="lazy"
                  alt=""
                  width="170"
                  height="150"
                  className=""
                />
                <div className="bg-client" style={{ opacity: "0.5" }} />
              </div>
            </a>  <a href="#" target="_blank" className="icon-a">
              <div
                data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="client"
              >
                <img
                src="./assets/img/news_media/biconomy.png"
                  loading="lazy"
                  alt=""
                  width="170"
                  height="150"
                  className=""
                />
                <div className="bg-client" style={{ opacity: "0.5" }} />
              </div>
            </a>
            <a href="#" target="_blank" className="icon-a">
              <div
                data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="client"
              >
                <img
                  src="./assets/img/buy_store/atomic_wallet.png"
                  loading="lazy"
                  alt=""
                  width="170"
                  height="150"
                  className=""
                />
                <div className="bg-client" style={{ opacity: "0.5" }} />
              </div>
            </a>
            <a href="#" className="icon-a">
              <div
                data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="client"
              >
                <img
                  src="./assets/img/buy_store/block_wallet.png"
                  loading="lazy"
                  alt=""
                  width="170"
                  height="150"
                  className=""
                />
                <div className="bg-client" style={{ opacity: "0.5" }} />
              </div>
            </a>
            <a href="#" className="icon-a">
              <div
                data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="client"
              >
                <img
                  src="./assets/img/buy_store/dapp.png"
                  loading="lazy"
                  alt=""
                  width="170"
                  height="150"
                  className=""
                />
                <div className="bg-client" style={{ opacity: "0.5" }} />
              </div>
            </a>
            <a href="#" className="icon-a">
              <div
                data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="client"
              >
                <img
                  src="./assets/img/buy_store/enkrypt.png"
                  loading="lazy"
                  alt=""
                  width="170"
                  height="150"
                  className=""
                />
                <div className="bg-client" style={{ opacity: "0.5" }} />
              </div>
            </a>
            <a href="#" className="icon-a">
              <div
                data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="client"
              >
                <img
                  src="./assets/img/buy_store/msw_wallet.png"
                  loading="lazy"
                  alt=""
                  width="170"
                  height="150"
                  className=""
                />
                <div className="bg-client" style={{ opacity: "0.5" }} />
              </div>
            </a>
            <a href="#" className="icon-a">
              <div
                data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="client"
              >
                <img
                  src="./assets/img/buy_store/phantom_wallet.png"
                  loading="lazy"
                  alt=""
                  width="170"
                  height="150"
                  className=""
                />
                <div className="bg-client" style={{ opacity: "0.5" }} />
              </div>
            </a>
            <a href="#" className="icon-a">
              <div
                data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="client"
              >
                <img
                  src="./assets/img/buy_store/rainbow.png"
                  loading="lazy"
                  alt=""
                  width="170"
                  height="150"
                  className=""
                />
                <div className="bg-client" style={{ opacity: "0.5" }} />
              </div>
            </a>
            <a href="#" className="icon-a">
              <div
                data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="client"
              >
                <img
                  src="./assets/img/buy_store/solflare.png"
                  loading="lazy"
                  alt=""
                  width="170"
                  height="150"
                  className=""
                />
                <div className="bg-client" style={{ opacity: "0.5" }} />
              </div>
            </a>
            <a href="#" className="icon-a">
              <div
                data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="client"
              >
                <img
                  src="./assets/img/buy_store/toke_pocket.png"
                  loading="lazy"
                  alt=""
                  width="170"
                  height="150"
                  className=""
                />
                <div className="bg-client" style={{ opacity: "0.5" }} />
              </div>
            </a>
            <a href="#" className="icon-a">
              <div
                data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="client"
              >
                <img
                  src="./assets/img/buy_store/trust_wallet.png"
                  loading="lazy"
                  alt=""
                  width="170"
                  height="150"
                  className=""
                />
                <div className="bg-client" style={{ opacity: "0.5" }} />
              </div>
            </a>
            
          </div>
        </div>
      </div>
    </>
  );
}

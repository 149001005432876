import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import DeveloperHero from './DeveloperHero'
import Resource from './Resource'
import WhatRupto from './WhatRupto'

export default function Developer() {
    document.title = `Developer Resources - ${process.env.REACT_APP_TITLE}`;
    return (
        <>
            <Header />
            <DeveloperHero/>
            <WhatRupto/>
            <Resource />
            <Footer />
        </>
    )
}

import React from "react";
import Footer from "../comman/Footer";
import Header from "../comman/Header";
import CareerHero from "./CareerHero";

export default function Jobdesc() {
  document.title = `Job Decription - ${process.env.REACT_APP_TITLE}`;
  return (
    <>
      <Header />
      <CareerHero />
      <div className="section wf-section bg-light">
        <div className="content">
          <div
            data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc498b"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              opacity: 1,
              transformStyle: "preserve-3d",
            }}
            className="subtitle text-center"
          >
            OUR VALUES
          </div>
          <div className="w-layout-grid grid-right-2 align-items-start">
            <div
              id="w-node-a50c89a1-4e8e-1cda-83cc-d3e74bcc498a-e3eb6dcf"
              className="block"
            >
              <div
                data-w-id="b18b81cf-a2db-0c97-0718-4b6d84339d65"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="rich-text-block w-richtext"
              >
                <h4 className="text-black">Aenean vulputate eleifend</h4>
                <p className="text-black">
                  Suspendisse sit sodales purus, fringilla metus. Hac ornare
                  aenean risus id nunc lobortis sagittis. Magna amet mauris dis
                  molestie amet. Nisl pulvinar egestas erat erat adipiscing sed.
                </p>
                <p className="text-black">
                  In tristique iaculis consectetur fermentum, in commodo
                  consequat imperdiet vitae. Vitae id at nisl phasellus. Tellus
                  vestibulum, turpis viverra in lacinia non in cursus aenean.
                  Suscipit lacus enim tortor, in gravida malesuada semper. Quam
                  odio lorem arcu, egestas.
                </p>
                <h4 className="text-black">Nulla consequat massa enim</h4>
                <p className="text-black">
                  Vivamus volutpat, mattis tristique commodo vestibulum, blandit
                  quisque quis. Turpis integer blandit at proin nulla fringilla
                  ac.
                </p>
                <blockquote className="text-black">
                  “Etiam lectus bibendum nam tortor, est posuere. Mauris tempus
                  eget tempor nunc ultricies. Sem morbi volutpat.”
                </blockquote>
                <p className="text-black">
                  Maecenas nibh sem dui, tincidunt. Leo pulvinar consectetur
                  amet tellus. Magna libero mattis tortor lorem ornare ac
                  integer. Ac eget donec neque nunc lectus gravida. Risus,
                  faucibus lectus dapibus magna sed vulputate integer. Placerat
                  tempus vulputate at phasellus tellus tristique et suscipit.
                  Arcu, adipiscing platea id et. Pretium nunc.
                </p>
                <h4 className="text-black">Aliquam lorem ante, dapibus</h4>
                <p className="text-black">
                  Vivamus volutpat, mattis tristique commodo vestibulum, blandit
                  quisque quis. Turpis integer blandit at proin nulla fringilla
                  ac.
                </p>
                <ul className="text-black" role="list">
                  <li>
                    In enim justo, rhoncus ut, imperdiet a, venenatis vitae.
                  </li>
                  <li>Nullam dictum felis eu pede mollis pretium.</li>
                  <li>Integer tincidunt. Cras dapibus.</li>
                  <li>Vivamus elementum semper nisi eleifend tellus</li>
                </ul>
                <p className="text-black">
                  Risus laoreet convallis faucibus consectetur nibh sollicitudin
                  mattis. Ut netus sagittis pharetra, amet sagittis non amet,
                  sem. Vitae enim fermentum vitae ultrices pellentesque eget
                  dui.
                </p>
              </div>
            </div>
            <div className="block-right ">
              <div
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc4985"
                className="figure-d-2 link-underlined "
              >
                {/* <img src="./assets/1.png" className='r-10' alt="" /> */}
                <div
                  data-w-id="77b60b92-872c-7997-8a32-0c57412cdee7"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 1,
                  }}
                  className="w-layout-grid grid-button "
                >
                  <a
                    href="/validators-presale"
                    className="button w-button text-white r-10"
                  >
                    Apply For This Job
                  </a>
                </div>
              </div>
              <div className="ml--95">
                <ul className="list-style-none d-flx-1 float-start ">
                  <li>
                    <p>
                      Location <br /> Gzira, Malta
                    </p>
                  </li>
                  <li>
                    <p>
                      Department <br /> GBusiness Intelligence
                    </p>
                  </li>
                  <li>
                    <p>
                      Employment Type
                      <br /> Full Time
                    </p>
                  </li>
                  <li>
                    <p>
                      Minimum Experience
                      <br /> Experienced
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="section-hero wf-section">
        <div className="content sgrid-right-2">
          <div data-w-id="b18b81cf-a2db-0c97-0718-4b6d84339d65" style={{
            transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'
            , opacity: 1, transformStyle: 'preserve-3d'
          }} className="rich-text-block w-richtext">
            <h4>Aenean vulputate eleifend</h4>
            <p>Suspendisse sit sodales purus, fringilla metus. Hac ornare aenean risus id nunc lobortis sagittis. Magna
              amet mauris dis molestie amet. Nisl pulvinar egestas erat erat adipiscing sed.</p>
            <p>In tristique iaculis consectetur fermentum, in commodo consequat imperdiet vitae. Vitae id at nisl
              phasellus. Tellus vestibulum, turpis viverra in lacinia non in cursus aenean. Suscipit lacus enim
              tortor, in gravida malesuada semper. Quam odio lorem arcu, egestas.</p>
            <h4>Nulla consequat massa enim</h4>
            <p>Vivamus volutpat, mattis tristique commodo vestibulum, blandit quisque quis. Turpis integer blandit at
              proin nulla fringilla ac.</p>
            <blockquote>“Etiam lectus bibendum nam tortor, est posuere. Mauris tempus eget tempor nunc ultricies. Sem
              morbi volutpat.”</blockquote>
            <p>Maecenas nibh sem dui, tincidunt. Leo pulvinar consectetur amet tellus. Magna libero mattis tortor lorem
              ornare ac integer. Ac eget donec neque nunc lectus gravida. Risus, faucibus lectus dapibus magna sed
              vulputate integer. Placerat tempus vulputate at phasellus tellus tristique et suscipit. Arcu, adipiscing
              platea id et. Pretium nunc.</p>
            <h4>Aliquam lorem ante, dapibus</h4>
            <p>Vivamus volutpat, mattis tristique commodo vestibulum, blandit quisque quis. Turpis integer blandit at
              proin nulla fringilla ac.</p>
            <ul role="list">
              <li>In enim justo, rhoncus ut, imperdiet a, venenatis vitae.</li>
              <li>Nullam dictum felis eu pede mollis pretium.</li>
              <li>Integer tincidunt. Cras dapibus.</li>
              <li>Vivamus elementum semper nisi eleifend tellus</li>
            </ul>
            <p>Risus laoreet convallis faucibus consectetur nibh sollicitudin mattis. Ut netus sagittis pharetra, amet
              sagittis non amet, sem. Vitae enim fermentum vitae ultrices pellentesque eget dui.</p>
          </div>
        </div>
        <div data-w-id="c8bf05fb-9eee-0307-c6c4-6adf4ca0a1dc" style={{
          transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'
          , opacity: 1, transformStyle: 'preserve-3d'
        }} className="glow-top" />
      </div> */}
      <Footer />
    </>
  );
}

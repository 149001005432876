import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'

export default function Login() {
  document.title = `Login - ${process.env.REACT_APP_TITLE}`;
    return (
        <>
            <Header />

            <div className="section banner wf-section" style={{ justifyItems: "center", paddingTop: "200px" }}>
                <div className="content">
                    <div data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb69" className="group-banner">
                        <div className="block-banner">
                            <h3 data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb6b" className="heading-banner">Login key
                            </h3>
                            <div data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb6d" className="form-block-banner w-form text-left">
                                <div method="get" className="form-banner text-center">
                                    <input type="email" className="text-field w-input" maxLength={256} name="Email" data-name="Email" placeholder="Login Key" id="Email" required />
                                    <a type="submit" className="button w-button mt-10 btn-black" href='/dashboard'>Login</a>
                                    <p className='text-black'>Don't have account ? <a href="/register">Create</a> </p>
                                </div>
                            </div>
                        </div>
                        <div className="glow-banner" />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

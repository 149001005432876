import React from "react";

export default function ValidatorsRewords() {
  return (
    <>
      <div className="section wf-section bg-light">
        <h2
          data-w-id="3930e259-75dd-d066-e614-6fbbe9bf2ee7"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="heading text-black"
        >
          CTEX Validator Rewards
        </h2>
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          {/* <div className="page-heading">
                        <h3 data-w-id="88efa683-a3ad-b788-d8e0-a44585c25590"
                            style={{
                                transform:
                                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 1,
                                transformStyle: "preserve-3d",
                                textAlign:"center",
                            }} >Validator Rewards
                        </h3>
                    </div> */}
          <div className="w-layout-grid grid-a">
            <div
              data-w-id="893553c5-8fd4-cfbf-8679-88871d8ec8a6"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="technology"
            >
              <img
                src="./assets/img/protocol.svg"
                loading="lazy"
                alt=""
                className="icon-technology"
              />
              <h6>Transaction Rewards</h6>
              <p>
                Transaction rewards are incentivised to validators from
                transaction gas. Every transaction requires a gas fee to be
                executed, this gas fee goes to validators.
              </p>
            </div>
            <div
              data-w-id="919317fc-3fc6-5955-bd1f-d2b83420d18f"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="technology"
            >
              <img
                src="./assets/img/staking.svg"
                loading="lazy"
                alt=""
                className="icon-technology"
              />
              <h6>Staking Rewards</h6>
              <p>
                Validators are offered staking rewards on their staked CTEX.
                This reward is released daily and unlocked on monthly basis. The
                protocol has allocated a separate fund for validator rewards
                which releases genesis CTEX as in form of staking rewards.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import Footer from "../comman/Footer";
import Header from "../comman/Header";
import FactDekho from "./FactDekho";
import GoomoJoomo from "./GoomoJoomo";
import IMSelf from "./IMSelf";
import JustBit from "./JustBit";
import MobiStar from "./MobiStar";
import ProductHero from "./ProductHero";
import RuptoNFT from "./RuptoNFT";
import RuptoPay from "./RuptoPay";
import SelfMart from "./SelfMart";
import SprtFan from "./SprtFan";
import TapInfo from "./TapInfo";

export default function Products() {
  return (
    <>
      <Header />
      <ProductHero />
      {/* <GoomoJoomo/> */}
      <JustBit />
      {/* <FactDekho/> */}
      {/* <SelfMart/> */}
      {/* <SprtFan /> */}
      {/* <IMSelf /> */}
      {/* <TapInfo /> */}
      <RuptoPay />
      <RuptoNFT />
      <MobiStar />
      <Footer />
    </>
  );
}

import React from "react";

export default function AmbassdorCommunity() {
  return (
    <>
      <div className="section wf-section">
        <div className="content">
          <div className="block-heading">
            <div
              data-w-id="68275e70-ddcc-9d46-397e-61d7b3551ba4"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="subtitle"
            >
              supported community{" "}
            </div>
            <h2
              data-w-id="68275e70-ddcc-9d46-397e-61d7b3551ba6"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="heading"
            >
              Join the community of developers, researchers and CTEX users.
            </h2>
          </div>
          {/* <div className="collection-list-wrapper w-dyn-list">
                        <div role="list" className="collection-list w-dyn-items">
                            <div role="listitem" className="collection-item w-dyn-item"><a data-w-id="9b3a04ce-54f9-ab44-7137-87c1c7ad463c" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} href="post/introducing-enhanced-search-api-functionality-with-core-2-0.html" className="link-image-blog w-inline-block"><img src="https://assets.website-files.com/614519168cffbd131c32d792/61451eb41100bf61e2edaeb4_blog 1.svg" loading="lazy" alt="Introducing enhanced search API functionality with Core 2.0" className="image-blog" />
                                <div className="bg-blog" />
                            </a>
                                <div data-w-id="92c8a6ff-e0e8-c539-af2a-4e39bf8fd110" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="block-blog">
                                    <div className="date-blog">September 18, 2021</div><a href="post/introducing-enhanced-search-api-functionality-with-core-2-0.html" className="link-heading-blog w-inline-block">
                                        <h6 className="heading-blog">Introducing enhanced search API functionality with Core 2.0
                                        </h6>
                                    </a>
                                </div>
                            </div>
                            <div role="listitem" className="collection-item w-dyn-item"><a data-w-id="9b3a04ce-54f9-ab44-7137-87c1c7ad463c" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} href="post/introducing-standardised-smart-contract-apis" className="link-image-blog w-inline-block"><img src="https://assets.website-files.com/614519168cffbd131c32d792/61451ebf03b6b874b09745aa_blog 2.svg" loading="lazy" alt="Introducing standardised smart contract APIs" className="image-blog" />
                                <div className="bg-blog" />
                            </a>
                                <div data-w-id="92c8a6ff-e0e8-c539-af2a-4e39bf8fd110" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="block-blog">
                                    <div className="date-blog">September 18, 2021</div><a href="post/introducing-standardised-smart-contract-apis" className="link-heading-blog w-inline-block">
                                        <h6 className="heading-blog">Introducing standardised smart contract APIs</h6>
                                    </a>
                                </div>
                            </div>
                            <div role="listitem" className="collection-item w-dyn-item"><a data-w-id="9b3a04ce-54f9-ab44-7137-87c1c7ad463c" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} href="post/new-partnership-marks-multi-chain-evolution-for-smart-contracts" className="link-image-blog w-inline-block"><img src="https://assets.website-files.com/614519168cffbd131c32d792/61451ec9f48304bdea104c6a_blog 3.svg" loading="lazy" alt="New Partnership Marks Multi-chain Evolution for Smart Contracts" className="image-blog" />
                                <div className="bg-blog" />
                            </a>
                                <div data-w-id="92c8a6ff-e0e8-c539-af2a-4e39bf8fd110" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="block-blog">
                                    <div className="date-blog">September 18, 2021</div><a href="post/new-partnership-marks-multi-chain-evolution-for-smart-contracts" className="link-heading-blog w-inline-block">
                                        <h6 className="heading-blog">New Partnership Marks Multi-chain Evolution for Smart Contracts
                                        </h6>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
      <div className="main">
        {/* Facebook Icon */}
        <a
          className="icon-a"
          href="https://www.facebook.com/people/Crypto-Tex-Token/100083171064229/"
          target="_blank"
        >
          <div className="icon s-icon">
            <i className="fa fa-facebook-f" />
            <span>Facebook</span>
          </div>
        </a>
        {/* Twitter Icon */}
        <a
          className="icon-a"
          href="https://twitter.com/CryptoTexCoin"
          target="_blank"
        >
          <div className="icon s-icon">
            <i className="fa fa-twitter" />
            <span>Twitter</span>
          </div>
        </a>
        {/* Linkedin Icon */}
        <a
          className="icon-a"
          href="https://www.instagram.com/ctexcoin/"
          target="_blank"
        >
          <div className="icon s-icon">
            <i className="fa fa-instagram" />
            <span>Instagram</span>
          </div>
        </a>
        {/* GitHub Icon */}
        <a
          className="icon-a"
          href="https://t.me/cryptotexofficial"
          target="_blank"
        >
          <div className="icon s-icon">
            <i className="fa fa-telegram" />
            <span>Telegram</span>
          </div>
        </a>
        {/* <a
              className="icon-a"
              href="https://t.me/ruptoofficial"
              target="_blank"
            >
              <div className="icon s-icon">
                <img
                  src="./assets/img/icon/dicorrd.png"
                  width="40"
                  className="fa fa-telegram"
                  alt=""
                />
                <span>Discord</span>
              </div>
            </a> */}
        <a
          className="icon-a text-black"
          href="https://github.com/ctexcoin"
          target="_blank"
        >
          <div className="icon s-icon">
            <i className="fa fa-github" />
            <span>GitHub</span>
          </div>
        </a>
        {/* <a
              className="icon-a"
              href="https://t.me/ruptoofficial"
              target="_blank"
            >
              <div className="icon s-icon">
                <img
                  src="./assets/img/icon/gitlook.png"
                  width="40"
                  className="fa fa-telegram"
                  alt=""
                />
                <span>GitBook</span>
              </div>
            </a> */}
        {/* <a
              className="icon-a"
              href="https://t.me/ruptoofficial"
              target="_blank"
            >
              <div className="icon s-icon">
                <img
                  src="./assets/img/icon/medium.png"
                  width="40"
                  className="fa fa-telegram"
                  alt=""
                />
                <span>Medium</span>
              </div>
            </a> */}
        <a
          className="icon-a text-black"
          href="https://www.reddit.com/r/ctexcoin/?rdt=54742"
          target="_blank"
        >
          <div className="icon s-icon">
            <i className="fa fa-reddit" />
            <span>Reddit</span>
          </div>
        </a>
        {/* YouTube Icon */}
        <a
          className="icon-a text-black"
          href="https://www.linkedin.com/company/crypto-tex"
        >
          <div className="icon s-icon">
            <i className="fa fa-linkedin" />
            <span>LimkedIn</span>
          </div>
        </a>
      </div>
    </>
  );
}

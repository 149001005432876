import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import RuptoProvider from './RuptoContext';
import MyContext from './MyContext';
import web3 from 'web3';
import toast, { Toaster } from 'react-hot-toast';
import { Web3ReactProvider } from '@web3-react/core'
const toastError = (data) => {
  toast.error(data);
}
const root = ReactDOM.createRoot(document.getElementById('root'));
const getLibrary = (provider) => {
  return new web3(provider)
}

// if (typeof window.ethereum.isTrust !== 'undefined') {
//   console.log('Trust Wallet is installed!');
// } else {
//   toastError("Trust Wallet Not Install!")
//   console.log('Trust Wallet Not Install!')
// }
// if (typeof window.ethereum !== 'undefined') {
//   console.log('MetaMask is installed!');
// } else {
//   console.log('MetaMask Not Install!')
// }
// <Toaster
//   position="top-left"
//   toastOptions={{
//     // Define default options
//     className: '',
//     duration: 5000,
//     style: {
//       background: '#ffa518',
//       color: '#fff',
//     },
//     // success: {
//     //   duration: 5000,
//     //   theme: {
//     //     primary: 'green',
//     //     secondary: 'black',
//     //   },
//     // }
//   }} />
root.render(
  <Web3ReactProvider getLibrary={getLibrary} >
    <MyContext />
  </Web3ReactProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import Footer from "../comman/Footer";
import Header from "../comman/Header";

export default function DetailPage() {
  return (
    <>
      <Header />
      <div className="section-hero wf-section">
        <div className="content hero">
          <img src="../assets/img/about-bg.png" className="bg" alt="" />
          <div className="block-hero">
            <h1
              data-w-id="fec0fb7b-303f-09d0-3ce6-40f91d7252a5"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="heading text-left"
            >
              About CTEX DeFi app
            </h1>
            <p
              data-w-id="dcd739db-6d1f-6a52-859b-4a3fd0e20c84"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="paragraph-large"
            >
              Learn more and understand CTEX Chain & its Products
            </p>
          </div>
        </div>
      </div>
      <div className="section-hero wf-section bg-light">
        <div className="content single-page">
          <div className="block-hero">
            <h1
              data-w-id="c8bf05fb-9eee-0307-c6c4-6adf4ca0a1d8"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="heading-single-page text-black"
            >
              Download the app
            </h1>
            <p>
              Download the latest app from the{" "}
              <a href="/downloads" className="text-black">
                downloads
              </a>{" "}
              page.
            </p>
          </div>
          <div
            data-w-id="b18b81cf-a2db-0c97-0718-4b6d84339d65"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              opacity: 1,
              transformStyle: "preserve-3d",
            }}
            className="rich-text-block w-richtext"
          >
            <p>
              Dui id ultrices neque, risus libero justo. Dolor adipiscing orci,
              pharetra, sociis. Eget tempus viverra quisque orci tortor,
              ultricies. Interdum ultrices sem tincidunt donec pellentesque eu.
              Morbi at commodo tellus lobortis quam lobortis in.
            </p>
            <h4 className="text-black">Aenean vulputate eleifend</h4>
            <p>
              Suspendisse sit sodales purus, fringilla metus. Hac ornare aenean
              risus id nunc lobortis sagittis. Magna amet mauris dis molestie
              amet. Nisl pulvinar egestas erat erat adipiscing sed.
            </p>

            <p>
              In tristique iaculis consectetur fermentum, in commodo consequat
              imperdiet vitae. Vitae id at nisl phasellus. Tellus vestibulum,
              turpis viverra in lacinia non in cursus aenean. Suscipit lacus
              enim tortor, in gravida malesuada semper. Quam odio lorem arcu,
              egestas.
            </p>
            <h4 className="text-black">Nulla consequat massa enim</h4>
            <p>
              Vivamus volutpat, mattis tristique commodo vestibulum, blandit
              quisque quis. Turpis integer blandit at proin nulla fringilla ac.
            </p>
            <blockquote className="text-golden">
              “Etiam lectus bibendum nam tortor, est posuere. Mauris tempus eget
              tempor nunc ultricies. Sem morbi volutpat.”
            </blockquote>
            <p>
              Maecenas nibh sem dui, tincidunt. Leo pulvinar consectetur amet
              tellus. Magna libero mattis tortor lorem ornare ac integer. Ac
              eget donec neque nunc lectus gravida. Risus, faucibus lectus
              dapibus magna sed vulputate integer. Placerat tempus vulputate at
              phasellus tellus tristique et suscipit. Arcu, adipiscing platea id
              et. Pretium nunc.
            </p>

            <h4 className="text-black">Aliquam lorem ante, dapibus</h4>
            <p>
              Vivamus volutpat, mattis tristique commodo vestibulum, blandit
              quisque quis. Turpis integer blandit at proin nulla fringilla ac.
            </p>
            <ul role="list">
              <li>In enim justo, rhoncus ut, imperdiet a, venenatis vitae.</li>
              <li>Nullam dictum felis eu pede mollis pretium.</li>
              <li>Integer tincidunt. Cras dapibus.</li>
              <li>Vivamus elementum semper nisi eleifend tellus</li>
            </ul>
            <p>
              Risus laoreet convallis faucibus consectetur nibh sollicitudin
              mattis. Ut netus sagittis pharetra, amet sagittis non amet, sem.
              Vitae enim fermentum vitae ultrices pellentesque eget dui.
            </p>
          </div>
        </div>
        <div
          data-w-id="c8bf05fb-9eee-0307-c6c4-6adf4ca0a1dc"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="glow-top"
        />
      </div>
      <Footer />
    </>
  );
}

import React from "react";

export default function NumberOfValidators() {
    return (
        <>
            <div className="section wf-section bg-light pb-0">
                <div data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc" className="content">
                    <div className="w-layout-grid grid-page-b">
                        <div
                            data-w-id="9040b537-44e1-e314-8717-bd0bd8cbe0a4"
                            style={{
                                transform:
                                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 1,
                                transformStyle: "preserve-3d",
                            }}
                            className="stats lighter"
                        >
                            <h4 className="heading-stats text-black">100+</h4>
                            <div className="text-stats">Available Validator Spots</div>
                        </div>
                        <div
                            data-w-id="f0aa4076-555c-586f-088d-17e0523b26a1"
                            style={{
                                transform:
                                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 1,
                                transformStyle: "preserve-3d",
                            }}
                            className="stats lighter"
                        >
                            <h4 className="heading-stats text-black">$50k+</h4>
                            <div className="text-stats">Approximate Transaction Volume</div>
                        </div>
                        <div
                            data-w-id="27764734-a540-afeb-0e84-c4274378e76a"
                            style={{
                                transform:
                                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 1,
                                transformStyle: "preserve-3d",
                            }}
                            className="stats lighter"
                        >
                            <h4 className="heading-stats text-black">∼24%</h4>
                            <div className="text-stats">Staking APY for Node Operators</div>
                        </div>
                    </div>
                    {/* <div className="page-content">
                        <h3
                            data-w-id="88efa683-a3ad-b788-d8e0-a44585c25590"
                            style={{
                                transform:
                                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 1,
                                transformStyle: "preserve-3d",
                            }}
                        >
                            We are a community of passionate humans building a distributed
                            cloud
                        </h3>
                        <p
                            data-w-id="0dafb890-b226-74d6-9466-46f5bec96839"
                            style={{
                                transform:
                                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 1,
                                transformStyle: "preserve-3d",
                            }}
                        >
                            Pulvinar duis eget tellus suscipit amet neque, purus. Auctor sed
                            accumsan gravida felis massa ornare in. Iaculis quis lectus
                            convallis proin proin leo. Convallis pellentesque eget at
                            ultricies amet scelerisque ut. <br />
                            <br />
                            Nunc proin adipiscing praesent sed accumsan. Porttitor in amet
                            vulputate quisque. Lacus, nunc mi, lacus, commodo mattis. Mi in
                            aliquam id sed vitae ultrices enim. Pulvinar odio varius sit sed
                            in pellentesque malesuada quisque. Massa amet arcu sit
                            pellentesque eget faucibus. Laoreet.
                        </p>
                    </div> */}
                    {/* <div className="w-layout-grid grid-team">
                        <div
                            data-w-id="bb94df64-cb79-9bf0-537f-3833576410c6"
                            style={{
                                transform:
                                    "translate3d(0px, 15px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 0,
                                transformStyle: "preserve-3d",
                            }}
                            className="team-member"
                        >
                            <img
                                src="https://assets.website-files.com/613e7a6e19fd8f65b8d29b8e/614e51874959236d7d42abe9_Member.svg"
                                loading="lazy"
                                alt=""
                                className="image-member"
                            />
                            <div className="block-member">
                                <h6 className="name-member">Troy Chapman</h6>
                                <div className="info-member">Co-founder</div>
                            </div>
                        </div>
                        <div
                            data-w-id="2fb9d41c-1668-1d44-4857-66053502bdb9"
                            style={{
                                transform:
                                    "translate3d(0px, 15px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 0,
                                transformStyle: "preserve-3d",
                            }}
                            className="team-member"
                        >
                            <img
                                src="https://assets.website-files.com/613e7a6e19fd8f65b8d29b8e/614e51874959236d7d42abe9_Member.svg"
                                loading="lazy"
                                alt=""
                                className="image-member"
                            />
                            <div className="block-member">
                                <h6 className="name-member">Scott Abbott</h6>
                                <div className="info-member">Co-founder</div>
                            </div>
                        </div>
                        <div
                            data-w-id="5ae747ee-7677-ab47-8ba9-6eef4b9b269f"
                            style={{
                                transform:
                                    "translate3d(0px, 15px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 0,
                                transformStyle: "preserve-3d",
                            }}
                            className="team-member"
                        >
                            <img
                                src="https://assets.website-files.com/613e7a6e19fd8f65b8d29b8e/614e51874959236d7d42abe9_Member.svg"
                                loading="lazy"
                                alt=""
                                className="image-member"
                            />
                            <div className="block-member">
                                <h6 className="name-member">Duane Simmons</h6>
                                <div className="info-member">Developer</div>
                            </div>
                        </div>
                        <div
                            data-w-id="542b0ad5-f079-ec93-2320-72cc8e823a8c"
                            style={{
                                transform:
                                    "translate3d(0px, 15px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 0,
                                transformStyle: "preserve-3d",
                            }}
                            className="team-member"
                        >
                            <img
                                src="https://assets.website-files.com/613e7a6e19fd8f65b8d29b8e/614e51874959236d7d42abe9_Member.svg"
                                loading="lazy"
                                alt=""
                                className="image-member"
                            />
                            <div className="block-member">
                                <h6 className="name-member">Frank Henry</h6>
                                <div className="info-member">Co-founder</div>
                            </div>
                        </div>
                        <div
                            data-w-id="722dc918-0e1e-8388-7f12-3f22e025ad84"
                            style={{
                                transform:
                                    "translate3d(0px, 15px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 0,
                                transformStyle: "preserve-3d",
                            }}
                            className="team-member"
                        >
                            <img
                                src="https://assets.website-files.com/613e7a6e19fd8f65b8d29b8e/614e51874959236d7d42abe9_Member.svg"
                                loading="lazy"
                                alt=""
                                className="image-member"
                            />
                            <div className="block-member">
                                <h6 className="name-member">Deborah Roberts</h6>
                                <div className="info-member">Product Owner</div>
                            </div>
                        </div>
                        <div
                            data-w-id="13f02683-3e9e-6692-e7ca-800c0551da85"
                            style={{
                                transform:
                                    "translate3d(0px, 15px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 0,
                                transformStyle: "preserve-3d",
                            }}
                            className="team-member"
                        >
                            <img
                                src="https://assets.website-files.com/613e7a6e19fd8f65b8d29b8e/614e51874959236d7d42abe9_Member.svg"
                                loading="lazy"
                                alt=""
                                className="image-member"
                            />
                            <div className="block-member">
                                <h6 className="name-member">Arthur Williams</h6>
                                <div className="info-member">Developer</div>
                            </div>
                        </div>
                        <div
                            data-w-id="21e7ed7f-4604-10b1-748b-37a1e6e22ce1"
                            style={{
                                transform:
                                    "translate3d(0px, 15px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 0,
                                transformStyle: "preserve-3d",
                            }}
                            className="team-member"
                        >
                            <img
                                src="https://assets.website-files.com/613e7a6e19fd8f65b8d29b8e/614e51874959236d7d42abe9_Member.svg"
                                loading="lazy"
                                alt=""
                                className="image-member"
                            />
                            <div className="block-member">
                                <h6 className="name-member">Doris Peterson</h6>
                                <div className="info-member">Co-founder</div>
                            </div>
                        </div>
                        <div
                            data-w-id="59875d70-392d-8277-03db-77a776d77ce9"
                            style={{
                                transform:
                                    "translate3d(0px, 15px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 0,
                                transformStyle: "preserve-3d",
                            }}
                            className="team-member"
                        >
                            <img
                                src="https://assets.website-files.com/613e7a6e19fd8f65b8d29b8e/614e51874959236d7d42abe9_Member.svg"
                                loading="lazy"
                                alt=""
                                className="image-member"
                            />
                            <div className="block-member">
                                <h6 className="name-member">Jessica Sanders</h6>
                                <div className="info-member">Developer</div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
}

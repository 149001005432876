import React from 'react'
import Header from '../comman/Header'
import Footer from '../comman/Footer'
import DappHero from './DappHero'
import Dapps from './Dapps'
import Submittab from './Submittab'

export default function SubmitDapp() {
    return (
        <>
            <Header />
            <DappHero />
            <Submittab/>
            <Dapps/>
            <Footer />
        </>
    )
}

import React from 'react'

export default function ShardedChain() {
  return (
    <>
      <div className="section wf-section bg-light-yellow ptblr-40">
        <div data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc" className="content">
          <div className="block-heading">
            <h1 data-w-id="edc71457-7d5b-7bea-ff46-ddb5540db920" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d', textAlign: "start" }} className="heading text-black">World’s first token with Proof-of-Asset Security</h1>
          </div>
        </div>
      </div>
      <div className="section wf-section bg-light-yellow ptblr-30">
        <div className="content">
          <div className="w-layout-grid grid-right">
            <div className="block-left">
              <img src="./assets/img/whitepaper.png" alt="" />
            </div>
            <div id="w-node-_5713fedf-471c-a9c3-90d4-7d6642ea17a8-6ad29b8f" className="block">
              {/* <div data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17a9" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="subtitle">Governance</div> */}
              <p data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ad" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="paragraph-large">Pioneering the tokenization with a highly required tokenization mechanism to ensure the asset surety. The protocol works with oracle driven data and on-chain optimization of the underlying documented asset.</p>
              <div data-w-id="2d6db815-ef82-a123-9a77-26cdadb39017" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="w-layout-grid grid-button"><a href='/governance' className="button w-button text-white">See Whitepaper</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section wf-section bg-light-yellow ptblr-30">
        <div data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc" className="content">
          <div className="w-layout-grid grid-page-b">
            <div data-w-id="f0aa4076-555c-586f-088d-17e0523b26a1" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="stats lighter">
              <h4 className="heading-stats text-black">15+</h4>
              <div className="text-stats text-black">Ecosystem Partners</div>
            </div>
            <div data-w-id="27764734-a540-afeb-0e84-c4274378e76a" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="stats lighter">
              <h4 className="heading-stats text-black">$30M+</h4>
              <div className="text-stats text-black">Asset Surety</div>
            </div>
            <div data-w-id="918d5c54-f1a0-3064-11f2-3a26b74b3ba9" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="stats lighter">
              <h4 className="heading-stats text-black">100k+</h4>
              <div className="text-stats text-black">Community Members</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import CryptoPortfolio from './CryptoPortfolio'
import ExchangeHero from './ExchangeHero'
import LiveRate from './LiveRate'
import Possibilities from './Possibilities'
import Support from './Support'
import Trade from './Trade'
import TrustedCrypto from './TrustedCrypto'

export default function Exchange() {
  return (
    <>
    <Header/>
    <ExchangeHero/>
    <Trade/>
    <CryptoPortfolio/>
    <LiveRate/>
    <Possibilities/>
    <TrustedCrypto/>
    <Support/>
    <Footer/>
    </>
  )
}

import React from "react";

export default function Research() {
  return (
    <>
      <div className="section wf-section bg-light">
        <div className="content  ptblr-50" style={{background:"#f0b41a",borderRadius:"30px"}}>
          <div className="w-layout-grid grid-right-2">
            <div
              id="w-node-a50c89a1-4e8e-1cda-83cc-d3e74bcc498a-e3eb6dcf"
              className="block"
            >
              <h4
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc498d"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="heading"
              >
                CTEX is building blockchain based solution to push decentralized
                economy forward
              </h4>
            </div>
            <div className="block-right">
              <div
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc4985"
                className="figure-d-2"
              >
                <img
                  src="./assets/img/career/passion.png"
                  className="r-10"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import Footer from "../comman/Footer";
import Header from "../comman/Header";

export default function AssetTokenization() {
  return (
    <>
      <Header />
      <div className="section-hero wf-section">
        <div className="content hero">
          <img src="../assets/img/about-bg.png" className="bg" alt="" />
          <div className="block-hero">
            <h1
              data-w-id="fec0fb7b-303f-09d0-3ce6-40f91d7252a5"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="heading text-left"
            >
              Understanding On-chain Asset Tokenization
            </h1>
          </div>
        </div>
      </div>

      <div className="section-hero wf-section pt-0 bg-light">
        <div className="content single-page pt-0 mt-0">
          <div
            data-w-id="b18b81cf-a2db-0c97-0718-4b6d84339d65"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              opacity: 1,
              transformStyle: "preserve-3d",
            }}
            className="rich-text-block w-richtext text-black"
          >
            <p className="text-justify">
              The tokenization of real world assets is really an extended use
              case of blockchain technology beyond cryptocurrency payments that
              enables the purchase, sale and exchange of digital assets on the
              distributed ledger in the form of a token
            </p>
            <p className="text-justify">
              The asset which is tokenized on the blockchain technology, or real
              assets can nearly all be converted into digital tokens through
              tokenization. For instance, tokens represented on the blockchain
              have an economic value physically. Its process may represent an
              investment fund, company shares or real estate ownership. The
              token ownership right allows its holder to trade it without any
              permission.
            </p>
            <p className="text-justify">
              The application of on-chain asset tokenization helps in creating
              alternative financing models to overcome infrastructure
              development barriers, given the rapid transfer of infrastructure
              to intelligent systems and the desire to unleash efficient finance
              with decentralization.
            </p>
            <p className="text-justify">
              Tokenization enables transfer of off-chain real-world assets' and
              its economic worth into the world of blockchain and decentralized
              finance. The blockchain technology and on-chain asset tokenization
              are still in a very early phase. There are protocols being built
              with the tokenization of real world assets such as plots,
              buildings, and other properties. However, asset tokenization is
              still in its infancy; therefore, challenges must be surmounted
              before they are widely adopted.
            </p>
            <p className="text-justify">
              CTEX protocol is simplifying the process of converting real world
              assets into distributed tokenized assets and putting asset
              tokenization into practice. The solution CTEX protocol provides is
              through the Proof-of-Asset Security (PoAS) model which is proposed
              for tokenization.CTEX is the issuing tokens that are representing
              real world assets.{" "}
            </p>
          </div>
        </div>
        <div
          data-w-id="c8bf05fb-9eee-0307-c6c4-6adf4ca0a1dc"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="glow-top"
        />
      </div>
      <Footer />
    </>
  );
}

import React from "react";

export default function AmbassdorsDetails() {
  return (
    <>
      <div className="section wf-section bg-light">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <div className="w-layout-grid grid-technology text-black">
            <div
              data-w-id="893553c5-8fd4-cfbf-8679-88871d8ec8a6"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="technology bg-light"
            >
              <img src="./assets/img/icon/growth.png" />
              <h6 className="text-black">Empower Growth</h6>
              <p>
                Ambassadors of CTEX project seek the ecosystem growth & project
                development opportunities in the decentralized finance economy.
              </p>
            </div>
            <div
              data-w-id="919317fc-3fc6-5955-bd1f-d2b83420d18f"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="technology bg-light"
            >
              <img
                src="./assets/img/icon/representing.png"
                loading="lazy"
                alt=""
                className="icon-technology"
              />
              <h6 className="text-black">Representing CTEX</h6>
              <p>
                There are thousands of events hosted every year in the
                blockchain industry. Ambassadors represent CTEX to different
                events such as hackathons, conferences, meet-ups etc.
              </p>
            </div>
            <div
              data-w-id="138e3976-e8c8-15d4-ba81-7614035567d3"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="technology bg-light"
            >
              <img
                src="./assets/img/icon/spreading-awareness.png"
                loading="lazy"
                alt=""
                className="icon-technology"
              />
              <h6 className="text-black">Spreading Awareness</h6>
              <p>
                The mutual goal of all the CTEX Ambassadors is to spread the
                core values of CTEX protocol which are proof-of-asset security
                (PoAS), tokenization & decentralization governance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";

export default function ValidatorsReward() {
  return (
    <>
      <div className="section wf-section bg-light pt-0">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <h1 className="text-black">Rewards</h1>
          <div className="w-layout-grid grid-page-a">
            <div
              data-w-id="893553c5-8fd4-cfbf-8679-88871d8ec8a6"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="technology-2"
            >
              <img
                src="https://assets.website-files.com/613e7a6e19fd8f65b8d29b8e/614e2c3dfe771fe94f3ac0af_icon%201%20large.svg"
                loading="lazy"
                alt=""
                className="icon-technology"
              />
              <a
                href="https://rupto.gitbook.io/rupto-documentation/developer-resources"
                className="icon-a"
              >
                <h6 className="text-black">Transaction Rewards</h6>
                <p className="text-black">
                  Validators are rewarded with transaction gas. Every
                  transaction is executed with a specific amount of gas fee
                  which goes to validators.
                </p>
              </a>
            </div>
            <div
              data-w-id="919317fc-3fc6-5955-bd1f-d2b83420d18f"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="technology-2"
            >
              <img
                src="https://assets.website-files.com/613e7a6e19fd8f65b8d29b8e/614e2c3d779115c7f655a8e1_icon%202%20large.svg"
                loading="lazy"
                alt=""
                className="icon-technology"
              />
              <a
                href="https://rupto.gitbook.io/rupto-documentation/developer-resources"
                className="icon-a"
              >
                <h6 className="text-black">Staking Reward</h6>
                <p className="text-black text-justify">
                  Staking Rewards are distributed CTEX node operators. Every
                  node operator is required to stake certain amount of CTEX in
                  order to become a validator. Staking rewards are distributed
                  on a monthly basis.
                </p>
              </a>
            </div>
            {/* <div data-w-id="138e3976-e8c8-15d4-ba81-7614035567d3"
                            style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }}
                            className="technology-2"><img
                                src="https://assets.website-files.com/613e7a6e19fd8f65b8d29b8e/614e2c3df589ed1849ab2270_icon%203%20large.svg"
                                loading="lazy" alt="" className="icon-technology" />
                            <a href="https://rupto.gitbook.io/rupto-documentation/developer-resources" className='icon-a'>
                                <h6 className='text-black'>Stake Pools</h6>
                                <p className='text-black'>Stake Pools are a liquid staking solution that promote censorship resistance, decentralization, and the growth of DeFi on CTEX.</p>
                                <a href="#" className='icon-a'>Learn More</a>
                            </a>
                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import GasFees from './GasFees'
import PlatformHero from './PlatformHero'
import Research from './Research'
import RutpoPlatform from './RutpoPlatform'
import ScalesNetwork from './ScalesNetwork'
import ShardedChain from './ShardedChain'

export default function Platform() {
  return (
    <>
    <Header/>
    <PlatformHero/>
    <RutpoPlatform/>
    <ShardedChain/>
    <ScalesNetwork/>
    <GasFees/>
    <Research/>
    <Footer/>    
    </>
  )
}

import React from 'react'

export default function SubmitForm() {
    return (
        <>
            <div className="section banner wf-section p-140" style={{ height: "85vh"}}>
                <div className="content">
                    <div data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb69" className="group-banner">
                        <div className="block-banner">
                            <h3 data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb6b" className="heading-banner">Submit Your Hash
                            </h3>
                            <div data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb6d" className="form-block-banner w-form align-center">
                                <input type="email" className="text-field w-input w-100" maxLength={256} name="Email" data-name="Email" placeholder="submit your hash" id="Email" required />
                            </div>
                            <p className='mt-10 text-black'>A highly scalable, fast and secure blockchain platform.</p>
                            <div data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb6d" className="form-block-banner w-form align-center">
                                <input type="submit" defaultValue="Subscribe" data-wait="Please wait..." className="button w-button btn-black" />
                            </div>
                        </div>
                        <div className="glow-banner" />
                    </div>
                </div>
            </div>
        </>
    )
}

import React from "react";

export default function BecomeAmbassdor() {
  return (
    <>
      <div className="section wf-section bg-light">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <div className="block-heading">
            <div
              data-w-id="823c362f-ea60-5ffd-ca7f-cde6a03d15bb"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="subtitle"
            >
              Become Ambassador
            </div>
            <h2
              data-w-id="edc71457-7d5b-7bea-ff46-ddb5540db920"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="heading text-black"
            >
              Why become CTEX Ambassador
            </h2>
          </div>
          <div className="w-layout-grid grid-features">
            <div
              data-w-id="893553c5-8fd4-cfbf-8679-88871d8ec8a6"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="feature"
            >
              <div className="icon-feature-bg">
                <img
                  src="./assets/img/icon/membership.png"
                  loading="lazy"
                  alt=""
                  className="icon-feature"
                />
              </div>
              <h6 className="text-black">Exclusive Membership</h6>
              <p className="text-black">
                Ambassadors get exclusive Membership for CTEX Ecosystem
              </p>
            </div>
            <div
              data-w-id="86649301-b3bf-a470-084f-c832dcc04618"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="feature"
            >
              <div className="icon-feature-bg">
                <img
                  src="./assets/img/icon/reward.png"
                  loading="lazy"
                  alt=""
                  className="icon-feature"
                />
              </div>
              <h6 className="text-black">Contribution Rewards</h6>
              <p className="text-black">
                Get Rewards for your Contribution in CTEX Tokens
              </p>
            </div>
            <div
              data-w-id="82c90e76-3d3e-e0a8-5192-1d91c07d6533"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="feature"
            >
              <div className="icon-feature-bg">
                <img
                  src="./assets/img/icon/ecosystem.png"
                  loading="lazy"
                  alt=""
                  className="icon-feature"
                />
              </div>
              <h6 className="text-black">Ecosystem Partnership</h6>
              <p className="text-black">
                Become an Ecosystem Partner and Earn Rewards
              </p>
            </div>
            <div
              data-w-id="d69dcdee-aab4-e517-357b-a0d5f9b2aa34"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="feature"
            >
              <div className="icon-feature-bg">
                <img
                  src="./assets/img/icon/companionship.png"
                  loading="lazy"
                  alt=""
                  className="icon-feature"
                />
              </div>
              <h6 className="text-black">Companionship</h6>
              <p className="text-black">
                Receive fellowship from CTEX protocol
              </p>
            </div>
            <div
              data-w-id="6c922f6d-a6bc-ce40-9a2c-d1ba5021c7a1"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="feature"
            >
              <div className="icon-feature-bg">
                <img
                  src="./assets/img/icon/networking.png"
                  loading="lazy"
                  alt=""
                  className="icon-feature"
                />
              </div>
              <h6 className="text-black">Networking</h6>
              <p className="text-black">
                Grow your Network in Corporate and Web Sector
              </p>
            </div>
            <div
              data-w-id="d8a73d62-1886-742f-ad2e-ca5926422de9"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="feature"
            >
              <div className="icon-feature-bg">
                <img
                  src="./assets/img/icon/travelling.png"
                  loading="lazy"
                  alt=""
                  className="icon-feature"
                />
              </div>
              <h6 className="text-black">Traveling</h6>
              <p className="text-black">
                Travel around different Cities & Join Events
              </p>
            </div>
            <div
              data-w-id="d69dcdee-aab4-e517-357b-a0d5f9b2aa34"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="feature"
            >
              <div className="icon-feature-bg">
                <img
                  src="./assets/img/icon/influencer.png"
                  loading="lazy"
                  alt=""
                  className="icon-feature"
                />
              </div>
              <h6 className="text-black">Influence Others</h6>
              <p className="text-black">
                Become an Influencer in Decentralized Finance Economy
              </p>
            </div>
            <div
              data-w-id="6c922f6d-a6bc-ce40-9a2c-d1ba5021c7a1"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="feature"
            >
              <div className="icon-feature-bg">
                <img
                  src="./assets/img/icon/comunity.png"
                  loading="lazy"
                  alt=""
                  className="icon-feature"
                />
              </div>
              <h6 className="text-black">Manage Community</h6>
              <p className="text-black">
                Community Management & Other Beneficial Activity
              </p>
            </div>
            <div data-w-id="d8a73d62-1886-742f-ad2e-ca5926422de9" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="feature">
                            <div className="icon-feature-bg"><img src="./assets/img/features/unlock_the_future.png" loading="lazy" alt="" className="icon-feature" /></div>
                            <h6 className="text-black">Unlock the Future with Ctex</h6>
                            <p className='text-black'>Get Your Swag On!</p>
                        </div>
          </div>
        </div>
      </div>
    </>
  );
}

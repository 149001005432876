import React, { useState } from "react";

export default function Submittab() {
    const [toggleState, setToggleState] = useState("all");
  return (
    <div>
           <div className="section wf-section ">
        <div className="content">
          <div className="block-heading">
            <div
              data-w-id="6d9ad869-a5c2-fd2f-9bfc-e6fc73288d47"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="subtitle"
            >
              {" "}
              Partner
            </div>
            <h2
              data-w-id="6d9ad869-a5c2-fd2f-9bfc-e6fc73288d49"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="heading"
            >
              Meet our ecosystem partners & collaborators
            </h2>
            <p
              data-w-id="7fc5df83-6010-b5dc-f288-b6f30f9f4eef"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="paragraph-large text-white"
            >
              Driven by collaboration with partners and investors, Ctex Network
              continues to propel forward. With over 400 investors, numerous
              partnerships, and endorsements from leading blockchain protocols,
              confidence in Ctex's scaling infrastructure is evident.
            </p>
          </div>
          <ul className="d-flx-tabs">
            <li>
              <button
                className={
                  toggleState === "all"
                    ? "button-tabs btn-active w-button tabs text-white"
                    : "button-tabs w-button tabs text-white"
                }
                onClick={() => setToggleState("all")}
              >
                All
              </button>
            </li>
            <li>
              <button
                className={
                  toggleState === "newsandmedia"
                    ? "button-tabs btn-active w-button tabs text-white"
                    : "button-tabs w-button tabs text-white"
                }
                
                onClick={() => setToggleState("newsandmedia")}
              >
           CEX
              </button>
            </li>
            <li>
              <button
                className={
                  toggleState === "exchange"
                    ? "button-tabs btn-active w-button tabs text-white"
                    : "button-tabs w-button tabs text-white"
                }
                onClick={() => setToggleState("exchange")}
              >
               Defi
              </button>
            </li>
            <li>
              <button
                className={
                  toggleState === "listing"
                    ? "button-tabs btn-active w-button tabs text-white"
                    : "button-tabs w-button tabs text-white"
                }
                onClick={() => setToggleState("listing")}
              >
             SocialFi
              </button>
            </li>
            <li>
              <button
                className={
                  toggleState === "ecosystem"
                    ? "button-tabs btn-active w-button tabs text-white"
                    : "button-tabs w-button tabs text-white"
                }
                onClick={() => setToggleState("ecosystem")}
              >
              Metaverse
              </button>
            </li>
            <li>
              <button
                className={
                  toggleState === "wallet"
                    ? "button-tabs btn-active w-button tabs text-white"
                    : "button-tabs w-button tabs text-white"
                }
                onClick={() => setToggleState("wallet")}
              >
           Wallet
              </button>
            </li>
            <li>
              <button
                className={
                  toggleState === "infrastructure"
                    ? "button-tabs btn-active w-button tabs text-white"
                    : "button-tabs w-button tabs text-white"
                }
                onClick={() => setToggleState("infrastructure")}
              >
          Infrastructure
              </button>
            </li>
          
            
          </ul>
          <ul  className="d-flx-tabs p-0">
        
            <li>
              <button
                className={
                  toggleState === "gamefi"
                    ? "button-tabs btn-active w-button tabs text-white"
                    : "button-tabs w-button tabs text-white"
                }
                onClick={() => setToggleState("gamefi")}
              >
            GameFi
              </button>
            </li>
            <li>
              <button
                className={
                  toggleState === "bridge"
                    ? "button-tabs btn-active w-button tabs text-white"
                    : "button-tabs w-button tabs text-white"
                }
                onClick={() => setToggleState("bridge")}
              >
             Bridge
              </button>
            </li>
            <li>
              <button
                className={
                  toggleState === "dex"
                    ? "button-tabs btn-active w-button tabs text-white"
                    : "button-tabs w-button tabs text-white"
                }
                onClick={() => setToggleState("dex")}
              >
          DEX
              </button>
            </li>
            <li>
              <button
                className={
                  toggleState === "nft"
                    ? "button-tabs btn-active w-button tabs text-white"
                    : "button-tabs w-button tabs text-white"
                }
                onClick={() => setToggleState("nft")}
              >
          NFT
              </button>
            </li>
            <li>
              <button
                className={
                  toggleState === "paymentsolution"
                    ? "button-tabs btn-active w-button tabs text-white"
                    : "button-tabs w-button tabs text-white"
                }
                onClick={() => setToggleState("paymentsolution")}
              >
        Payment solution
              </button>
            </li>
          </ul>

          <div
            id="tab-1"
            className={
              toggleState === "all"
                ? "w-layout-grid grid-clients ptblr-50"
                : "w-layout-grid grid-clients ptblr-50 d-none"
            }
          >
             <div
              data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
               src="./assets/img/news_media/coin_desk.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
            <div
              data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
               src="./assets/img/news_media/coin_ledger.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
            <div
              data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
               src="./assets/img/news_media/coin_pedia.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
            <div
              data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
             src="./assets/img/news_media/news_bitcoin.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div> <div
              data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
               src="./assets/img/news_media/yahoo_finance.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div> <div
              data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
                src="./assets/img/news_media/fameex (1).png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
             <div
              data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
                src="./assets/img/news_media/biconomy.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
            <div
              data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
                src="./assets/img/news_media/bloomberg.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
            
       
           
            <div
              data-w-id="303ba026-3055-cdf6-a5db-e3bed7bdaecc"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
                src="./assets/img/partners/trust-wallet.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
          
          </div>
          <div
            id="tab-2"
            className={
              toggleState === "newsandmedia"
                ? "w-layout-grid grid-clients ptblr-50"
                : "w-layout-grid grid-clients ptblr-50 d-none"
            }
          >
       
            <div
              data-w-id="15951e27-b4c4-fda3-2e35-207951f74bc2"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
                src="./assets/img/news_media/coin_desk.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
            <div
              data-w-id="15951e27-b4c4-fda3-2e35-207951f74bc2"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
                src="./assets/img/news_media/coin_ledger.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
            <div
              data-w-id="15951e27-b4c4-fda3-2e35-207951f74bc2"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
                src="./assets/img/news_media/coin_pedia.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
            
            <div
              data-w-id="15951e27-b4c4-fda3-2e35-207951f74bc2"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
                src="./assets/img/news_media/news_bitcoin.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
            <div
              data-w-id="15951e27-b4c4-fda3-2e35-207951f74bc2"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
                src="./assets/img/news_media/yahoo_finance.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
            <div
              data-w-id="15951e27-b4c4-fda3-2e35-207951f74bc2"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
                src="./assets/img/news_media/money_control.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
            <div
              data-w-id="15951e27-b4c4-fda3-2e35-207951f74bc2"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
                src="./assets/img/news_media/the_economic_times.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
            <div
              data-w-id="15951e27-b4c4-fda3-2e35-207951f74bc2"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
                src="./assets/img/news_media/up18_news.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
         
            <div
              data-w-id="f567adfd-1fc5-47fc-fe1d-b3cb4ed22463"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
                src="./assets/img/news_media/bloomberg.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
          </div>
          <div
            id="tab-1"
            className={
              toggleState === "exchange"
                ? "w-layout-grid grid-clients ptblr-50"
                : "w-layout-grid grid-clients ptblr-50 d-none"
            }
          > 
            <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          <img
            src="./assets/img/news_media/fameex (1).png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
         <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          <img
            src="./assets/img/news_media/biconomy.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
            {/* <div
              data-w-id="844d1e39-a177-95a8-d613-60f08197b22e"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
                src="./assets/img/partners/coin_ledger.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div> */}
          </div>
          <div
            id="tab-2"
            className={
              toggleState === "listing"
                ? "w-layout-grid grid-clients ptblr-50"
                : "w-layout-grid grid-clients ptblr-50 d-none"
            }
          >
            <div
              data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
                src="./assets/img/listing/be_in_crypto.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
            <div
              data-w-id="f567adfd-1fc5-47fc-fe1d-b3cb4ed22463"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
                src="./assets/img/listing/blockspot.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
        
            <div
              data-w-id="15951e27-b4c4-fda3-2e35-207951f74bc2"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
                src="./assets/img/listing/coin_paperika.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
            <div
              data-w-id="15951e27-b4c4-fda3-2e35-207951f74bc2"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
                src="./assets/img/listing/coin_ranking.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
       
     
       
            <div
              data-w-id="f567adfd-1fc5-47fc-fe1d-b3cb4ed22463"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="client"
            >
              <img
                src="./assets/img/listing/cryptorank.png"
                loading="lazy"
                alt=""
                className="logo-client"
              />
              <div className="bg-client" style={{ opacity: "0.5" }} />
            </div>
          </div>
          <div
            id="tab-2"
            className={
              toggleState === "ecosystem"
                ? "w-layout-grid grid-clients ptblr-50"
                : "w-layout-grid grid-clients ptblr-50 d-none"
            }
          >
      
                 <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_exchange.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
             <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_nft.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
        <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_pay.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
        <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_star.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
       
          </div>
          <div
            id="tab-2"
            className={
              toggleState === "wallet"
                ? "w-layout-grid grid-clients ptblr-50"
                : "w-layout-grid grid-clients ptblr-50 d-none"
            }
          >
      
                 <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_exchange.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
             <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_nft.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
        <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_pay.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
        <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_star.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
       
          </div>
          <div
            id="tab-2"
            className={
              toggleState === "infrastructure"
                ? "w-layout-grid grid-clients ptblr-50"
                : "w-layout-grid grid-clients ptblr-50 d-none"
            }
          >
      
                 <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_exchange.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
             <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_nft.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
        <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_pay.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
        <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_star.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
       
          </div>
          <div
            id="tab-2"
            className={
              toggleState === "gamefi"
                ? "w-layout-grid grid-clients ptblr-50"
                : "w-layout-grid grid-clients ptblr-50 d-none"
            }
          >
      
                 <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_exchange.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
             <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_nft.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
        <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_pay.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
        <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_star.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
       
          </div>
          <div
            id="tab-2"
            className={
              toggleState === "bridge"
                ? "w-layout-grid grid-clients ptblr-50"
                : "w-layout-grid grid-clients ptblr-50 d-none"
            }
          >
      
                 <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_exchange.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
             <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_nft.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
        <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_pay.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
        <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_star.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
       
          </div>
          <div
            id="tab-2"
            className={
              toggleState === "dex"
                ? "w-layout-grid grid-clients ptblr-50"
                : "w-layout-grid grid-clients ptblr-50 d-none"
            }
          >
      
                 <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_exchange.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
             <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_nft.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
        <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_pay.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
        <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_star.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
       
          </div>
          <div
            id="tab-2"
            className={
              toggleState === "nft"
                ? "w-layout-grid grid-clients ptblr-50"
                : "w-layout-grid grid-clients ptblr-50 d-none"
            }
          >
      
                 <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_exchange.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
             <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_nft.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
        <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_pay.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
        <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_star.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
       
          </div>
          <div
            id="tab-2"
            className={
              toggleState === "paymentsolution"
                ? "w-layout-grid grid-clients ptblr-50"
                : "w-layout-grid grid-clients ptblr-50 d-none"
            }
          >
      
                 <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_exchange.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
             <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_nft.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
        <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_pay.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
        <div
          data-w-id="0c106c66-bf23-050f-0fdb-3ed56c3a69f4"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="client"
        >
          
          <img
            src="./assets/img/news_media/ctex_star.png"
            loading="lazy"
            alt=""
            className="logo-client"
          />
          <div className="bg-client" style={{ opacity: "0.5" }} />
        </div>
       
          </div>

        </div>
      </div>
    </div>
  )
}

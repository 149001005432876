import React from "react";

export default function HowTo() {
  return (
    <>
      <div className="section wf-section bg-light">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <div className="block-heading">
            {/* <div data-w-id="68275e70-ddcc-9d46-397e-61d7b3551ba4" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="subtitle">How To</div> */}
            <h2
              data-w-id="68275e70-ddcc-9d46-397e-61d7b3551ba6"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="heading text-black"
            >
              How To Guides
            </h2>
          </div>

          <div className="w-layout-grid grid-page-a">
            <a href="/ctex-ctex" className="icon-a">
              <div
                data-w-id="9040b537-44e1-e314-8717-bd0bd8cbe0a4"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="stats lighter"
              >
                <h6 className="heading-stats text-black">
                  What is CTEX Protocol?
                </h6>
                <div className="text-stats-2 text-black">
                  Basic understanding on how CTEX protocol works.
                </div>
              </div>
            </a>
         
            <a href="/poas" className="icon-a">
              <div
                data-w-id="27764734-a540-afeb-0e84-c4274378e76a"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="stats lighter"
              >
                <h6 className="heading-stats text-black">
                What is the Delegate Proof of Stake (DPoS) Consensus Model?
                </h6>
                <div className="text-stats-2 text-black">
                  Get to know what is proof-of-asset security (PoAS) consensus.
                </div>
              </div>
            </a>
            <a href="/architecture" className="icon-a">
              <div
                data-w-id="918d5c54-f1a0-3064-11f2-3a26b74b3ba9"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="stats lighter"
              >
                <h6 className="heading-stats text-black">
                  The architecture of RBC
                </h6>
                <div className="text-stats-2 text-black">
                  See how RBC blockchain network layers work with one another.
                </div>
              </div>
            </a>
            <a href="/application-protocol" className="icon-a">
              <div
                data-w-id="27764734-a540-afeb-0e84-c4274378e76a"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="stats lighter"
              >
                <h6 className="heading-stats text-black">
                  Application of CTEX Protocol
                </h6>
                <div className="text-stats-2 text-black">
                  Use cases and applications for CTEX protocol and RBC
                </div>
              </div>
            </a>
        
          </div>
        </div>
      </div>
    </>
  );
}

import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import CareerHero from './CareerHero'
import ComeWork from './ComeWork'
import Ourvalue from './Ourvalue'
import WorkWithUs from './WorkWithUs'

export default function Career() {
  document.title = `Career - ${process.env.REACT_APP_TITLE}`;
  return (
    <>
    <Header/>
    <CareerHero/>
    <WorkWithUs/>
    <Ourvalue/>
    <ComeWork/>
    <Footer/>
    </>
  )
}

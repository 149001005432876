import React from "react";

export default function Hero() {
  return (
    <>
      <div className="section-hero wf-section video-1" style={{ height: "1080px" }}>
        <div className="content hero">
          <video muted autoPlay loop id="myVideo" className="video">
            <source src="./assets/rupto_hero.mp4" type="video/webm" />
          </video>
          <div className="block-hero">
            {/* <div data-w-id="3930e259-75dd-d066-e614-6fbbe9bf2ee5" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="subtitle blink_me"><a href="/validators-presale" className='text-golden icon-a'>Validators Presale - join now</a></div> */}
            <h1
              data-w-id="fec0fb7b-303f-09d0-3ce6-40f91d7252a5"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="heading-hero h1-hero"
            >
              Empowering Developers <br />- Permissionless Web3 Innovation
            </h1>
        <div
          className="w-layout-grid grid-button "
          style={{
            transform:
              "translate3d(0px, 15px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
          }}
        >
          <a className="button w-button text-white" target="blank" href="https://ctex-network.gitbook.io/developer-documentation/" style={{ width: "200px" }}>
            Documentation
          </a>
          <a className="button w-button text-white" target="blank" href="/developer-resource" style={{ width: "200px" }}>
            Build
          </a>
        </div>
          </div>
        </div>

      </div>
    </>
  );
}

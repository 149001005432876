import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import Address from './Address'
import Benefits from './Benefits'
import ImselfHero from './ImselfHero'
import LifeStyle from './LifeStyle'
import Mission from './Mission'
import NewFace from './NewFace'

export default function Imself() {
    return (
        <>  
        <Header/>
        <ImselfHero/>
        <NewFace/>
        <Mission/>
        <LifeStyle/>
        <Benefits/>
        {/* <Address/> */}
        <Footer/>
        </>
    )
}

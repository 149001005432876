import React, { useState } from 'react'

export default function HeroDashbord() {
    const [isActive, setIsActive] = useState(false);

    const handleClick = event => {
        // 👇️ toggle isActive state variable
        setIsActive(current => !current);
    };
    return (
        <>
            <div className="section wf-section bg-light pb-0">
                <div data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc" className="content">
                    <div className="block-hero">
                        <h4 data-w-id="fec0fb7b-303f-09d0-3ce6-40f91d7252a5" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="heading text-black pb-30">My Account</h4>
                    </div>
                    <div className="w-layout-grid grid-page-b">
                        <div id="w-node-_03dd9d41-6f8f-6dae-ad77-4ec7b94fceda-e76b7e62" data-w-id="03dd9d41-6f8f-6dae-ad77-4ec7b94fceda" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d', alignItems: "center" }} className="tasks">
                            <div className="tasks-block text-center">
                                <h5 className="heading-tasks text-black heading">Rajesh</h5></div>
                            <img src="./assets/img/user.png" className='rounded-11' alt="" width="200" />
                        </div>
                        <div data-w-id="9040b537-44e1-e314-8717-bd0bd8cbe0a4" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="stats lighter">
                            <h4 className="heading-stats text-black">100 RWT</h4>
                            <div className="text-stats text-black">Balance
                            </div>
                        </div>
                        <div data-w-id="f0aa4076-555c-586f-088d-17e0523b26a1" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="stats lighter">
                            <h4 className="heading-stats text-black">58.3M</h4>
                            <div className="text-stats text-black">Total Blocks 
                            </div>
                        </div>
                        <div data-w-id="27764734-a540-afeb-0e84-c4274378e76a" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="stats lighter">
                            <h4 className="heading-stats text-black">2.4B</h4>
                            <div className="text-stats text-black">Total Stake
                            </div>
                        </div>
                        <div data-w-id="918d5c54-f1a0-3064-11f2-3a26b74b3ba9" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="stats lighter">
                            <h4 className="heading-stats text-black">50</h4>
                            <div className="text-stats text-black">Total Reward
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import EventBlog from './EventBlog'
import EventHero from './EventHero'
import EventSEction from './EventSEction'

export default function Events() {
  return (
    <>
    <Header/>
    <EventHero/>
    <EventSEction/>
    <EventBlog/>
    <Footer/>
    </>
  )
}

import { InjectedConnector } from '@web3-react/injected-connector'

export const CHAIN_ID = 97;
const POLLING_INTERVAL = 12000;
const RPC_URLS = {
    97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    // 56: "https://bsc-dataseed.binance.org/",
    // 19: "http://66.23.226.120:8000"
};


export const injected = new InjectedConnector({
    // supportedChainIds: [1991],
    supportedChainIds: [97],
})
// export const chainData = [
//     {
//         chainId: '0x38',
//         chainName: 'BSC Mainnet',
//         rpcUrls: ['https://bsc-dataseed.binance.org'],
//         blockExplorerUrls: ['https://bscscan.com'],
//     },
// ]
export const chainData = [
    {
        chainId: '0x61',
        chainName: 'BSC Testnet',
        rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
        blockExplorerUrls: ['https://testnet.bscscan.com'],
    },
]

import React, { useState } from "react";
import AutoTyping, { BlinkCursor } from "react-auto-typing";

export default function Build() {
  const [toggleState, setToggleState] = useState(true);
  const [toggleState2, setToggleState2] = useState(false);
  const handleToogletab = () => {
    // alert("hello")
    setToggleState(true);
    setToggleState2(false);
  };
  const handleToogletab2 = () => {
    // alert("hello")
    setToggleState(false);
    setToggleState2(true);
  };
  return (
    <>
      <div>
        <div className="section-hero wf-section  bg-code">
          <div className="content hero mt-30 pb-0 mb-30">
            <div className="block-hero">
              {/* <div data-w-id="78bf7935-39ac-c464-dac4-4f1b84bd7529" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="subtitle">Build</div> */}
              <h1
                data-w-id="294bce85-806e-c17f-c9e9-048ba9d50788"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="heading-single-page"
              >
                Build on CTEX
              </h1>
              <p
                data-w-id="dcd739db-6d1f-6a52-859b-4a3fd0e20c84"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="paragraph-large"
              >
                Build and deploy countless decentralized applications on CTEX
                with a secured and developer friendly blockchain network.
              </p>
              <div
                data-w-id="77b60b92-872c-7997-8a32-0c57412cdee7"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="w-layout-grid grid-button"
              >
                <a
                  href="https://ctex-network.gitbook.io/developer-documentation/"
                  target="_blank"
                  className="button w-button text-white"
                >
                  Read Docs
                </a>
                <a
                  href="https://github.com/ctexcoin"
                  target="_blank"
                  className="button w-button text-white"
                >
                  GitHub
                </a>
              </div>
            </div>
          </div>  
          <div
            data-w-id="58e11b86-9d96-c9a8-f52f-b1795c37fa57"
            style={{
              WebkitTransform:
                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              MozTransform:
                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              msTransform:
                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              transform:
                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              opacity: 1,
            }}
            className="glow-top"
          />
          <main>
            <section className="tabbed-window-list">
              <section className="tabs">
                <div
                  className={toggleState ? "tab tab-active" : "tab"}
                  onClick={handleToogletab}
                >
                  
                  <h5>Code</h5>
                </div>
                <div
                  className={toggleState2 ? "tab tab-active" : "tab"}
                  onClick={handleToogletab2}
                >
                  
                  <h5>Dribbble</h5>
                </div>
                <div className="tab">
                  {/* <img loading="lazy" src="https://tabbed-window-list.iamdanial.com/assets/images/github.svg" alt="GitHub" width={32} height={32} />
                                <h2>GitHub</h2> */}
                </div>
                <div className="tab">
                  {/* <img loading="lazy" src="https://tabbed-window-list.iamdanial.com/assets/images/linkedin.svg" alt="LinkedIn" width={32} height={32} />
                                <h2>LinkedIn</h2> */}
                </div>
                <div className="tab">
                  {/* <img loading="lazy" src="https://tabbed-window-list.iamdanial.com/assets/images/twitter.svg" alt="Twitter" width={32} height={32} />
                                <h2>Twitter</h2> */}
                </div>
                <div className="tab">
                  {/* <img loading="lazy" src="https://tabbed-window-list.iamdanial.com/assets/images/vimeo.svg" alt="Vimeo" width={32} height={32} />
                                <h2>Vimeo</h2> */}
                </div>
              </section>
              <section className="content-pane">
                <div
                  className={
                    toggleState
                      ? "tab-content tab-content-active"
                      : "tab-content"
                  }
                >
                  {/* <h3>CodePen</h3> */}
                  <div className="code-block">
                    <div className="code-lines">
                      <div>
                        1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />
                        9<br />
                        10
                        <br />
                        11
                        <br />
                        12
                        <br />
                        13
                        <br />
                        14
                        <br />
                        15
                        <br />
                        16
                        <br />
                        17
                        <br />
                        18
                        <br />
                      </div>
                    </div>
                    <div className="code-content">
                      <div className="code-content-text">
                        <span className="code-keyword">const </span>
                        CTEX = <span className="code-keyword">new</span> CTEX(
                        {"{"} <span className="code-keyword">token</span>:
                        api_token {"}"});
                        <br />
                        <br />
                        <span className="code-comment">
                          // Promise &lt;Item&gt;
                        </span>
                        <br />
                        <span className="code-keyword">const </span>
                        item = CTEX.
                        <span className="code-function">createItem</span>({"{"}
                        <br />{" "}
                        <span className="code-keyword">collectionId</span>:{" "}
                        <span className="code-string">
                          '580e63fc8c9a982ac9b8b745'
                        </span>
                        ,<br />
                        <span className="code-keyword">fields</span>: {"{"}
                        <br />
                        <span className="code-string">'name'</span>:{" "}
                        <span className="code-string">
                          'Exciting blog post title'
                        </span>
                        ,<br />
                        <span className="code-string">'slug'</span>:{" "}
                        <span className="code-string">'exciting-post'</span>,
                        <br />
                        <span className="code-string">'_archived'</span>:{" "}
                        <span className="code-number">false</span>,<br />{" "}
                        <span className="code-string">'_draft'</span>:
                        <span className="code-number">false</span>,<br />{" "}
                        <span className="code-string">'color'</span>:{" "}
                        <span className="code-string">'#a98080'</span>,<br />{" "}
                        <span className="code-string">'author'</span>:{" "}
                        <span className="code-string">
                          '580e640c8c9a982ac9b8b778'
                        </span>
                        ,<br />
                        <span className="code-string">'post-body'</span>:{" "}
                        <span className="code-string">
                          '&lt;p&gt;Blog post contents...&lt;/p&gt;'
                        </span>
                        ,<br />
                        <span className="code-string auto-type">
                          'post-summary'
                        </span>
                        :{" "}
                        <span className="code-string">
                          'Summary of exciting blog post'
                        </span>
                        ,<br />{" "}
                        <span className="code-string">'main-image'</span>:'
                        <span className="code-string">
                          <AutoTyping
                            active // <boolean>
                            textRef="580e63fe8c9a982ac9b8b749" // <string>
                            writeSpeed={150} // <number>
                            deleteSpeed={150} // <number>
                            delayToWrite={1000} // <number>
                            delayToDelete={2000} // <number>
                          />
                          <BlinkCursor
                            active // <boolean>
                            blinkSpeed={500} // <number>
                          />
                        </span>
                        ',
                        <br />
                        {"}"},<br />
                        {"}"});
                      </div>
                    </div>
                  </div>
                  {/* <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quam non molestiae odio maiores? Fuga laboriosam debitis eaque corporis reiciendis, tempore consequuntur temporibus quod amet porro voluptate enim odit error dignissimos excepturi officia ea doloribus animi quia. Voluptas dolorum aperiam optio.</p> */}
                </div>
                <div
                  className={
                    toggleState2
                      ? "tab-content tab-content-active"
                      : "tab-content"
                  }
                >
                  <div className="code-block">
                    <div className="code-lines">
                      <div>
                        1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />
                        9<br />
                        10
                        <br />
                        11
                        <br />
                        12
                        <br />
                        13
                        <br />
                        14
                      </div>
                    </div>

                    <div className="code-content">
                      <div className="code-content-text">
                        <span className="code-keyword">const </span>
                        axios <span className="code-keyword"> = </span>{" "}
                        require('<span className="code-function">axios</span>');
                        <br />
                        <br />
                        <span className="code-comment">
                          // Get &lt;Item&gt;
                        </span>
                        <br />
                        axios.<span className="code-function">get</span>('
                        <span className="code-function">
                          https://ctexscan.com/
                          <br />
                          &nbsp;address/apod?api_key=DEMO_KEY
                        </span>
                        ')
                        <br />
                        <br />.<span className="code-function">then</span>(
                        <span className="code-keyword">response</span> {"=>"}{" "}
                        {"{"}
                        <br />
                        &nbsp; console.
                        <span className="code-function">log</span>
                        (response.data.url);
                        <br /> &nbsp; console.
                        <span className="code-function">log</span>
                        (response.data.explanation);
                        <br />
                        {"}"})
                        <br />
                        <span className="code-function">.catch</span>(
                        <span className="code-keyword">error </span>
                        {"=>"} {"{"}
                        <br />
                        &nbsp; console.
                        <span className="code-function">log</span>(error);
                        <br />
                        {"}"});
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="tab-content">
                                <h3>GitHub</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis obcaecati quo architecto reprehenderit iste facere molestiae nulla laborum. Quas earum sequi, voluptas magni repudiandae necessitatibus.</p>
                            </div>
                            <div className="tab-content">
                                <h3>LinkedIn</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit tenetur qui odit asperiores quasi adipisci nam corrupti nostrum ex mollitia distinctio accusantium, inventore quos. Temporibus voluptatibus distinctio et tempore voluptas est impedit reprehenderit totam dolorum placeat aliquam quis odit, fuga minima quibusdam autem sit optio ipsa. Officiis doloribus velit eaque.</p>
                            </div>
                            <div className="tab-content">
                                <h3>Twitter</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum repellat iste odio quis ut commodi reiciendis inventore dolor voluptatibus consequatur voluptas facilis eveniet rem quasi aspernatur dolore atque illum ducimus eum, ipsum in illo. A asperiores nulla deserunt? Eaque magni natus dicta aperiam consequatur pariatur?</p>
                            </div>
                            <div className="tab-content">
                                <h3>Vimeo</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet quisquam minus eaque debitis voluptates. Veniam vitae esse quam saepe maiores provident ea? Itaque dolores ipsam recusandae minus dolor. Sapiente quod quia, cumque nam minima ab.</p>
                            </div> */}
              </section>
            </section>
          </main>
        </div>
      </div>
    </>
  );
}

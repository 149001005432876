import React from "react";

export default function CurrentCTEXAmbassadors() {
  return (
    <>
      <div className="section wf-section bg-light ptblr-30">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <div className="w-layout-grid grid-page-b-1">
            <div
              data-w-id="f0aa4076-555c-586f-088d-17e0523b26a1"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="stats lighter"
            >
              <h4 className="heading-stats text-black text-right ">6</h4>
              <img
                src="./assets/ambassdor.svg"
                alt=""
                className="align-self-end"
              />
              <div className="text-stats text-black">
                Current CTEX Ambassadors
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";

export default function SocialMedia() {
  return (
    <>
      <div className="section wf-section bg-light">
        <div className="content">
          <div className="block-heading">
            <div
              data-w-id="68275e70-ddcc-9d46-397e-61d7b3551ba4"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="subtitle"
            >
              supported community
            </div>
            <h2
              data-w-id="68275e70-ddcc-9d46-397e-61d7b3551ba6"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="heading text-black"
            >
              Join the community of developers, researchers and CTEX users
            </h2>
          </div>
        </div>
      </div>
      <div className="main bg-light pb-120">
        {/* Facebook Icon */}
        <a
          className="icon-a"
          href="https://www.facebook.com/people/Crypto-Tex-Token/100083171064229/"
          target="_blank"
        >
          <div className="icon s-icon">
            <i className="fa fa-facebook-f" />
            <span>Facebook</span>
          </div>
        </a>
        {/* Twitter Icon */}
        <a
          className="icon-a"
          href="https://twitter.com/CryptoTexCoin"
          target="_blank"
        >
          <div className="icon s-icon">
            <i className="fa fa-twitter" />
            <span>Twitter</span>
          </div>
        </a>
        {/* Instagram Icon */}
        <a
          className="icon-a"
          href="https://www.instagram.com/ctexcoin//"
          target="_blank"
        >
          <div className="icon s-icon">
            <i className="fa fa-instagram" />
            <span>Instagram</span>
          </div>
        </a>
        {/* Telegram Icon */}
        <a
          className="icon-a"
          href="https://t.me/cryptotexofficial"
          target="_blank"
        >
          <div className="icon s-icon">
            <i className="fa fa-telegram" />
            <span>Telegram</span>
          </div>
        </a>
        {/* <a className="icon-a" href="https://t.me/ruptoofficial" target="_blank">
          <div className="icon s-icon">
            <img
              src="./assets/img/icon/dicorrd.png"
              width="40"
              className="fa fa-telegram"
              alt=""
            />
            <span>Discord</span>
          </div>
        </a> */}
        <a
          className="icon-a text-black"
          href="https://github.com/ctexcoin"
          target="_blank"
        >
          <div className="icon s-icon">
            <i className="fa fa-github" />
            <span>GitHub</span>
          </div>
        </a>
        {/* <a className="icon-a" href="https://t.me/ruptoofficial" target="_blank">
          <div className="icon s-icon">
            <img
              src="./assets/img/icon/gitlook.png"
              width="40"
              className="fa fa-telegram"
              alt=""
            />
            <span>GitBook</span>
          </div>
        </a> */}
        {/* <a className="icon-a" href="https://t.me/ruptoofficial" target="_blank">
          <div className="icon s-icon">
            <img
              src="./assets/img/icon/medium.png"
              width="40"
              className="fa fa-telegram"
              alt=""
            />
            <span>Medium</span>
          </div>
        </a> */}
        <a
          className="icon-a text-black"
          href="https://www.reddit.com/r/ctexcoin/?rdt=54742"
          target="_blank"
        >
          <div className="icon s-icon">
            <i className="fa fa-reddit" />
            <span>Reddit</span>
          </div>
        </a>
        {/* YouTube Icon */}
        <a
          className="icon-a text-black"
          href="https://www.linkedin.com/company/crypto-tex"
          target="_blank"
        >
          <div className="icon s-icon">
            <i className="fa fa-linkedin" />
            <span>LinkedIn</span>
          </div>
        </a>
      </div>
    </>
  );
}

import React from "react";

export default function Ourvalue() {
  return (
    <>
      <div className="section wf-section">
        <div className="content">
          <div
            data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc498b"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              opacity: 1,
              transformStyle: "preserve-3d",
            }}
            className="subtitle text-center"
          >
            OUR VALUES
          </div>
          <div className="w-layout-grid grid-right-2">
            <div
              id="w-node-a50c89a1-4e8e-1cda-83cc-d3e74bcc498a-e3eb6dcf"
              className="block"
            >
              <h5
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc498d"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="heading"
              >
                Passion for Industry
              </h5>
              <p
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc4991"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="paragraph-large-2"
              >
                Passion generates excitement for what we do and how we do it.
                With passion towards the web3 industry at the heart of
                everything we do, we nurture ideas, inspire excellence, and find
                creative ways to grow into this ever-growing industry.
              </p>
            </div>
            <div className="block-right">
              <div
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc4985"
                className="figure-d-2"
              >
                <img
                  src="./assets/img/career/passion.png"
                  className="r-10"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="w-layout-grid grid-left-2">
            <div className="block-left">
              <div
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc4985"
                className="figure-d-2"
              >
                <img
                  src="./assets/img/career/target.png"
                  className="r-10"
                  alt=""
                />
              </div>
            </div>
            <div
              id="w-node-a50c89a1-4e8e-1cda-83cc-d3e74bcc498a-e3eb6dcf"
              className="block"
            >
              <h5
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc498d"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="heading"
              >
                Team on Mission
              </h5>
              <p
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc4991"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="paragraph-large-2"
              >
                The team behind CTEX is a group of amazing individuals. We’re
                committed to helping and spreading blockchain adoption by
                working together with equal parts humility and ambition.
              </p>
            </div>
          </div>
          <div className="w-layout-grid grid-right-2">
            <div
              id="w-node-a50c89a1-4e8e-1cda-83cc-d3e74bcc498a-e3eb6dcf"
              className="block"
            >
              <h5
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc498d"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="heading"
              >
                Leaving an Impact
              </h5>
              <p
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc4991"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="paragraph-large-2"
              >
                Impact demonstrates the immeasurable power of our shared values
                and collective effort to effect positive, ground-breaking change
                and deliver excellence for our customers, colleagues, and
                communities.
              </p>
            </div>
            <div className="block-right">
              <div
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc4985"
                className="figure-d-2"
              >
                <img
                  src="./assets/img/career/believe.png"
                  className="r-10"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="w-layout-grid grid-left-2">
            <div className="block-left">
              <div
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc4985"
                className="figure-d-2"
              >
                <img
                  src="./assets/img/career/get-it-done.png"
                  className="r-10"
                  alt=""
                />
              </div>
            </div>
            <div
              id="w-node-a50c89a1-4e8e-1cda-83cc-d3e74bcc498a-e3eb6dcf"
              className="block"
            >
              <h5
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc498d"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="heading"
              >
                Get it Done
              </h5>
              <p
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc4991"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="paragraph-large-2"
              >
                We will overcome obstacles, find solutions and deliver
                exceptional results for our community. The technology &
                solutions we develop are focused on high market demand. Our
                approach to build products always follows the ideal tactics with
                aiming to produce a product-market-fit.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

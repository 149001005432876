import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import HeroValidators from './HeroValidators'
import HSlider from './HSlider'
import NumberOfValidators from './NumberOfValidators'
import ValidatorsBackbone from './ValidatorsBackbone'
import ValidatorsRewords from './ValidatorsRewords'
import ValidatorsTable from './ValidatorsTable'

export default function Validators() {
  document.title = `Validators - ${process.env.REACT_APP_TITLE}`;
  return (
   <>
   <Header/>
   {/* <HSlider/> */}
   <HeroValidators/>
   <NumberOfValidators/>
   <ValidatorsTable/>
   <ValidatorsRewords/>
   <ValidatorsBackbone/>
   
   <Footer/>
   </>
  )
}

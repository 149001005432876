import React from "react";

export default function Stakebox() {
  return (
    <>
      <div
        className="section banner wf-section bg-light pb-5"
        style={{ height: "85vh" }}
      >
        <div className="content">
          <div
            data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb69"
            className="group-banner"
          >
            <div className="block-banner">
              <h4
                data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb6b"
                className="heading-banner text-black"
              >
                Stake RWT
              </h4>
              <div
                data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb6d"
                className="form-block-banner w-form align-center"
              >
                <p className="text-start mb-0 text-black">Balance : 1.28946186</p>
                <input
                  type="text"
                  className="text-field w-input w-100"
                  maxLength={256}
                  name="stake"
                  data-name="Email"
                  placeholder="Enter Amount"
                  id="Email"
                  requirsed
                />
                <p className="float-end text-black">Minimum Staking : 0.5 CTEX</p>
              </div>
              <h6 className="mt-2 text-black">Transaction Hash</h6>
              <p className="mt-1 text-black">
                RFIsjnYtAReyYPTLJOlTNXLfQo4tu6FGnvDeaaayaLpJbw2Vzx
              </p>
              <div
                data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb6d"
                className="form-block-banner w-form align-center"
              >
                <button className="button w-button"> Stake </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import Footer from "../comman/Footer";
import Header from "../comman/Header";

export default function Downloads() {
  return (
    <>
      <Header />
      <div className="section-hero wf-section">
        <div className="content hero">
          <img src="../assets/img/about-bg.png" className="bg" alt="" />
          <div className="block-hero">
            <h1
              data-w-id="fec0fb7b-303f-09d0-3ce6-40f91d7252a5"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="heading text-left"
            >
              Your key to the CTEX Chain ecosystem
            </h1>
            <p
              data-w-id="dcd739db-6d1f-6a52-859b-4a3fd0e20c84"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
                alignSelf: "start",
              }}
              className="paragraph-large text-left"
            >
              Learn more and understand CTEX Chain & its Products
            </p>
          </div>
        </div>
      </div>

      <div className="section wf-section bg-light">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <div className="w-layout-gri">
            <div
              data-w-id="b243638b-925b-7205-18ef-8dd1bbb756f7"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="tasks"
            >
              <div className="tasks-block">
                <img
                  src="./assets/logo-dark.png"
                  loading="lazy"
                  alt=""
                  className="icon-tasks"
                />
                <h5 className="heading-tasks text-black">
                  CTEX Chain Wallet Mobile App
                </h5>
              </div>
              <p className="text-black">
                Our mission is to spread blockchain adoption across different
                sectors while proposing blockchain based applications and
                solutions to thousands of people.
              </p>
              <div
                data-w-id="77b60b92-872c-7997-8a32-0c57412cdee7"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="w-layout-grid grid-button"
              >
                <a
                  href="/validators-presale"
                  className="button w-button text-white"
                >
                  <img
                    src="./assets/play.png"
                    loading="lazy"
                    alt=""
                    className="icon-tasks"
                  />
                  <small>GET IN ON</small>
                  <br />
                  Play Store
                </a>
                <a
                  href="/validators-presale"
                  className="button w-button text-white"
                >
                  <img
                    src="./assets/apple.png"
                    loading="lazy"
                    alt=""
                    className="icon-tasks"
                  />
                  App Store <br /> <small>coming soon...</small>
                </a>
              </div>
            </div>
            {/* <div data-w-id="f6d37ef0-7429-a241-58f8-4b5be9e2265d" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="tasks">
                            <div className="tasks-block"><img src="./assets/img/role.svg" loading="lazy" alt="" className="icon-tasks" />
                                <h5 className="heading-tasks text-black">Our Role</h5>
                            </div>
                            <p className='text-black'>With offering the high throughput blockchain network to introducing the conceptual model for the tokenization of actual land, Rupto aims to widen the blockchain space with connecting to and building real world applications.</p>
                        </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

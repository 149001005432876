import React from 'react'

export default function AmbassadorsExpect2() {
  return (
    <div>
        <div className="bg-dark">
            <div className=" section-Ctextokens wf-section bg-dark ">
            <div className="content hero">
          {/* <img src="../assets/img/protocol-background.png" className='bg' alt="" /> */}
          <div className="block-hero">
            <h1
              data-w-id="fec0fb7b-303f-09d0-3ce6-40f91d7252a5"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="Ctextokens-hero"
            >
              What we Expect
            </h1>
            <p
              data-w-id="dcd739db-6d1f-6a52-859b-4a3fd0e20c84"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
                maxWidth: "600px",
                fontSize: "18px",
              }}
              className="block paragraph-large text-white"
            >
              The CTEX Force are the pillars of our community, and as such, we
              anticipate the following from them:
            </p>
          </div>
        </div>
            </div>
            <div className="row ms-5 ">
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4"> 
                  <div>
            <h6>Consistent Engagement</h6>
            <p style={{ color: "#aba5a5" }}>
              Regularly participate in <br />
              community discussions, both <br />
              online and offline, <br />
              ensuring that the CTEX
              <br />
              Network's presence is felt.
            </p>
          </div>
          </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4">     <div>
            <h6>Content Creation</h6>
            <p style={{ color: "#aba5a5" }}>
              Produce and share high-quality <br /> content that educates,{" "}
              <br />
              informs, and engages the <br />
              audience about CTEX Network.
            </p>
          </div></div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4">    <div>
            <h6>Feedback Loop</h6>
            <p style={{ color: "#aba5a5" }}>
              Act as the eyes and ears of <br /> CTEX Network on the ground.{" "}
              <br />
              Gather feedback, insights, and <br />
              suggestions from the community.
            </p>
          </div></div>
          <div className="col-xl-3 col-lg-3 "></div>
            </div>
            <div className="row ms-5 me-5 pt-3">
                <div className="col-lg-3">
                <div>
                  <h6>Event Representation</h6>
                  <p style={{ color: "#aba5a5" }}>
                    Represent CTEX Network at <br />
                    local and global events, <br />
                    meetups, and conferences. <br />
                    Share your experiences, <br />
                    insights, and the benefits.
                  </p>
                </div>
                <div>
                  <h6>Collaboration</h6>
                  <p style={{ color: "#aba5a5" }}>
                    Share best practices, learn <br /> from each other, and
                    collaborate <br />
                    on projects or initiatives that <br /> benefit the
                    community.
                  </p>
                </div>
                </div>

                <div className="col-lg-3">       <div>
                  <h6>Uphold Brand Values</h6>
                  <p style={{ color: "#aba5a5" }}>
                    Ensure interactions and engagements <br /> are respectful,
                    inclusive, and <br />
                    aligned with our brand's principles.
                  </p>
                </div>
                <div className='pt-5'>
                  <h6>Continuous Learning</h6>
                  <p style={{ color: "#aba5a5" }}>
                    Stay updated with the latest <br /> developments in the Web3
                    space <br /> and within the CTEX Network.
                  </p>
                </div>
                </div>
                <div className="col-lg-6" >
                <img src="./assets/img/token/rupto-wallet.png" style={{marginTop:"-100px"}} alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}

import React from "react";
import "./App.css";
import Index from "./Components/Home/Index";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Validators from "./Components/Valildators/Validators";
import SetUpNode from "./Components/Setupanode/SetUpNode";
import Login from "./Components/Login/Login";
import Register from "./Components/Login/Register";
import Dashboard from "./Components/Dashboard/Dashboard";
import SubmitHash from "./Components/SubmitHash/SubmitHash";
import Ecosystem from "./Components/Ecosystem/Ecosystem";
import Brandkit from "./Components/Brandkit/Brandkit";
import About from "./Components/About/About";
import Career from "./Components/Career/Career";
import Jobdesc from "./Components/Career/Jobdesc";
import Developer from "./Components/Developer/Developer";
import Whitepaper from "./Components/Whitepaper/Whitepaper";
import Products from "./Components/Products/Products";
import Events from "./Components/Events/Events";
import Governance from "./Components/Governance/Governance";
import Ambassadors from "./Components/Ambassadors/Ambassadors";
import Protocol from "./Components/Protocol/Protocol";
import Wallet from "./Components/Wallet/Wallet";
import Token from "./Components/Token/Token";
import SecurityAudit from "./Components/SecurityAudit/SecurityAudit";
import Platform from "./Components/Platform/Platform";
import Dex from "./Components/DEX/Dex";
import Bridge from "./Components/Bridge/Bridge";
import RuptoSwap from "./Components/RuptoSwap/RuptoSwap";
import Community from "./Components/Community/Community";
import Learn from "./Components/Learn/Learn";
import DetailPage from "./Components/Learn/DetailPage";
import CalculateRpt from "./Components/Calculator/CalculateRpt";
import BecomeValidators from "./Components/BecomeValidators/BecomeValidators";
import Downloads from "./Components/Learn/Downloads";
import SetupNode from "./Components/Home/SetupNode";
import HeroSetupNode from "./Components/Setupanode/HeroSetupNode";
import Imself from "./Components/Imself/Imself";
import RuptoProtocol from "./Components/Learn/RuptoProtocol";
import UnderstandingRupto from "./Components/Learn/UnderstandingRupto";
import PoAS from "./Components/Learn/PoAS";
import Architecture from "./Components/Learn/Architecture";
import AssetTokenization from "./Components/Learn/AssetTokenization";
import ApplicationProtocol from "./Components/Learn/ApplicationProtocol";
import Network from "./Components/Network/Network";
import Exchange from "./Components/Exchange/Exchange";
import Tools from "./Components/Token/Tools";
import Myaccount from "./Components/MyAccount/Myaccount";
import Staking from "./Components/Staking/Staking";
import SubmitDapp from "./Components/SubmitDapps/SubmitDapp";
import ComingSoon from "./Components/comman/ComingSoon";
import Burn from "./Components/Burn/Burn";
import PrivacyPolicy from "./Components/comman/PrivacyPolicy";
import TermsConditions from "./Components/comman/TermsConditions";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/validators" element={<Validators />} />
          <Route path="/validators-presale" element={<SetUpNode />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/submit-hash" element={<SubmitHash />} />
          <Route path="/ecosystem" element={<Ecosystem />} />
          <Route path="/brandkit" element={<Brandkit />} />
          <Route path="/about" element={<About />} />
          <Route path="/career" element={<Career />} />
          <Route path="/job-desc" element={<Jobdesc />} />
          <Route path="/developer-resource" element={<Developer />} />
          <Route path="/whitepaper" element={<Whitepaper />} />
          <Route path="/products" element={<Products />} />
          <Route path="/events" element={<Events />} />
          <Route path="/governance" element={<Governance />} />
          <Route path="/ambassadors" element={<Ambassadors />} />
          <Route path="/protocol" element={<Protocol />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/token" element={<Token />} />
          <Route path="/security-audits" element={<SecurityAudit />} />
          <Route path="/platform" element={<Platform />} />
          <Route path="/dex" element={<Dex />} />
          <Route path="/bridge" element={<Bridge />} />
          <Route path="/rupto-swap" element={<RuptoSwap />} />
          <Route path="/community" element={<Community />} />
          <Route path="/learn" element={<Learn />} />
          <Route path="/detail-page" element={<DetailPage />} />
          <Route path="/calculate-rpt" element={<CalculateRpt />} />
          <Route path="/become-validators" element={<BecomeValidators />} />
          <Route path="/downloads" element={<Downloads />} />
          <Route path="/imself" element={<Imself />} />
          <Route path="/ctex-ctex" element={<RuptoProtocol />} />
          <Route path="/understanding-rupto" element={<UnderstandingRupto />} />
          <Route path="/poas" element={<PoAS />} />
          <Route path="/architecture" element={<Architecture />} />
          <Route path="/asset-tokenization" element={<AssetTokenization />} />
          <Route
            path="/application-protocol"
            element={<ApplicationProtocol />}
          />
          <Route path="/network" element={<Network />} />
          <Route path="/exchange" element={<Exchange />} />
          <Route path="/myaccount" element={<Myaccount />} />
          <Route path="/stake" element={<Staking />} />
          <Route path="/submit-dapp" element={<SubmitDapp />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/ctex-burn" element={<Burn />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
        </Routes>
      </BrowserRouter>
      {/* <Index/> */}
    </>
  );
}

export default App;

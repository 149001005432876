import React from 'react'

export default function Support() {
    return (
        <>
            <div className="section wf-section bg-light">
                <div data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc" className="content">
                    <div className="block-heading">
                        <div data-w-id="823c362f-ea60-5ffd-ca7f-cde6a03d15bb" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="subtitle">Need help?</div>
                        <h2 data-w-id="edc71457-7d5b-7bea-ff46-ddb5540db920" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="heading text-black">Need help?</h2>
                    </div>
                    <div className="w-layout-grid grid-features">
                        <div data-w-id="893553c5-8fd4-cfbf-8679-88871d8ec8a6" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="feature">
                            <div className="icon-feature-bg"><img src="./assets/img/icon/24-hours.png" loading="lazy" alt="" className="icon-feature" /></div>
                            <h6 className="text-black">24/7 Support</h6>
                            <p className='text-black'>We are live 24/7 with the best customer support team</p>
                        </div>
                        <div data-w-id="86649301-b3bf-a470-084f-c832dcc04618" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="feature">
                            <div className="icon-feature-bg"><img src="./assets/img/icon/join-our-community.png" loading="lazy" alt="" className="icon-feature" /></div>
                            <h6 className="text-black">Join Community</h6>
                            <p className='text-black'>Join other traders, investors and community of users.</p>
                        </div>
                        <div data-w-id="82c90e76-3d3e-e0a8-5192-1d91c07d6533" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="feature">
                            <div className="icon-feature-bg"><img src="./assets/img/icon/learn-crypto.png" loading="lazy" alt="" className="icon-feature" /></div>
                            <h6 className="text-black">Learn Crypto</h6>
                            <p className='text-black'>Explore blogs and articles to learn more about crypto.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

import React from 'react'

export default function DappHero() {
    return (
        <>
            <div className="section wf-section pt-140 pb-0 ">
                <img src="./assets/img/developer_resource_hero_bg.png" className="bg-2" alt="" />
                <div className="content">
                    <div className="w-layout-grid grid-right">
                        <div
                            id="w-node-_5713fedf-471c-a9c3-90d4-7d6642ea17a8-6ad29b8f"
                            className="block"
                        >
                            <div
                                data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17a9"
                                style={{
                                    WebkitTransform:
                                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                    MozTransform:
                                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                    msTransform:
                                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                    transform:
                                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                    opacity: 1,
                                }}
                                className="subtitle"
                            >
                                Developer
                            </div>
                            <h3
                                data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ab"
                                style={{
                                    WebkitTransform:
                                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                    MozTransform:
                                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                    msTransform:
                                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                    transform:
                                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                    opacity: 1,
                                }}
                                className="heading"
                            >
                                Start building scalable dApps on CTEX blockchain.
                            </h3>
                        </div>
                        <img src="./assets/img/ctex_blockchain_developer_img.png" alt="" srcset="" />
                        <div className="block-right align-items-end"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

import React from 'react'
import Header from '../comman/Header2'
import Footer from '../comman/Footer'
import Stakebox from './Stakebox'
import StakeStatustics from './StakeStatustics'

export default function Staking() {
    return (
        <>
            <Header />
            <StakeStatustics/>
            <Stakebox/>
            <Footer />
        </>
    )
}

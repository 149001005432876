import React from "react";

export default function Joinus() {
  return (
    <>
      <div className="section-hero wf-section bg-light">
        <div className="content hero">
          <div className="block-hero">
            <h1
              data-w-id="fec0fb7b-303f-09d0-3ce6-40f91d7252a5"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="heading-hero text-black"
            >
              Let's build together!
            </h1>
            <p
              data-w-id="dcd739db-6d1f-6a52-859b-4a3fd0e20c84"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="paragraph-large text-black"
            >
              Join us in building and growing the CTEX protocol.
            </p>
            <div
              data-w-id="77b60b92-872c-7997-8a32-0c57412cdee7"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="w-layout-grid grid-button"
            >
              <a href="/career" className="button w-button">
                Join Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from 'react'
import Footer from '../comman/Footer'
import Header2 from '../comman/Header2'
import HeroDashbord from './HeroAccount'
import WithdwarBox from './WithdwarBox'
import WithdwarTable from './WithdwarTable'

export default function Myaccount() {
    return (
        <>
            <Header2 />
            <HeroDashbord />
            <WithdwarBox/>
            <WithdwarTable />
            <Footer />
        </>
    )
}

import React from "react";

export default function Feature() {
  return (
    <>
      <div className="section wf-section bg-light">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <div className="block-heading">
            <div
              data-w-id="823c362f-ea60-5ffd-ca7f-cde6a03d15bb"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="subtitle"
            >
              Features
            </div>
            <h2
              data-w-id="edc71457-7d5b-7bea-ff46-ddb5540db920"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="heading text-black"
            >
              Powerful new features for solving challenges
            </h2>
          </div>
          <div className="w-layout-grid grid-features">
            <div
              data-w-id="893553c5-8fd4-cfbf-8679-88871d8ec8a6"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="feature"
            >
              <div className="icon-feature-bg">
                <img
                  src="./assets/img/features/lowest-fee.png"
                  loading="lazy"
                  alt=""
                  className="icon-feature"
                />
              </div>
              <h6 className="text-black">Reduced fees</h6>
              <p className="text-black">
                Cost-efficient: Up to 100x cheaper than native blockchain fees
              </p>
            </div>
            <div
              data-w-id="86649301-b3bf-a470-084f-c832dcc04618"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="feature"
            >
              <div className="icon-feature-bg">
                <img
                  src="./assets/img/features/poas-mac.png"
                  loading="lazy"
                  alt=""
                  className="icon-feature"
                />
              </div>
              <h6 className="text-black">High throughput</h6>
              <p className="text-black">Improved transaction scalability</p>
            </div>
            <div
              data-w-id="82c90e76-3d3e-e0a8-5192-1d91c07d6533"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="feature"
            >
              <div className="icon-feature-bg">
                <img
                  src="./assets/img/features/secure-mac.png"
                  loading="lazy"
                  alt=""
                  className="icon-feature"
                />
              </div>
              <h6 className="text-black">Cloud Compute</h6>
              <p className="text-black">
                Elevate dApp intelligence with seamless integration to off-chain
                APIs.
              </p>
            </div>
            <div
              data-w-id="d69dcdee-aab4-e517-357b-a0d5f9b2aa34"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="feature"
            >
              <div className="icon-feature-bg">
                <img
                  src="./assets/img/features/shared.png"
                  loading="lazy"
                  alt=""
                  className="icon-feature"
                />
              </div>
              <h6 className="text-black">Interoperability</h6>
              <p className="text-black">
                Connecting Blockchain Networks for Liquidity Exchange
              </p>
            </div>
            <div
              data-w-id="6c922f6d-a6bc-ce40-9a2c-d1ba5021c7a1"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="feature"
            >
              <div className="icon-feature-bg">
                <img
                  src="./assets/img/features/goverence.png"
                  loading="lazy"
                  alt=""
                  className="icon-feature"
                />
              </div>
              <h6 className="text-black">DPOS Mechanism</h6>
              <p className="text-black">
                Running on Delegate Proof-of-Stake Security Mechanism
              </p>
            </div>
            <div
              data-w-id="d8a73d62-1886-742f-ad2e-ca5926422de9"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="feature"
            >
              <div className="icon-feature-bg">
                <img
                  src="./assets/img/features/interopola.png"
                  loading="lazy"
                  alt=""
                  className="icon-feature"
                />
              </div>
              <h6 className="text-black">Secured</h6>
              <p className="text-black">
                Ctex Chain is secured by the layer 7 blockchain technologies{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

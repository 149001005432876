import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Mycontext } from "../../MyContext";

export default function TokenHero() {
  // const { formateNumber } = useContext(Mycontext);
  // const [rate, setRate] = useState([]);
  // const [marketCap, setMarketCap] = useState([]);
  const { rate, totalsupply, marketCap, CirculatingSupply } = useContext(Mycontext);
  // const fetchData = async () => {
  //   await axios
  //     .get("https://server.justbit.co.in/list-crypto/liveprice/rptusdt")
  //     .then((res) => {
  //       if (res.data.error) {
  //         return false;
  //       } else {
  //         setRate(res.data);
  //         console.log("heloo", res.data.data.price);
  //         setMarketCap(Number(res.data.data.price) * 50000);
  //       }
  //     });
  // };

  // console.log("user data---=>", rate);
  // useEffect(() => {
  //   fetchData();
  // }, []);
  return (
    <>
      <div className="section wf-section ptblr--40">
        <div className="content">
          <img src="../assets/img/bg-for-token.png" className="bg" alt="" />
          <div className="w-layout-grid grid-right">
            <div
              id="w-node-_5713fedf-471c-a9c3-90d4-7d6642ea17a8-6ad29b8f"
              className="block"
            >
              <div
                data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17a9"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="subtitle"
              >
                Coin
              </div>
              <h2
                data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ab"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="heading"
              >
                CTEX Coin
              </h2>
              <p
                data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ad"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="paragraph-large text-white"
              >
                Crypto Tex is an open-source blockchain scaling solution that
                delivers a faster, cheaper and smarter experience for
                blockchain’s next billion users. we are part of the global
                payment revolution.
              </p>
              <div
                data-w-id="2d6db815-ef82-a123-9a77-26cdadb39017"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="w-layout-grid grid-button"
              >
                <a href="#get" className="button w-button text-white">
                  Get CTEX
                </a>
                <a
                  href="https://ctex-network.gitbook.io/whitepaper/distribution"
                  className="button w-button text-white"
                  target="_blank"
                >
                  Tokenomics
                </a>
              </div>
            </div>
            <div className="block-right">
              <div className="w-layout-grid ">
                <div
                  data-w-id="9040b537-44e1-e314-8717-bd0bd8cbe0a4"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 1,
                  }}
                  className="stats lighter"
                >
                  <h5 className="heading-stats text-black">
                    {/* ${rate?.data?.price ? rate?.data?.price : ""} */}
                    ${rate}
                  </h5>
                  <div className="text-stats text-black">CTEX Rate</div>
                </div>
                <div
                  data-w-id="f0aa4076-555c-586f-088d-17e0523b26a1"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 1,
                  }}
                  className="stats lighter"
                >
                  <h5 className="heading-stats text-black">
                    {/* {"$" + formateNumber(marketCap)} */}
                    $ {marketCap}
                  </h5>
                  <div className="text-stats text-black">Market Cap</div>
                </div>
                <div
                  data-w-id="27764734-a540-afeb-0e84-c4274378e76a"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 1,
                  }}
                  className="stats lighter"
                >
                  <h4 className="heading-stats text-black">{CirculatingSupply}</h4>
                  <div className="text-stats text-black">
                    Circulating Supply
                  </div>
                </div>
                <div
                  data-w-id="918d5c54-f1a0-3064-11f2-3a26b74b3ba9"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 1,
                  }}
                  className="stats lighter"
                >
                  <h4 className="heading-stats text-black">{totalsupply}</h4>
                  <div className="text-stats text-black">Total Supply</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

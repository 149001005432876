import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import AboutHero from './AboutHero'
import AboutSection from './AboutSection'
import Joinus from './Joinus'
import Team from './Team'

export default function About() {

      return (
        <>
          <Header/>
          <AboutHero/>
          <AboutSection/>
          <Team/>
          <Joinus/>
          <Footer/>
        </>
    )
}

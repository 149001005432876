import React from "react";

export default function Footer() {
  const todaysDate = new Date();
  const currentYear = todaysDate.getFullYear();
  return (
    <>
      <div className="section footer wf-section">
        <div className="content">
          <div className="w-layout-grid grid-footer-2">
            <div
              id="w-node-_6603d099-87ea-d392-449a-caaf2cdb7f91-2cdb7f8e"
              data-w-id="6603d099-87ea-d392-449a-caaf2cdb7f91"
              className="block-footer"
            >
              <a href="/">
                <img
                  src="logo512.png"
                  loading="lazy"
                  alt=""
                  className="logo-footer"
                />
              </a>
              <p className="paragraph-footer text-justify">
                CTEX is an ideal blockchain platform for decentralized finance,
                asset tokenization & real-world blockchain applications.
                <br />
                <br />
                <a
                  href="mailto:info@ctexcoin.io"
                  target="_blank"
                  className="link-underlined"
                >
                  info@ctexcoin.io
                </a>
              </p>
            </div>
            <div
              data-w-id="6603d099-87ea-d392-449a-caaf2cdb7f99"
              className="block-footer"
            >
              <div className="heading-footer">CTEX</div>
              <a href="/token" target="_blank" className="link-footer">
                $CTEX
              </a>
              <a href="/about" target="_blank" className="link-footer">
                About
              </a>
              <a href="/coming-soon" target="_blank" className="link-footer">
                News & Media
              </a>
              <a href="/career" target="_blank" className="link-footer">
                Careers
              </a>
              <a href="/learn" target="_blank" className="link-footer">
                Learn
              </a>
              <a href="/ambassadors" target="_blank" className="link-footer">
                Ambassador Program
              </a>
              <a href="/community" target="_blank" className="link-footer">
                Community
              </a>
              <a href="/ctex-burn" target="_blank" className="link-footer">
                Ctex Burn
              </a>
            </div>
            <div
              data-w-id="6603d099-87ea-d392-449a-caaf2cdb7fa5"
              className="block-footer"
            >
              <div className="heading-footer">Technology</div>
              <a href="/protocol" target="_blank" className="link-footer">
                Protocol
              </a>
              <a href="/whitepaper" target="_blank" className="link-footer">
                Research
              </a>
              <a
                href="https://ctexscan.com/"
                target="_blank"
                className="link-footer"
              >
                Mainnet
              </a>
              <a
                href="https://testnet-explorer.ctexscan.com/"
                target="_blank"
                className="link-footer"
              >
                Testnet
                <br />
              </a>
              <a
                href="https://faucet.ctexscan.com/"
                target="_blank"
                className="link-footer"
              >
                Testnet Faucet
                <br />
              </a>
              <a href="/wallet" target="_blank" className="link-footer">
                Wallet
              </a>
              <a href="/governance" target="_blank" className="link-footer">
                Governance
              </a>
            </div>
            <div
              data-w-id="6603d099-87ea-d392-449a-caaf2cdb7fb1"
              className="block-footer"
            >
              <div className="heading-footer">Ecosystem</div>
              <a href="/products" target="_blank" className="link-footer">
                Products
              </a>
              <a href="https://ctexexchange.com/" target="_blank" className="link-footer">
                Exchange
              </a>
              <a href="https://ctex-network.gitbook.io/developer-documentation/run-master-node" target="_blank" className="link-footer">
                Node Setup
              </a>
              <a
                href="https://ctex-network.gitbook.io/developer-documentation/ctex-clusters/audit-report"
                target="_blank"
                className="link-footer"
              >
                Security & Audit
              </a>
              <a href="https://staking.ctexscan.com/" target="_blank" className="link-footer">
                Staking
              </a>
              <a href="https://status.ctexscan.com/" target="_blank" className="link-footer">
                Status
              </a>

              <a href="https://ctexswap.com/" target="_blank" className="link-footer">
                CTEX Swap
              </a>
            </div>
            <div
              data-w-id="6603d099-87ea-d392-449a-caaf2cdb7fb1"
              className="block-footer"
            >
              <div className="heading-footer">Resources</div>
              <a
                href="https://ctex-network.gitbook.io/developer-documentation"
                target="_blank"
                className="link-footer"
              >
                Documentation
              </a>
              <a
                href="https://ctex-network.gitbook.io/developer-documentation/developer-guide"
                target="_blank"
                className="link-footer"
              >
                Developer Resource
                <br />
              </a>
              <a href="/brandkit" target="_blank" className="link-footer">
                Brand Kit
              </a>
              <a href="/platform" target="_blank" className="link-footer">
                Platform
              </a>
              <a href="#" className="link-footer">
                Submit dapps
              </a>
              <a href="/privacy-policy" className="link-footer">
                Privacy Policy
              </a>
              <a href="/terms-conditions" className="link-footer">
                Terms and Conditions
              </a>
            </div>
          </div>
          <div
            data-w-id="6603d099-87ea-d392-449a-caaf2cdb7fbb"
            className="footer-down text-center"
          >
            <p className="paragraph-footer">
              All rights reserved by{" "}
              <a href="/" target="_blank" className="link">
                © {currentYear} CTEX COMMUNITY
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

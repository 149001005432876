import React from 'react'

export default function StakeStatustics() {
    return (
        <>
            <div className="section wf-section bg-light pb-0 mb-1">
                <div data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc" className="content">
                    <div className="w-layout-grid grid-page-b">
                        <div
                            data-w-id="9040b537-44e1-e314-8717-bd0bd8cbe0a4"
                            style={{
                                transform:
                                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 1,
                                transformStyle: "preserve-3d",
                            }}
                            className="stats lighter"
                        >
                            <h4 className="heading-stats text-black">100+</h4>
                            <div className="text-stats">Available Validator Spots</div>
                        </div>
                        <div
                            data-w-id="f0aa4076-555c-586f-088d-17e0523b26a1"
                            style={{
                                transform:
                                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 1,
                                transformStyle: "preserve-3d",
                            }}
                            className="stats lighter"
                        >
                            <h4 className="heading-stats text-black">$50k+</h4>
                            <div className="text-stats">Approximate Transaction Volume</div>
                        </div>
                        <div
                            data-w-id="27764734-a540-afeb-0e84-c4274378e76a"
                            style={{
                                transform:
                                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 1,
                                transformStyle: "preserve-3d",
                            }}
                            className="stats lighter"
                        >
                            <h4 className="heading-stats text-black">∼24%</h4>
                            <div className="text-stats">Staking APY for Node Operators</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Mycontext } from "../../MyContext";

export default function AboutSection() {
  const { rate, totalsupply, marketCap, CirculatingSupply, formateNumber } = useContext(Mycontext);

  // const [rate, setRate] = useState([]);
  // const [marketCap, setMarketCap] = useState([]);
  // const fetchData = async () => {
  //   await axios
  //     .get("https://server.justbit.co.in/list-crypto/liveprice/rptusdt")
  //     .then((res) => {
  //       if (res.data.error) {
  //         return false;
  //       } else {
  //         setRate(res.data);
  //         console.log(res.data.data.price);
  //         setMarketCap(Number(res.data.data.price) * 50000);
  //       }
  //     });
  // };
  // console.log("user data---=>", rate);
  // useEffect(() => {
  //   fetchData();
  // }, []);
  return (
    <>
      <div className="section wf-section bg-light">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <div className="w-layout-grid grid-page-a">
            <div
              data-w-id="b243638b-925b-7205-18ef-8dd1bbb756f7"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="tasks"
            >
              <div className="tasks-block">
                <img
                  src="./assets/img/mission.svg"
                  loading="lazy"
                  alt=""
                  className="icon-tasks"
                />
                <h5 className="heading-tasks text-black">Our Mission</h5>
              </div>
              <p className="text-black">
                Our mission is to spread blockchain adoption across different
                sectors while proposing blockchain based applications and
                solutions to thousands of people.
              </p>
            </div>
            <div
              data-w-id="f6d37ef0-7429-a241-58f8-4b5be9e2265d"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="tasks"
            >
              <div className="tasks-block">
                <img
                  src="./assets/img/role.svg"
                  loading="lazy"
                  alt=""
                  className="icon-tasks"
                />
                <h5 className="heading-tasks text-black">Our Role</h5>
              </div>
              <p className="text-black">
                With offering the high throughput blockchain network to
                introducing the conceptual model for the tokenization of actual
                land, CTEX aims to widen the blockchain space with connecting to
                and building real world applications.
              </p>
            </div>
          </div>
          <div className="w-layout-grid grid-page-b">
            <div
              id="w-node-_03dd9d41-6f8f-6dae-ad77-4ec7b94fceda-e76b7e62"
              data-w-id="03dd9d41-6f8f-6dae-ad77-4ec7b94fceda"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="tasks"
            >
              <div className="tasks-block">
                <img
                  src="./assets/img/value.svg"
                  loading="lazy"
                  alt=""
                  className="icon-tasks"
                />
                <h5 className="heading-tasks text-black">Our Values</h5>
              </div>
              <p className="text-black">
                CTEX ecosystem is connected with real world applications
                including travelling platforms, e-commerce portals, games, media
                platforms and dozens of others offering unique utility for CTEX
                token.
              </p>
            </div>
            <div
              data-w-id="9040b537-44e1-e314-8717-bd0bd8cbe0a4"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="stats lighter"
            >
              <h4 className="heading-stats text-black">
                {/* {"$20M+" + formateNumber(marketCap)} */}
                {marketCap ? formateNumber(marketCap) : "---"}
              </h4>
              <div className="text-stats text-black">
                of Asset Secured MarketCap
              </div>
            </div>
            <div
              data-w-id="f0aa4076-555c-586f-088d-17e0523b26a1"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="stats lighter"
            >
              <h4 className="heading-stats text-black">10+</h4>
              <div className="text-stats text-black">Ecosystem Partners</div>
            </div>
            <div
              data-w-id="27764734-a540-afeb-0e84-c4274378e76a"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="stats lighter"
            >
              <h4 className="heading-stats text-black">100%</h4>
              <div className="text-stats text-black">Asset Surety</div>
            </div>
            <div
              data-w-id="918d5c54-f1a0-3064-11f2-3a26b74b3ba9"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="stats lighter"
            >
              <h4 className="heading-stats text-black">1M+</h4>
              <div className="text-stats text-black">Unique Users Monthly</div>
            </div>
          </div>
          <div className="page-content">
            <h3
              data-w-id="88efa683-a3ad-b788-d8e0-a44585c25590"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
                textAlign: "center",
              }}
              className="text-black"
            >
              Delegate Proof of Stack
            </h3>
            <p
              data-w-id="0dafb890-b226-74d6-9466-46f5bec96839"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="text-justify text-black"
            >
              Delegate Proof of Stake (DPoS) is the foundational consensus
              mechanism employed by Ctex, ensuring agreement on the state of the
              blockchain. In our DPoS system, token holders actively participate
              in the governance process by electing delegates or validators
              responsible for block production and transaction validation. These
              delegates are chosen based on their token holdings or votes from
              fellow token holders.
            </p>
            <p
              data-w-id="0dafb890-b226-74d6-9466-46f5bec96839"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="text-justify text-black"
            >
              Once elected, delegates diligently perform their duties, rotating
              in producing blocks and securely adding them to the blockchain.
              Their adherence to integrity and accuracy is incentivized, as
              token holders possess the authority to vote them out in case of
              any misconduct.
            </p>
            <p
              data-w-id="0dafb890-b226-74d6-9466-46f5bec96839"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="text-justify text-black"
            >
              Renowned for its efficiency and scalability, DPoS within Ctex
              stands out among other consensus mechanisms such as Proof of Work
              (PoW) and Proof of Stake (PoS). Its resource-efficient nature
              enables faster transaction speeds while reducing overall costs.
              However, it's crucial to acknowledge that DPoS introduces
              potential centralization risks, as the validation authority is
              centralized among a select group of delegates.
            </p>

            <div
              data-w-id="77b60b92-872c-7997-8a32-0c57412cdee7"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="w-layout-grid grid-button"
            >
              <a
                href="https://ctex-network.gitbook.io/whitepaper/dpos-with-ctex"
                target="_blank"
                className="button w-button text-white"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

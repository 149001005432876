import React from "react";

export default function AmbassadorsMap() {
  return (
    <>
      <div className="section wf-section bg-light">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <div className="block-heading">
            <div
              data-w-id="823c362f-ea60-5ffd-ca7f-cde6a03d15bb"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="subtitle"
            >
              Join the Program
            </div>
            <h2
              data-w-id="edc71457-7d5b-7bea-ff46-ddb5540db920"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="heading text-black"
            >
              Become a part of fast growing worldwide decentralized community
            </h2>
          </div>
        </div>
        {/* <div id="container"></div> */}
        {/* <div id="chartdiv"></div> */}
        <img src="./assets/img/rupto-map.png" alt="" />
      </div>
      {/* <div className="section wf-section bg-light ptblr-30">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <div className="w-layout-grid grid-page-b-1">
            <div
              data-w-id="f0aa4076-555c-586f-088d-17e0523b26a1"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="stats lighter"
            >
              <h4 className="heading-stats text-black text-right ">6</h4>
              <img
                src="./assets/ambassdor.svg"
                alt=""
                className="align-self-end"
              />
              <div className="text-stats text-black">
                Current CTEX Ambassadors
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

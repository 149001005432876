import React from "react";

export default function Possibilities() {
  return (
    <>
      <div className="section wf-section">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <div className="block-heading">
            {/* <div data-w-id="6d9ad869-a5c2-fd2f-9bfc-e6fc73288d47" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="subtitle"> Partner</div> */}
            <h2
              data-w-id="6d9ad869-a5c2-fd2f-9bfc-e6fc73288d49"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="heading"
            >
              Build your crypto portfolio
            </h2>
            <p
              data-w-id="7fc5df83-6010-b5dc-f288-b6f30f9f4eef"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="paragraph-large"
            >
              Manage & track your cryptocurrency portfolio on CTEX Exchange.
            </p>
          </div>
          <div className="w-layout-grid grid-technology">
            <div
              data-w-id="893553c5-8fd4-cfbf-8679-88871d8ec8a6"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="technology pt-0  px w-90"
            >
              <div role="listitem" className="collection-item w-dyn-item">
                <a
                  data-w-id="9b3a04ce-54f9-ab44-7137-87c1c7ad463c"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d",
                  }}
                  className="link-image-blog w-inline-block"
                >
                  <img
                    src="./assets/img/staking.png"
                    loading="lazy"
                    alt="Introducing enhanced search API functionality with Core 2.0"
                    className="image-blog"
                  />
                  <div className="bg-blog" style={{ opacity: "0.5" }} />
                </a>
                <div
                  data-w-id="92c8a6ff-e0e8-c539-af2a-4e39bf8fd110"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d",
                  }}
                  className="block-blog px-20 "
                >
                  <h6 className="heading-blog">Safe & Secure App</h6>
                  <br />
                  <div className="date-blog">
                    CTEX Exchange app is enabled with multiple security layers.
                  </div>
                </div>
              </div>
            </div>
            <div
              data-w-id="919317fc-3fc6-5955-bd1f-d2b83420d18f"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="technology pt-0  px w-90"
            >
              {" "}
              <div role="listitem" className="collection-item w-dyn-item">
                <a
                  data-w-id="9b3a04ce-54f9-ab44-7137-87c1c7ad463c"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d",
                  }}
                  className="link-image-blog w-inline-block"
                >
                  <img
                    src="./assets/img/validator.png"
                    loading="lazy"
                    alt="Introducing enhanced search API functionality with Core 2.0"
                    className="image-blog"
                  />
                  <div className="bg-blog" style={{ opacity: "0.5" }} />
                </a>
                <div
                  data-w-id="92c8a6ff-e0e8-c539-af2a-4e39bf8fd110"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d",
                  }}
                  className="block-blog px-20 "
                >
                  <h6 className="heading-blog">Cold Storage</h6>
                  <br />
                  <div className="date-blog">
                    Users' funds and all the AUM are stored in cold wallets
                  </div>
                </div>
              </div>
            </div>
            <div
              data-w-id="138e3976-e8c8-15d4-ba81-7614035567d3"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="technology pt-0  px w-90"
            >
              <div role="listitem" className="collection-item w-dyn-item">
                <a
                  data-w-id="9b3a04ce-54f9-ab44-7137-87c1c7ad463c"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d",
                  }}
                  className="link-image-blog w-inline-block"
                >
                  <img
                    src="./assets/img/event.png"
                    loading="lazy"
                    alt="Introducing enhanced search API functionality with Core 2.0"
                    className="image-blog"
                  />
                  <div className="bg-blog" style={{ opacity: "0.5" }} />
                </a>
                <div
                  data-w-id="92c8a6ff-e0e8-c539-af2a-4e39bf8fd110"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d",
                  }}
                  className="block-blog px-20 "
                >
                  <h6 className="heading-blog">Easy Investing</h6>
                  <br />
                  <div className="date-blog">
                    Start investing easily with as minimum as 100 Rs.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import BookNode from './BookNode'
import HeroSetupNode from './HeroSetupNode'
import PresaleReward from './PresaleReward'
import Trusted from './Trusted '
export default function SetUpNode() {
  document.title = `Setup Node - ${process.env.REACT_APP_TITLE}`;
  return (
    <>
    <Header/>
    <HeroSetupNode/>
    <BookNode/>
    <PresaleReward/>
    <Trusted/>
    <Footer/>
    </>
  )
}

import React from "react";
import Footer from "../comman/Footer";
import Header from "../comman/Header";
import AmbassadorsHero from "./AmbassadorsHero";
import AmbassadorsMap from "./AmbassadorsMap";
import AmbassdorCommunity from "./AmbassdorCommunity";
import AmbassdorsDetails from "./AmbassdorsDetails";
import BecomeAmbassdor from "./BecomeAmbassdor";
import AmbassadorsExpect from "./AmbassadorsExpect";
import CurrentCTEXAmbassadors from "./CurrentCTEXAmbassadors";
import CtexForce from "./CtexForce";
import AmbassadorsExpect2 from "./AmbassadorsExpect2";

export default function Ambassadors() {
  return (
    <>
      <Header />
      <AmbassadorsHero />
      <AmbassdorsDetails />
      <BecomeAmbassdor />
      {/* <AmbassadorsExpect /> */}
      <AmbassadorsExpect2/>
      <AmbassadorsMap />
      <CurrentCTEXAmbassadors />
      <CtexForce />
      <AmbassdorCommunity />
      <Footer />
    </>
  );
}

import React from "react";

export default function Trade() {
  return (
    <>
      <div className="section wf-section bg-light">
        <div className="content bg-green ptblr-50">
          <div className="w-layout-grid grid-left-2">
            <div className="block-left">
              <div
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc4985"
                className="figure-d-2"
              >
                <img
                  src="./assets/img/zero-percent.png"
                  className="r-10"
                  alt=""
                />
              </div>
            </div>
            <div
              id="w-node-a50c89a1-4e8e-1cda-83cc-d3e74bcc498a-e3eb6dcf"
              className="block"
            >
              <h4
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc498d"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="heading"
              >
                Trade $CTEX with 0 Trading Fee
              </h4>
              <p>
                0 trading fee on CTEX Token (CTEX) and other selected digital
                assets.
              </p>
              <p>Lowest trading fee for other popular assets</p>
              <div
                data-w-id="2d6db815-ef82-a123-9a77-26cdadb39017"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="w-layout-grid grid-button"
              >
                <a
                  href="https://justbit.in/"
                  className="button w-button text-white"
                >
                  Trade CTEX
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

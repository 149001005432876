import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import Faq from './Faq'
import HowTo from './HowTo'
import LearnHero from './LearnHero'
import Faq2 from './Faq2'

export default function Learn() {
  return (
    <>
    <Header/>
    <LearnHero/>
    <HowTo/>
    {/* <Faq/> */}
    <Faq2/>
    <Footer/>
    </>
  )
}

import React from "react";

export default function TrustedCrypto() {
  return (
    <>
      <div className="section wf-section bg-light bg-21 ">
        <div className="content">
          <div className="w-layout-grid grid-right">
            <div
              id="w-node-_5713fedf-471c-a9c3-90d4-7d6642ea17a8-6ad29b8f"
              className="block"
            >
              <h4
                data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ab"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="heading text-black"
              >
                The only crypto app you need
              </h4>
              <p
                data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ad"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="paragraph-large"
              >
                Why you should choose CTEX Exchange as your primary investment
                app
              </p>
              <ul role="list">
                <li>
                  <b className="text-black">Quality Assets </b>
                  <br /> All the assets that are listed on CTEX Exchanges are
                  legit and audited
                </li>
                <li>
                  <b className="text-black">User First Policy </b>
                  <br /> CTEX provides the best for its users while prioritizing
                  user first policy
                </li>
                <li>
                  <b className="text-black">Variety of Investment Option </b>
                  <br /> Choose from numerous options to access derivatives,
                  SIPs, staking and more.
                </li>
              </ul>
              <div
                data-w-id="2d6db815-ef82-a123-9a77-26cdadb39017"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="w-layout-grid grid-button"
              >
                <a
                  href="https://justbit.in/exchange?s=RPTUSDT"
                  target="_blank"
                  className="button w-button text-white"
                >
                  Download Now
                </a>
              </div>
            </div>
            <div className="block-right">
              <img
                src="./assets/img/exchange-inner-section.png"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

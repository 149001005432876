import React, { useContext } from 'react'
import CountdownTimer from "../comman/CountdownTimer";
import { Mycontext } from '../../MyContext';

export default function Brunstatustics() {
    const { rate, totalsupply, marketCap, CirculatingSupply, burnbalance } = useContext(Mycontext);
    return (
        <>
            <div className="section wf-section bg-light ptblr-40">
                <div
                    data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
                    className="content"
                >

                    <h5 className="text-stats text-black">Next Buring Event </h5>
                    <div className="w-layout-gri grid-page-a  d-flex">
                        <div
                            data-w-id="918d5c54-f1a0-3064-11f2-3a26b74b3ba9"
                            style={{
                                transform:
                                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 1,
                                transformStyle: "preserve-3d",
                                textAlign: "center"
                            }}
                            className="stats lighter"
                        >
                            <h4 className="heading-stats text-black"><CountdownTimer targetDate={'2025-04-12'} /></h4>

                        </div>
                    </div>
                </div>
            </div>
            <div
                className="section banner wf-section bg-light pb-50"
                style={{ justifyItems: "center" }}
            >
                <div className="content">
                    <div
                        data-w-id="5d90d470-fa17-1f16-14ad-fad48d39fb69"
                        className="group-banner ptblr-30 "
                    >
                        <div className="content">
                            <div
                                className="w-layout-grid grid-right "
                                style={{ textAlign: "start" }}
                            >
                                <div
                                    id="w-node-_5713fedf-471c-a9c3-90d4-7d6642ea17a8-6ad29b8f"
                                    className="block"
                                >
                                    <h4
                                        data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ab"
                                        style={{
                                            WebkitTransform:
                                                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                            MozTransform:
                                                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                            msTransform:
                                                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                            transform:
                                                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                            opacity: 1,
                                        }}
                                        className="heading text-black"
                                    >
                                        Why Burning?
                                    </h4>
                                    <p
                                        data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ad"
                                        style={{
                                            WebkitTransform:
                                                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                            MozTransform:
                                                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                            msTransform:
                                                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                            transform:
                                                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                            opacity: 1,
                                            fontSize: "18px",
                                        }}
                                        className="paragraph-large text-black"
                                    >
                                        Burning helps in controlling the inflation of CTEX supply. The circulating supply of CTEX is deducted by 200 million in every burning event. This gives the CTEX economy a sharp reversal in demand and supply curve that helps in managing the market.



                                    </p>
                                    <p
                                        data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ad"
                                        style={{
                                            WebkitTransform:
                                                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                            MozTransform:
                                                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                            msTransform:
                                                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                            transform:
                                                "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                            opacity: 1,
                                            fontSize: "18px",
                                        }}
                                        className="paragraph-large text-black"
                                    >
                                        CTEX Buring events are scheduled to be organized on each 12 April & 21 September every year until the last burning in September 2036. There will be a total of 400 Million CTEX burned every year.
                                    </p>
                                </div>
                                <div className="block-left align-items-end">
                                    <img
                                        src="./assets/ctex_burning.png"
                                        width="500"
                                        height="300"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section wf-section bg-light">
                <div
                    data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
                    className="content"
                >
                    <h5 className="text-stats text-black">CTEX Statistics</h5>
                    <div className="w-layout-grid grid-page-b">
                        <div
                            data-w-id="f0aa4076-555c-586f-088d-17e0523b26a1"
                            style={{
                                transform:
                                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 1,
                                transformStyle: "preserve-3d",
                            }}
                            className="stats lighter"
                        >
                            <h4 className="heading-stats text-black">
                                {burnbalance ? burnbalance : "---"}
                                CTEX
                            </h4>
                            <div className="text-stats text-black">Total Burned</div>
                        </div>
                        <div
                            data-w-id="27764734-a540-afeb-0e84-c4274378e76a"
                            style={{
                                transform:
                                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 1,
                                transformStyle: "preserve-3d",
                            }}
                            className="stats lighter"
                        >
                            <h4 className="heading-stats text-black">{CirculatingSupply ? CirculatingSupply : "---"}</h4>
                            <div className="text-stats text-black">Circulating Supply </div>
                        </div>
                        <div
                            data-w-id="918d5c54-f1a0-3064-11f2-3a26b74b3ba9"
                            style={{
                                transform:
                                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                opacity: 1,
                                transformStyle: "preserve-3d",
                            }}
                            className="stats lighter"
                        >
                            <h4 className="heading-stats text-black">{totalsupply ? totalsupply : "---"}</h4>
                            <div className="text-stats text-black">Total Supply </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import CommunityHero from './CommunityHero'
import Conversation from './Conversation'
import SocialMedia from './SocialMedia'

export default function Community() {
    return (
        <>
            <Header/>
            <CommunityHero />
            <Conversation/>
            <SocialMedia/>
            <Footer />
        </>
    )
}

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { RuptoContext } from "../../RuptoContext";

export default function LiveRate() {
  const { formateNumber } = useContext(RuptoContext);
  const [rate, setRate] = useState([]);
  const [marketCap, setMarketCap] = useState([]);

  const fetchData = async () => {
    await axios
      .get("https://server.justbit.co.in/list-crypto/liveprice/rptusdt")
      .then((res) => {
        if (res.data.error) {
          return false;
        } else {
          setRate(res.data);
          console.log(res.data.data.price);
          setMarketCap(Number(res.data.data.price) * 50000);
        }
      });
  };
  console.log("user data---=>", rate);
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div className="section wf-section ptblr--40">
        <div className="content">
          <img src="../assets/img/bg-for-token.png" className="bg" alt="" />
          <div className="w-layout-grid grid-right">
            <div
              id="w-node-_5713fedf-471c-a9c3-90d4-7d6642ea17a8-6ad29b8f"
              className="block"
            >
              <h2
                data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ab"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="heading"
              >
                Trade CTEX, Bitcoin and other hundreds of cryptocurrency
              </h2>
              <p
                data-w-id="5713fedf-471c-a9c3-90d4-7d6642ea17ad"
                style={{
                  WebkitTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1,
                }}
                className="paragraph-large"
              >
                Buy, trade and store popular crypto assets on CTEX Exchange.
              </p>
              {/* <div data-w-id="2d6db815-ef82-a123-9a77-26cdadb39017" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="w-layout-grid grid-button">
                                <a href="https://justbit.in/" className="button w-button text-white">Get RPT</a>
                                <a href="#" className="button w-button text-white">Tokenomics</a>
                            </div> */}
            </div>
            <div className="block-right">
              <div className="w-layout-grid ">
                <div
                  data-w-id="9040b537-44e1-e314-8717-bd0bd8cbe0a4"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 1,
                  }}
                  className="stats lighter"
                >
                  <h4 className="heading-stats text-black">
                    {/* ${rate?.data?.price ? rate?.data?.price : ''} */}
                    100+
                  </h4>
                  <div className="text-stats text-black">Crypto Assets</div>
                </div>
                <div
                  data-w-id="f0aa4076-555c-586f-088d-17e0523b26a1"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 1,
                  }}
                  className="stats lighter"
                >
                  <h4 className="heading-stats text-black">
                    {/* {"$" + formateNumber(marketCap)} */}
                    150+
                  </h4>
                  <div className="text-stats text-black">Verified Users</div>
                </div>
                <div
                  data-w-id="27764734-a540-afeb-0e84-c4274378e76a"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 1,
                  }}
                  className="stats lighter"
                >
                  <h4 className="heading-stats text-black">$1M+</h4>
                  <div className="text-stats text-black">Trading Volume</div>
                </div>
                <div
                  data-w-id="918d5c54-f1a0-3064-11f2-3a26b74b3ba9"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 1,
                  }}
                  className="stats lighter"
                >
                  <h4 className="heading-stats text-black">20+</h4>
                  <div className="text-stats text-black">Countries</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";

export default function WhitepaperMain() {
  return (
    <>
      <div className="section wf-section bg-light">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <div className="w-layout-grid grid-page-a jusity-content-center">
          <div
              data-w-id="9040b537-44e1-e314-8717-bd0bd8cbe0a4"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="stats lighter"
            >
              <a
                href="https://ctex-network.gitbook.io/whitepaper/"
                target="_blank"
                className="icon-a"
                download
              >
                <h5 className="heading-stats text-black">Whitepaper</h5>
                <br />
                <p className="text-black">
                A comprehensive document outlining the goals, technology, and solutions proposed by CTEx to address challenges in the cryptocurrency exchange industry, typically targeting investors and stakeholders.
                </p>

                {/* <div className="text-stats text-black">Rupto whitepaper represents overall structure for the Rupto project from the idea to implementation into a working product & functioning application. All the content included into this whitepaper is a blueprint for the design & development of Rupto.</div> */}
              </a>
            </div>
            <div
              data-w-id="f0aa4076-555c-586f-088d-17e0523b26a1"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="stats lighter"
            >
              <a
                href="https://ctex-network.gitbook.io/technical-paper/"
                className="icon-a"
              >
                <h5 className="heading-stats text-black">Technical paper </h5>
                <br />
                <p className="text-black">
                A detailed document providing in-depth insights into the technical architecture, consensus mechanisms, scalability solutions, and other technical aspects specific to CTEx's exchange platform.

                </p>

                {/* <div className="text-stats text-black">Ecosystem Partners</div> */}
              </a>
            </div>
        
            <div
              data-w-id="9040b537-44e1-e314-8717-bd0bd8cbe0a4"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="stats lighter"
            >
              <a
                href="https://ctex-network.gitbook.io/yellow-paper/"
                target="_blank"
                className="icon-a"
                download
              >
                <h5 className="heading-stats text-black">Yellow paper</h5>
                <br />
                <p className="text-black">
                A formal specification document for CTEx's blockchain protocol, detailing the consensus mechanism, cryptographic algorithms, data structures, and other technical specifications at a deeper level than the whitepaper.
                </p>

                {/* <div className="text-stats text-black">Rupto whitepaper represents overall structure for the Rupto project from the idea to implementation into a working product & functioning application. All the content included into this whitepaper is a blueprint for the design & development of Rupto.</div> */}
              </a>
            </div>
            <div
              data-w-id="9040b537-44e1-e314-8717-bd0bd8cbe0a4"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="stats lighter"
            >
              <a
                href="https://ctex-network.gitbook.io/developer-documentation/"
                target="_blank"
                className="icon-a"
                download
              >
                <h5 className="heading-stats text-black"> 

Developer documentation 

</h5>
                <br />
                <p className="text-black">
                Detailed guides, tutorials, APIs, and SDKs provided by CTEx to assist developers in building applications, integrating services, and leveraging the CTEx platform for trading and other functionalities.
                </p>

                {/* <div className="text-stats text-black">Rupto whitepaper represents overall structure for the Rupto project from the idea to implementation into a working product & functioning application. All the content included into this whitepaper is a blueprint for the design & development of Rupto.</div> */}
              </a>
            </div>

            {/* <div data-w-id="b243638b-925b-7205-18ef-8dd1bbb756f7" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="tasks">
                            <div className="tasks-block"><img src="./assets/img/mission.svg" loading="lazy" alt="" className="icon-tasks" />
                                <h5 className="heading-tasks text-black">Whitepaper</h5>
                            </div>
                            <p className='text-black'>Rupto whitepaper represents overall structure for the Rupto project from the idea to implementation into a working product & functioning application. All the content included into this whitepaper is a blueprint for the design & development of Rupto. </p>
                        </div>
                        <div data-w-id="f6d37ef0-7429-a241-58f8-4b5be9e2265d" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="tasks">
                            <div className="tasks-block"><img src="./assets/img/role.svg" loading="lazy" alt="" className="icon-tasks" />
                                <h5 className="heading-tasks text-black">Rupto Docs</h5>
                            </div>
                            <p className='text-black'>With offering the high throughput blockchain network to introducing the conceptual model for the tokenization of actual land, Rupto aims to widen the blockchain space with connecting to and building real world applications.</p>
                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

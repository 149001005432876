import React from "react";

export default function ValidatorsBox() {
  return (
    <>
      <div className="section wf-section bg-light">
        <div
          data-w-id="0773ab12-e7cf-0dcb-1ac5-1097386497cc"
          className="content"
        >
          <div className="w-layout-grid grid-page-b">
            <div
              data-w-id="b243638b-925b-7205-18ef-8dd1bbb756f7"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="tasks"
            >
              <div className="tasks-block">
                <img
                  src="./assets/img/mission.svg"
                  loading="lazy"
                  alt=""
                  className="icon-tasks"
                />
                <h5 className="heading-tasks text-black">Start as Validator</h5>
              </div>
              <p className="text-black">
                Get started as a CTEX validator with basics and node set-up
                process
              </p>
            </div>
            <div
              data-w-id="f6d37ef0-7429-a241-58f8-4b5be9e2265d"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="tasks"
            >
              <div className="tasks-block">
                <img
                  src="./assets/img/role.svg"
                  loading="lazy"
                  alt=""
                  className="icon-tasks"
                />
                <h5 className="heading-tasks text-black">View Validators</h5>
              </div>
              <p className="text-black">
                Find out all validators on the CTEX network and delegate your
                tokens.
              </p>
            </div>
            <div
              data-w-id="b243638b-925b-7205-18ef-8dd1bbb756f7"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="tasks"
            >
              <div className="tasks-block">
                <img
                  src="./assets/img/mission.svg"
                  loading="lazy"
                  alt=""
                  className="icon-tasks"
                />
                <h5 className="heading-tasks text-black">Network Status</h5>
              </div>
              <p className="text-black">
                Check network status, epochs, slots, fees and other insights.
              </p>
            </div>
            {/* <div data-w-id="f6d37ef0-7429-a241-58f8-4b5be9e2265d" style={{ WebkitTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1 }} className="tasks">
                            <div className="tasks-block"><img src="./assets/img/role.svg" loading="lazy" alt="" className="icon-tasks" />
                                <h5 className="heading-tasks text-black">Delegation Program</h5>
                            </div>
                            <p className='text-black'>Foundation Delegation participants are eligible to receive a delegation from the CTEX Foundation.</p>
                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

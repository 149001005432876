import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import NetworkHero from './NetworkHero'
import NetworkResoures from './NetworkResoures'

export default function Network() {
    return (
        <>
            <Header />
            <NetworkHero/>
            <NetworkResoures/>
            <Footer />
        </>
    )
}

import React from "react";
import Footer from "../comman/Footer";
import Header from "../comman/Header";
// import Chart from './Chart'
import CheckRupto from "./CheckRupto";
import FurtherReading from "./FurtherReading";
import GetToken from "./GetToken";
import KeepSafe from "./KeepSafe";
import Staking from "./Staking";
import TokenHero from "./TokenHero";
import Tools from "./Tools";
import UnitExchange from "./UnitExchange";
import CtextExchange from "./CtextExchange";

export default function Token() {
  return (
    <>
      <Header />
      <TokenHero />
      {/* <Chart/> */}
      {/* <KeepSafe /> */}
      {/* <CheckRupto /> */}
      {/* <Tools/> */}
      {/* <Staking /> */}
      <UnitExchange />
      <CtextExchange />
      {/* <FurtherReading /> */}
      <GetToken />
      <Footer />
    </>
  );
}

import React from "react";

export default function AllAssets() {
  return (
    <>
      <div className="section wf-section ">
        <div className="content">
          <div className="block-heading">
            <h2
              data-w-id="6d9ad869-a5c2-fd2f-9bfc-e6fc73288d49"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="heading"
            >
              All-in-one crypto wallet
            </h2>
            <p
              data-w-id="7fc5df83-6010-b5dc-f288-b6f30f9f4eef"
              style={{
                WebkitTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              className="paragraph-large text-white"
            >
              Store, trade & utilize thousands of digital asset from different
              blockchain networks on{" "}
              <span className="text-golden">CTEX Wallet.</span>
            </p>
          </div>
        </div>
      </div>

      <div class="slider">
        <div class="slide-track">
          <div class="slide">
            <img
               src="./assets/img/svg/avalanche.svg"
              height="100"
              width="220"
              alt=""
            />
          </div>
          
          <div class="slide">
            <img
                 src="./assets/img/svg/binance_smart_chain.svg"
              height="100"
              width="220"
              alt=""
            />
          </div>
          {/*  */}
          <div class="slide">
            <img
          src="./assets/img/svg/bitcoin.svg"
              height="100"
              width="220"
              alt=""
            />
          </div>
          <div class="slide">
            <img
                src="./assets/img/svg/cardano.svg"
              height="100"
              width="220"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="./assets/img/svg/chainlink.svg"
              height="100"
              width="220"
              alt=""
            />
          </div>
          <div class="slide">
            <img
             src="./assets/img/svg/dogecoin.svg"
              height="100"
              width="220"
              alt=""
            />
          </div>
          <div class="slide">
            <img
             src="./assets/img/svg/ethereum.svg"
              height="100"
              width="220"
              alt=""
            />
          </div>
          <div class="slide">
            <img
               src="./assets/img/svg/near.svg"
              height="100"
              width="220"
              alt=""
            />
          </div>
          <div class="slide">
            <img
                 src="./assets/img/svg/poladot.svg"
              height="100"
              width="220"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="./assets/img/svg/polygon.svg"
              height="100"
              width="220"
              alt=""
            />
          </div>
          <div class="slide">
            <img
               src="./assets/img/svg/solana.svg"
              height="100"
              width="220"
              alt=""
            />
          </div>
          
        </div>
      </div>
      <div class="slider">
        <div class="slide-track-2">
          <div class="slide">
            <img
                src="./assets/img/svg/tether.svg"
              height="100"
              width="200"
              alt=""
            />
          </div>
          <div class="slide">
            <img
             src="./assets/img/svg/tron.svg"
              height="100"
              width="200"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="./assets/img/svg/uniswap.svg"
              height="100"
              width="200"
              alt=""
            />
          </div>
          <div class="slide">
            <img
            src="./assets/img/svg/usdc.svg"
              height="100"
              width="200"
              alt=""
            />
          </div>
          <div class="slide">
            <img
               src="./assets/img/svg/xrp.svg"
              height="100"
              width="200"
              alt=""
            />
          </div>
          <div class="slide">
            <img
             src="./assets/img/svg/tether.svg"
              height="100"
              width="200"
              alt=""
            />
          </div>
          <div class="slide">
            <img
            src="./assets/img/svg/poladot.svg"
              height="100"
              width="200"
              alt=""
            />
          </div>
          <div class="slide">
            <img
            src="./assets/img/svg/dogecoin.svg"
              width="200"
              alt=""
            />
          </div>
          <div class="slide">
            <img
 src="./assets/img/svg/tether.svg"
               height="100"
              width="200"
              alt=""
            />
          </div>
          <div class="slide">
            <img
             src="./assets/img/svg/tron.svg"
              height="100"
              width="200"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="./assets/img/svg/uniswap.svg"
              height="100"
              width="200"
              alt=""
            />
          </div>
          <div class="slide">
            <img
            src="./assets/img/svg/usdc.svg"
              height="100"
              width="200"
              alt=""
            />
          </div>
          {/* <div class="slide">
            <img
              src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png"
              height="100"
              width="200"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png"
              height="100"
              width="200"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png"
              height="100"
              width="200"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png"
              height="100"
              width="200"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png"
              height="100"
              width="200"
              alt=""
            />
          </div> */}
        </div>
      </div>
      <div class="slider">
        <div class="slide-track">
        <div class="slide">
            <img
                src="./assets/img/svg/cardano.svg"
              height="100"
              width="220"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="./assets/img/svg/chainlink.svg"
              height="100"
              width="220"
              alt=""
            />
          </div>
          <div class="slide">
            <img
             src="./assets/img/svg/dogecoin.svg"
              height="100"
              width="220"
              alt=""
            />
          </div>
          <div class="slide">
            <img
             src="./assets/img/svg/ethereum.svg"
              height="100"
              width="220"
              alt=""
            />
          </div>
          <div class="slide">
            <img
               src="./assets/img/svg/near.svg"
              height="100"
              width="220"
              alt=""
            />
          </div>
          <div class="slide">
            <img
                 src="./assets/img/svg/poladot.svg"
              height="100"
              width="220"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="./assets/img/svg/polygon.svg"
              height="100"
              width="220"
              alt=""
            />
          </div>
          <div class="slide">
            <img
               src="./assets/img/svg/xrp.svg"
              height="100"
              width="220"
              alt=""
            />
          </div>
          {/*  */}
          <div class="slide">
            <img
               src="./assets/img/svg/usdc.svg"
              height="100"
              width="220"
              alt=""
            />
          </div> <div class="slide">
            <img
               src="./assets/img/svg/uniswap.svg"
              height="100"
              width="220"
              alt=""
            />
          </div> 
       
        </div>
      </div>
    </>
  );
}

import React from "react";

export default function Ctextokens() {
  return (
    <>
      <div
        className="section-Ctextokens wf-section bg-dark "
        // style={{ height: "1080px" }}
      >
        <div className="content hero">
          {/* <img src="../assets/img/protocol-background.png" className='bg' alt="" /> */}
          <div className="block-hero">
            <h1
              data-w-id="fec0fb7b-303f-09d0-3ce6-40f91d7252a5"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="Ctextokens-hero"
            >
              Powered by CTEX coins
            </h1>
            <p
              data-w-id="dcd739db-6d1f-6a52-859b-4a3fd0e20c84"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
                // maxWidth: "380px",
                fontSize: "18px",
              }}
              className="paragraph-large text-white"
            >
              CTEX is the native currency of the CTEX Network, just like ETH on
              Ethereum.
            </p>
          </div>
        </div>
   
        <div className="row mx-5 ">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-30">
            <div className="row">
              <div className="col-lg-6 col-sm-6">
              <div>
                  <h6>Transaction Fees</h6>
                  <p style={{ color: "#aba5a5" }}>
                    Fees are denominated and <br />
                    paid in CTEX
                  </p>
                </div>
                <div>
                  <h6>Security & Consensus</h6>
                  <p style={{ color: "#aba5a5" }}>
                    Delegated proof-of-stake. <br />
                    5% yearly inflation.
                  </p>
                </div>
                <div>
                  <h6>Liquid Staking</h6>
                  <p style={{ color: "#aba5a5" }}>
                    Liquid staking with SCTEX <br />
                    and Voltage Finance{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
              <div>
                  <h6>Staking</h6>
                  <p style={{ color: "#aba5a5" }}>
                    Upto 14% APY with on-chain staking
                  </p>
                </div>
                <div>
                  <h6>Governance</h6>
                  <p style={{ color: "#aba5a5" }}>
                    Proportional, stake-based voting.
                  </p>
                </div>
                <div>
                  <h6>Interoperable</h6>
                  <p style={{ color: "#aba5a5" }}>
                    CTEX is on Ethereum, BNB Chain, and Polygon
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 " style={{marginTop:"-80px",paddingBottom:"30px"}}> <img src="./assets/img/token/rupto-wallet.png" className="img-fluid" alt="" /></div>
        </div>
      </div>
    </>
  );
}

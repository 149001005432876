import React from "react";

export default function ValidatorsBackbone() {
  return (
    <>
      <div className="section wf-section bg-light">
        <div className="content">
          {/* <div data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc498b" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 1, transformStyle: 'preserve-3d' }} className="subtitle text-center">OUR VALUES</div> */}
          <div className="w-layout-grid grid-left">
            <div
              id="w-node-a50c89a1-4e8e-1cda-83cc-d3e74bcc498a-e3eb6dcf"
              className="block"
            >
              <h3
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc498d"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="heading text-black"
              >
                Validators empowers the Decentralization
              </h3>
              <p
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc4991"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="paragraph-large-2 text-black"
              >
                Every blockchain network needs validators to verify transactions
                and secure the network. CTEX validators are incentivised with
                CTEX tokens from transaction gas and benefited with staking
                rewards.
              </p>
            </div>
            <div className="block-right">
              <div
                data-w-id="a50c89a1-4e8e-1cda-83cc-d3e74bcc4985"
                className="figure-d-2"
              >
                <img
                  src="./assets/img/validators-empowers.png"
                  className="r-10"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
